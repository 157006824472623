import * as SagaEffects from "redux-saga/effects";
import * as types from "../../../constants/ActionTypes";
import * as timeRegistrationActionSelectors from "../../../selectors/actions/timeRegistration";
import * as writeBackQueueActions from "../../../actions/writeBackQueue";

function* doMakeChangeToModel(action: any) {
  const change = timeRegistrationActionSelectors.getChangeToModelChange(action);
  const model = timeRegistrationActionSelectors.getChangeToModelModel(action);
  yield SagaEffects.put(writeBackQueueActions.addChangeToQueue(change, model));
}

export default function* makeChangeToModel() {
  yield SagaEffects.takeLatest(
    types.APP_PAGE_TIMEREGISTRATION_MAKE_CHANGE_TO_MODEL,
    doMakeChangeToModel
  );
}
