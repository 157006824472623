import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as apihelper from "../../selectors/apihelper";
import * as storehelper from "../../selectors/storehelper";
import { SortingType } from "../../selectors/storehelper";
import * as reducerAccountSelectors from "../../selectors/reducers/account";
import { isLoggedIn } from "../../selectors/reducers/auth";
import "../../lib/unique";
import * as EntityTypes from "../../constants/EntityTypes";
import * as usersAndResourcesSelectors from "../../selectors/pages/usersAndResources";
import * as reducerPermissionSelectors from "../../selectors/reducers/permission";
import * as usersAndResourcesActions from "../../actions/pages/usersAndResources";
import { Pivot, PivotItem, PivotLinkSize } from "@fluentui/react/lib/Pivot";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { Toggle } from "@fluentui/react/lib/Toggle";
import { Icon } from "@fluentui/react/lib/Icon";
import * as Icons from "../../constants/Icons";
import DetailsListBasic from "../../components/lists/DetailsListBasic";
import SorterList from "../../components/lists/SorterList";
import { Resource } from "../../components/uifabricextensions/Resource";
import { User } from "../../components/uifabricextensions/User";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import * as localeSelectors from "../../selectors/reducers/locale";
import * as resourceTypeSelectors from "../../selectors/reducers/resourceType";
import * as appDomainSelectors from "../../selectors/app/domain";
import { IconButton } from "@fluentui/react/lib/Button";
import { ContextualMenu } from "@fluentui/react";
import { Stack } from "@fluentui/react/lib/Stack";
import * as editorActions from "../../actions/EntityEditor";
import * as editorSelectors from "../../selectors/EntityEditorSelectors";
import ResourceForm from "../../components/forms/ResourceForm";
import UserForm from "../../components/forms/UserForm";
import DeleteForm from "../../components/forms/DeleteForm";
import PermissionForm from "../../components/forms/PermissionForm";
import { MessageBar } from "@fluentui/react/lib/MessageBar";
import * as UserReducers from "../../selectors/reducers/user";
import { DefaultCompareFunctions } from "../../components/lists/sorting";

class PermissionsTreeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedGroups: [],
    };
  }

  _findIndexOfGroup = (groupsOnThisLevel, item, col) => {
    const { columnGroups } = this.props;
    return groupsOnThisLevel.findIndex(
      (group) => group.name == columnGroups[col].extract(item)
    );
  };

  _doesColumnMatchPermissionType = (column, permissionTypeId) => {
    let { permissionTypeNameToIds } = this.props;

    if (
      column == "account" &&
      permissionTypeId == permissionTypeNameToIds.AccountWrite
    ) {
      return "Write";
    } else if (
      column == "account" &&
      permissionTypeId == permissionTypeNameToIds.AccountRead
    ) {
      return "Read";
    } else if (
      column == "client" &&
      permissionTypeId == permissionTypeNameToIds.ClientWrite
    ) {
      return "Write";
    } else if (
      column == "client" &&
      permissionTypeId == permissionTypeNameToIds.ClientRead
    ) {
      return "Read";
    } else if (
      column == "project" &&
      permissionTypeId == permissionTypeNameToIds.ProjectWrite
    ) {
      return "Write";
    } else if (
      column == "project" &&
      permissionTypeId == permissionTypeNameToIds.ProjectRead
    ) {
      return "Read";
    } else {
      return "";
    }
  };

  _sortAllPermissionsWithParents = (allPermissionsWithParents, groupBy) => {
    let { columnSorters } = this.props;

    let sortedAllPermissionsWithParents = allPermissionsWithParents.slice();
    groupBy
      .slice()
      .reverse()
      .forEach((col) => {
        sortedAllPermissionsWithParents = storehelper.sortItemsAscending(
          sortedAllPermissionsWithParents,
          columnSorters[col]
        );
      });
    return sortedAllPermissionsWithParents;
  };

  _makePermissionTree = (sortedAllPermissionsWithParents, groupBy) => {
    const { columnGroups } = this.props;

    let groupTracker = [{ parentHasPermission: "" }];
    let permissionTree = [];

    sortedAllPermissionsWithParents.forEach((item) => {
      let groupsOnThisLevel = groupTracker;
      for (let i = 0; i < groupBy.length; i++) {
        let col = groupBy.at(i);
        let idxOfGroup = this._findIndexOfGroup(groupsOnThisLevel, item, col);
        if (columnGroups[col].extract(item) == "") {
          break;
        } else if (idxOfGroup == -1) {
          // Update the permissionTree:
          let newPermissionTreeItem = {
            newBlock: "",
            user: item["user"],
            arrowAccount: "",
            account: "",
            arrowclient: "",
            client: "",
            arrowproject: "",
            project: "",
            permission: "",
          };
          newPermissionTreeItem[col] = item[col];

          // newBlock is used in the function _makeUserGroups to divide the list into groups (one per user)
          newPermissionTreeItem["newBlock"] = col == "user" ? true : "";

          // Make the permission check icons for this level in the tree:
          let permissionAtThisLevel = this._doesColumnMatchPermissionType(
            col,
            item.permissionTypeId
          );
          let permissionIcons = [];
          if (permissionAtThisLevel == "Write") {
            permissionIcons.push(
              <span>
                <Icon
                  styles={{ root: { color: "crimson", fontSize: 17 } }}
                  title="Write permission"
                  iconName={Icons.ICON_NAME_WRITE_ACCESS}
                />
              </span>
            );
          } else if (permissionAtThisLevel == "Read") {
            permissionIcons.push(
              <span>
                <Icon
                  styles={{ root: { fontSize: 17 } }}
                  title="Read permission"
                  iconName={Icons.ICON_NAME_READ_ACCESS}
                />
              </span>
            );
          } else {
            permissionIcons.push(
              <span>
                <Icon styles={{ root: { color: "orange", width: "17px" } }} />
              </span>
            ); // Used as placeholder
          }

          // Make the permission check icons indicating if a parent has the same or a stronger permission type
          if (
            groupsOnThisLevel[0].parentHasPermission == "Write" ||
            (groupsOnThisLevel[0].parentHasPermission == "Read" &&
              permissionAtThisLevel == "") ||
            (groupsOnThisLevel[0].parentHasPermission == "Read" &&
              permissionAtThisLevel == "Read")
          ) {
            let checkIcon =
              groupsOnThisLevel[0].parentHasPermission == "Write"
                ? "SkypeCircleCheck"
                : "SkypeCheck";
            permissionIcons.push(
              <span>
                <Icon
                  styles={{ root: { color: "grey", fontSize: 17 } }}
                  title={
                    "The user has already " +
                    (groupsOnThisLevel[0].parentHasPermission == "Write"
                      ? "write"
                      : "read") +
                    " permission to a parent"
                  }
                  iconName={checkIcon}
                />
              </span>
            );
          }
          newPermissionTreeItem["permission"] = (
            <Stack horizontal gap={20}>
              {permissionIcons}
            </Stack>
          );

          // Make childOf-arrow in permissionTree:
          if (i > 0) {
            newPermissionTreeItem["arrow".concat(col)] = (
              <Icon styles={{ root: { fontSize: 17 } }} iconName="Childof" />
            );
          }
          permissionTree.push(newPermissionTreeItem);

          // Pass information to children on permission of parents
          let setParentHasPermission;
          if (groupsOnThisLevel[0].parentHasPermission == "Write") {
            setParentHasPermission = "Write";
          } else if (permissionAtThisLevel == "Write") {
            setParentHasPermission = "Write";
          } else if (groupsOnThisLevel[0].parentHasPermission == "Read") {
            setParentHasPermission = "Read";
          } else if (permissionAtThisLevel == "Read") {
            setParentHasPermission = "Read";
          } else {
            setParentHasPermission = "";
          }

          // Update groupTracker:
          groupsOnThisLevel.push({
            name: columnGroups[col].extract(item),
            children: [{ parentHasPermission: setParentHasPermission }],
          });
        }
        idxOfGroup = this._findIndexOfGroup(groupsOnThisLevel, item, col); // Recompute in case it has changed
        groupsOnThisLevel = groupsOnThisLevel[idxOfGroup].children;
      }
    });
    return permissionTree;
  };

  _makeUserGroups = (permissionTree) => {
    const { columnGroups } = this.props;
    const { expandedGroups } = this.state;

    let userGroups = [];
    permissionTree.forEach((item, itemIndex) => {
      if (item["newBlock"]) {
        let isCollapsed = expandedGroups
          ? !expandedGroups.includes(itemIndex)
          : true;
        userGroups.push({
          key: itemIndex,
          name: columnGroups["user"].extract(item),
          startIndex: itemIndex + 1,
          count: 0,
          isCollapsed: isCollapsed,
        });
      } else {
        userGroups.at(-1).count += 1;
      }
    });
    return userGroups;
  };

  _onToggleCollapse = (group) => {
    const { expandedGroups } = this.state;

    let newExpandedGroups = expandedGroups.slice();
    if (group.isCollapsed) {
      newExpandedGroups.push(group.key);
    }
    if (!group.isCollapsed) {
      newExpandedGroups = newExpandedGroups.filter((str) => str !== group.key);
    }

    this.setState({ expandedGroups: newExpandedGroups });
  };

  render() {
    let { allPermissionsWithParents, selectedPermissionUserId, ...other } =
      this.props;

    let groupBy = ["user", "account", "client", "project"];
    let sortedAllPermissionsWithParents = this._sortAllPermissionsWithParents(
      allPermissionsWithParents,
      groupBy
    );
    let permissionTree = this._makePermissionTree(
      sortedAllPermissionsWithParents,
      groupBy
    );
    let userGroups = this._makeUserGroups(permissionTree);

    return (
      <DetailsListBasic
        {...other}
        columns={[
          {
            key: "p-user",
            name: "User",
            fieldName: "user",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
          },
          {
            key: "p-arrowaccount",
            name: "",
            fieldName: "arrowaccount",
            minWidth: 17,
            maxWidth: 17,
            isResizable: false,
          },
          {
            key: "p-account",
            name: "Account",
            fieldName: "account",
            minWidth: 60,
            maxWidth: 100,
            isResizable: true,
          },
          {
            key: "p-arrowclient",
            name: "",
            fieldName: "arrowclient",
            minWidth: 17,
            maxWidth: 17,
            isResizable: false,
          },
          {
            key: "p-client",
            name: "Client",
            fieldName: "client",
            minWidth: 60,
            maxWidth: 100,
            isResizable: true,
          },
          {
            key: "p-arrowproject",
            name: "",
            fieldName: "arrowproject",
            minWidth: 17,
            maxWidth: 17,
            isResizable: false,
          },
          {
            key: "p-project",
            name: "Project",
            fieldName: "project",
            minWidth: 60,
            maxWidth: 140,
            isResizable: true,
          },
          {
            key: "p-permission",
            name: "Permission",
            fieldName: "permission",
            minWidth: 60,
            maxWidth: 90,
            isResizable: true,
          },
        ]}
        onRenderItemColumn={(item, index, column) => {
          let fieldContent;
          switch (column.key) {
            case "p-user":
              return "";
            case "p-account":
              fieldContent = item[column.fieldName]
                ? apihelper.getAttr(item[column.fieldName], "name")
                : "";
              return fieldContent;
            case "p-client":
              fieldContent = item[column.fieldName]
                ? apihelper.getAttr(item[column.fieldName], "name")
                : "";
              return fieldContent;
            case "p-project":
              fieldContent = item[column.fieldName]
                ? apihelper.getAttr(item[column.fieldName], "name")
                : "";
              return fieldContent;
            default:
              return item[column.fieldName];
          }
        }}
        items={permissionTree}
        groups={userGroups}
        groupProps={{
          headerProps: {
            onToggleCollapse: this._onToggleCollapse,
            styles: { headerCount: { display: "none" } },
          },
        }}
      />
    );
  }
}

const userListColumns = [
  {
    key: "u-name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "u-email",
    name: "E-mail",
    fieldName: "email",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "u-uniqueId",
    name: "Unique ID",
    fieldName: "uniqueId",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "u-active",
    name: "Active",
    fieldName: "active",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "u-resource",
    name: "Resource",
    fieldName: "resource",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
];

const userListColumnSorters = {
  name: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.name),
  },
  email: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.email),
  },
  uniqueId: {
    compareFunction: DefaultCompareFunctions.Lexical((a) =>
      apihelper.getAttr(a.data, "name")
    ),
  },
  active: {
    compareFunction: DefaultCompareFunctions.Boolean((a) => a.active),
  },
  resource: {
    compareFunction: DefaultCompareFunctions.Lexical((a) =>
      apihelper.getAttr(a.resource, "name")
    ),
  },
};

const resourceListColumns = [
  {
    key: "r-name",
    name: "Name",
    fieldName: "name",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "r-type",
    name: "Type",
    fieldName: "type",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "r-user",
    name: "User",
    fieldName: "user",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "r-active",
    name: "Active",
    fieldName: "active",
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
  },
  {
    key: "r-description",
    name: "Description",
    fieldName: "description",
    minWidth: 200,
    maxWidth: 250,
    isResizable: true,
  },
];

const resourceListColumnSorters = {
  name: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.name),
  },
  type: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.type),
  },
  user: {
    compareFunction: DefaultCompareFunctions.Lexical((a) =>
      apihelper.getAttr(a.data, "name")
    ),
  },
  employedAtLocale: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.employedAtLocale),
  },
  active: {
    compareFunction: DefaultCompareFunctions.Boolean((a) => a.active),
  },
  description: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.description),
  },
};

class UsersAndResources extends Component {
  constructor(props) {
    super(props);

    this._onEditUser = this._onEditUser.bind(this);
    this._onSelectUser = this._onSelectUser.bind(this);

    this._onCreateResource = this._onCreateResource.bind(this);
    this._onSelectResource = this._onSelectResource.bind(this);
    this._onEditResource = this._onEditResource.bind(this);
    this._onRemoveResource = this._onRemoveResource.bind(this);

    this._onSelectPermission = this._onSelectPermission.bind(this);
    this._onCreatePermission = this._onCreatePermission.bind(this);
    this._onEditPermission = this._onEditPermission.bind(this);

    this._toggleActive = this._toggleActive.bind(this);

    this.props.actions.pageOpened();
  }

  _onEditUser(element) {
    this.props.actions.startEdit(this.props.selectedUser, UserForm);
  }

  _onSelectUser(items) {
    this.props.actions.selectUser(items.length ? items[0].data : null);
  }

  _onEditResource() {
    this.props.actions.startEdit(this.props.selectedResource, ResourceForm);
  }

  _onCreateResource() {
    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.RESOURCE,
        {
          eligibleForAbsenceRegistration: true,
          isActive: true,
          vacationHours: 200,
          utilizationTarget: 82,
        },
        {
          account: this.props.selectedAccount,
        }
      ),
      ResourceForm
    );
  }

  _onRemoveResource() {
    this.props.actions.startEdit(this.props.selectedResource, DeleteForm);
  }

  _onSelectResource(items) {
    if (items.length == 1) {
      this.props.actions.selectResource(items[0].data);
    }
  }

  _onSelectPermission(item) {
    let permissionIdentifiers = {
      user: item[0].user,
      account: item[0].account,
      client: item[0].client,
      project: item[0].project,
    };
    this.props.actions.selectPermissionUser(permissionIdentifiers);
  }

  _isColumnCorrect(itemCol, selectedPermissionColId) {
    if (itemCol == "" && selectedPermissionColId == undefined) {
      return true;
    } else if (apihelper.entityHasId(itemCol, selectedPermissionColId)) {
      return true;
    } else {
      return false;
    }
  }

  _isPermissionSelected(item) {
    const {
      selectedPermissionUserId,
      selectedPermissionAccountId,
      selectedPermissionClientId,
      selectedPermissionProjectId,
    } = this.props;

    let isUserCorrect = apihelper.entityHasId(
      item.user,
      selectedPermissionUserId
    );
    let isAccountCorrect = this._isColumnCorrect(
      item.account,
      selectedPermissionAccountId
    );
    let isClientCorrect = this._isColumnCorrect(
      item.client,
      selectedPermissionClientId
    );
    let isProjectCorrect = this._isColumnCorrect(
      item.project,
      selectedPermissionProjectId
    );
    return (
      isUserCorrect && isAccountCorrect && isClientCorrect && isProjectCorrect
    );
  }

  _currentUserPermissionTypeMessage() {
    const { allPermissions, currentUser, permissionTypeNameToIds } = this.props;

    let currentUserPermission = allPermissions.find(
      (perm) => apihelper.getRelId(perm, "user") == currentUser["id"]
    );
    let currentUserPermissionTypeName;
    if (
      apihelper.getRelId(currentUserPermission, "permissionType") ==
      permissionTypeNameToIds.GlobalAdmin
    ) {
      currentUserPermissionTypeName = "Global Admin";
    } else if (
      apihelper.getRelId(currentUserPermission, "permissionType") ==
      permissionTypeNameToIds.GlobalAccountant
    ) {
      currentUserPermissionTypeName = "Global Accountant";
    } else {
      currentUserPermissionTypeName = "";
    }

    return (
      "Your user (" +
      apihelper.getAttr(currentUser, "name") +
      ") has permission type: " +
      currentUserPermissionTypeName
    );
  }

  _onCreatePermission() {
    this.props.actions.selectPermissionUser(null);
    this.props.actions.startEdit([], PermissionForm);
  }

  _onEditPermission() {
    this.props.actions.startEdit(
      this.props.selectedPermissionUserPermissions,
      PermissionForm
    );
  }

  _toggleActive() {
    this.props.actions.toggleInactives();
  }

  render() {
    let {
      selectedAccount,
      selectedResourceId,
      selectedUserId,
      permissionTypeNameToIds,
      selectedPermissionUserId,
      filteredResourceItems,
      filteredUserItems,
      allPermissionsWithParents,
    } = this.props;

    const usersMoreMenu = {
      items: [
        {
          key: "edit",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: this._onEditUser,
        },
      ],
    };

    const resourcesMoreMenu = {
      items: [
        {
          key: "edit",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: this._onEditResource,
        },
        {
          key: "remove",
          text: "Remove",
          iconProps: { iconName: "Remove" },
          onClick: this._onRemoveResource,
        },
      ],
    };

    const shrinkItem = {
      root: {
        overflow: "hidden",
      },
    };

    return (
      <div className="novatime-container">
        <h2>Users and resources</h2>
        <Pivot linkSize={PivotLinkSize.large}>
          <PivotItem
            linkText="Users"
            itemCount={filteredUserItems.length}
            itemIcon={Icons.ICON_NAME_USER}
          >
            <br />
            <CommandBar
              farItems={[
                {
                  key: "toggle-actives",
                  text: "Show Inactives",
                  ariaLabel: "Toggle Actives",
                  iconOnly: true,
                  iconProps: { iconName: "CheckboxComposite" },
                  commandBarButtonAs: () => (
                    <Toggle
                      label="Show Inactives"
                      inlineLabel
                      checked={this.props.toggleInactives}
                      onClick={() => {
                        this._toggleActive();
                      }}
                    />
                  ),
                  onText: "On",
                  offText: "Off",
                },
              ]}
            />
            <SorterList
              columns={userListColumns}
              columnSorters={userListColumnSorters}
              initialColumnSorterIndex="name"
              items={filteredUserItems}
              onRenderItemColumn={(item, index, column) => {
                const fieldContent = item[column.fieldName];
                switch (column.key) {
                  case "u-name":
                    return (
                      <Stack
                        horizontal
                        styles={{
                          root: { "justify-content": "space-between" },
                        }}
                      >
                        <Stack.Item align="center" shrink styles={shrinkItem}>
                          {fieldContent}
                        </Stack.Item>
                        <Stack.Item disableShrink>
                          <IconButton
                            iconProps={{ iconName: Icons.ICON_NAME_MORE }}
                            menuProps={usersMoreMenu}
                            onRenderMenuIcon={() => <div />} // Disables the chevron
                            menuAs={(props) => <ContextualMenu {...props} />}
                            title={"Show actions"}
                          />
                        </Stack.Item>
                      </Stack>
                    );
                  case "u-resource":
                    return <Resource model={fieldContent} />;
                  case "u-active":
                    return (
                      <Icon
                        iconName={
                          fieldContent ? "CheckboxComposite" : "Checkbox"
                        }
                      />
                    );
                  default:
                    return <span>{fieldContent}</span>;
                }
              }}
              onSelect={this._onSelectUser}
              isItemSelected={(item) =>
                apihelper.entityHasId(item.data, selectedUserId)
              }
            />
          </PivotItem>
          <PivotItem
            linkText="Resources"
            itemCount={filteredResourceItems.length}
            itemIcon={Icons.ICON_NAME_RESOURCE}
          >
            <br />
            <CommandBar
              items={[
                {
                  key: "new-entity",
                  name: "New",
                  iconProps: { iconName: Icons.ICON_NAME_NEW },
                  className: "ms-CommandBarItem",
                  onClick: this._onCreateResource,
                  disabled: !selectedAccount,
                },
              ]}
              farItems={[
                {
                  key: "toggle-actives",
                  text: "Toggle Actives",
                  ariaLabel: "Toggle Actives",
                  iconOnly: true,
                  iconProps: { iconName: "CheckboxComposite" },
                  commandBarButtonAs: () => (
                    <Toggle
                      label="Show Inactives"
                      inlineLabel
                      checked={this.props.toggleInactives}
                      onClick={() => {
                        this._toggleActive();
                      }}
                    />
                  ),
                  onText: "On",
                  offText: "Off",
                },
              ]}
            />
            <SorterList
              columns={resourceListColumns}
              items={filteredResourceItems}
              columnSorters={resourceListColumnSorters}
              initialColumnSorterIndex="name"
              onRenderItemColumn={(item, index, column) => {
                const fieldContent = item[column.fieldName];
                switch (column.key) {
                  case "r-name":
                    return (
                      <Stack
                        horizontal
                        styles={{
                          root: { "justify-content": "space-between" },
                        }}
                      >
                        <Stack.Item align="center">
                          <span>{fieldContent}</span>
                        </Stack.Item>
                        <Stack.Item align="end">
                          <IconButton
                            iconProps={{ iconName: Icons.ICON_NAME_MORE }}
                            menuProps={resourcesMoreMenu}
                            onRenderMenuIcon={() => <div />}
                            menuAs={(props) => <ContextualMenu {...props} />}
                            title={"Show actions"}
                          />
                        </Stack.Item>
                      </Stack>
                    );
                  case "r-user":
                    return <User model={fieldContent} />;
                  case "r-active":
                    return (
                      <Icon
                        iconName={
                          fieldContent ? "CheckboxComposite" : "Checkbox"
                        }
                      />
                    );
                  default:
                    return <span>{fieldContent}</span>;
                }
              }}
              onSelect={this._onSelectResource}
              isItemSelected={(item) =>
                apihelper.entityHasId(item.data, selectedResourceId)
              }
            />
          </PivotItem>
          <PivotItem
            linkText="Permissions"
            itemIcon={Icons.ICON_NAME_PERMISSION}
          >
            <br />
            <MessageBar>{this._currentUserPermissionTypeMessage()}</MessageBar>
            <CommandBar
              items={[
                {
                  key: "new-entity",
                  name: "New",
                  iconProps: { iconName: Icons.ICON_NAME_NEW },
                  className: "ms-CommandBarItem",
                  onClick: this._onCreatePermission,
                  disabled: !selectedAccount,
                },
                {
                  key: "edit-entity",
                  name: "Edit",
                  iconProps: { iconName: Icons.ICON_NAME_EDIT },
                  className: "ms-CommandBarItem",
                  onClick: this._onEditPermission,
                  disabled: !selectedPermissionUserId,
                },
              ]}
            />
            <PermissionsTreeView
              allPermissionsWithParents={allPermissionsWithParents}
              permissionTypeNameToIds={permissionTypeNameToIds}
              columnSorters={{
                account: {
                  name: "Account",
                  sortType: SortingType.LEXICAL,
                  extract: (a) =>
                    a.account ? apihelper.getAttr(a.account, "name") : "",
                },
                client: {
                  name: "Client",
                  sortType: SortingType.LEXICAL,
                  extract: (a) =>
                    a.client ? apihelper.getAttr(a.client, "name") : "",
                },
                project: {
                  name: "Project",
                  sortType: SortingType.LEXICAL,
                  extract: (a) =>
                    a.project ? apihelper.getAttr(a.project, "name") : "",
                },
                user: {
                  name: "User",
                  sortType: SortingType.LEXICAL,
                  extract: (a) => apihelper.getAttr(a.user, "name"),
                },
              }}
              columnGroups={{
                account: {
                  name: "Account",
                  sortType: SortingType.LEXICAL,
                  extract: (a) =>
                    a.account ? apihelper.getAttr(a.account, "name") : "",
                },
                client: {
                  name: "Client",
                  sortType: SortingType.LEXICAL,
                  extract: (a) =>
                    a.client ? apihelper.getAttr(a.client, "name") : "",
                },
                project: {
                  name: "Project",
                  sortType: SortingType.LEXICAL,
                  extract: (a) =>
                    a.project ? apihelper.getAttr(a.project, "name") : "",
                },
                permission: {
                  name: "Permission",
                  sortType: SortingType.LEXICAL,
                  extract: (a) => a.permissionType,
                },
                user: {
                  name: "User",
                  sortType: SortingType.LEXICAL,
                  extract: (a) => apihelper.getAttr(a.user, "name"),
                },
              }}
              onSelect={this._onSelectPermission}
              isItemSelected={(item) => this._isPermissionSelected(item)}
            />
          </PivotItem>
        </Pivot>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selectedAccount = appDomainSelectors.selectedAccount(state);

  let selectedUserId = usersAndResourcesSelectors.selectedUserId(state);
  let selectedResourceId = usersAndResourcesSelectors.selectedResourceId(state);
  let selectedPermissionUserId =
    usersAndResourcesSelectors.selectedPermissionUserId(state);
  let selectedPermissionAccountId =
    usersAndResourcesSelectors.selectedPermissionAccountId(state);
  let selectedPermissionClientId =
    usersAndResourcesSelectors.selectedPermissionClientId(state);
  let selectedPermissionProjectId =
    usersAndResourcesSelectors.selectedPermissionProjectId(state);
  let allResourceType = resourceTypeSelectors.allResourceType(state);

  let currentUser = UserReducers.currentUser(state);
  let selectedUser = usersAndResourcesSelectors.userBySelectedId(state);
  let selectedResource = usersAndResourcesSelectors.selectedResource(state);
  let permissionTypeNameToIds =
    usersAndResourcesSelectors.permissionTypeNameToIds(state);
  let allPermissions = reducerPermissionSelectors.allPermissions(state);
  let allPermissionsWithParents =
    usersAndResourcesSelectors.allPermissionsWithParents(state);

  let selectedPermissionUserPermissions =
    usersAndResourcesSelectors.selectedPermissionUserPermissions(state);
  let allUsersForSelectedAccount =
    usersAndResourcesSelectors.allUsersForSelectedAccount(state);
  let allResourcesForSelectedAccount =
    appDomainSelectors.allResourcesForSelectedAccount(state);

  let allClientsForPermissions =
    usersAndResourcesSelectors.allClientsForPermissions(state);
  let allClientsForSelectedAccount =
    usersAndResourcesSelectors.allClientsForSelectedAccount(state);
  allClientsForSelectedAccount.forEach((c1) => {
    if (
      !allClientsForPermissions.find((c2) =>
        apihelper.entityHasId(c1, apihelper.getEntityId(c2))
      )
    ) {
      allClientsForPermissions.push(c1);
    }
  });

  let allProjectsForPermissions =
    usersAndResourcesSelectors.allProjectsForPermissions(state);
  let allProjectsForSelectedAccount =
    usersAndResourcesSelectors.allProjectsForSelectedAccount(state);
  allProjectsForSelectedAccount.forEach((p1) => {
    if (
      !allProjectsForPermissions.find((p2) =>
        apihelper.entityHasId(p1, apihelper.getEntityId(p2))
      )
    ) {
      allProjectsForPermissions.push(p1);
    }
  });

  let modelUnderEdit = editorSelectors.modelUnderEdit(state);
  let localeId =
    modelUnderEdit &&
    !Array.isArray(modelUnderEdit) &&
    apihelper.getRelId(modelUnderEdit, "employedAtLocale");
  let locale = localeSelectors.localeById(state, localeId);

  let toggleInactives = usersAndResourcesSelectors.toggleInactives(state); //

  let filteredUserItems = allUsersForSelectedAccount.map((u) => {
    return {
      firstName: apihelper.getAttr(u, "firstName"),
      lastName: apihelper.getAttr(u, "lastName"),
      name: apihelper.getAttr(u, "name"),
      email: apihelper.getAttr(u, "email"),
      uniqueId: apihelper.getAttr(u, "uniqueIdentifier"),
      active: apihelper.getAttr(u, "active"),
      resource: allResourcesForSelectedAccount.find((r) =>
        apihelper.relRefersToEntity(u, "resource", r)
      ),
      data: u,
    };
  });

  if (typeof toggleInactives !== "undefined") {
    filteredUserItems = filteredUserItems.filter((i) =>
      toggleInactives ? true : i.active
    );
  }

  let filteredResourceItems = allResourcesForSelectedAccount.map((r) => {
    let resourcetypefind = allResourceType.find(
      (rt) => (r, "resourceType", rt)
    );
    return {
      active: apihelper.getAttr(r, "isActive"),
      name: apihelper.getAttr(r, "name"),
      employedAtLocale: locale ? locale.attributes.name : "",
      description: apihelper.getAttr(r, "description"),
      user: allUsersForSelectedAccount.find((u) =>
        apihelper.relRefersToEntity(r, "user", u)
      ),
      type: resourcetypefind && resourcetypefind.attributes.name,
      data: r,
    };
  }); //

  if (typeof toggleInactives !== "undefined") {
    filteredResourceItems = filteredResourceItems.filter((i) =>
      toggleInactives ? true : i.active
    );
  }

  return {
    isLoggedIn: isLoggedIn(state),
    allAccounts: reducerAccountSelectors.allAccounts(state),
    selectedAccount,
    selectedUserId,
    selectedUser,
    selectedResourceId,
    selectedResource,
    selectedPermissionUserId,
    selectedPermissionAccountId,
    selectedPermissionClientId,
    selectedPermissionProjectId,
    selectedPermissionUserPermissions,
    allUsersForSelectedAccount,
    allClientsForPermissions,
    allProjectsForPermissions,
    allResourcesForSelectedAccount,
    permissionTypeNameToIds,
    locale: locale,
    allResourceType,
    toggleInactives,
    filteredResourceItems,
    filteredUserItems,
    allPermissions,
    allPermissionsWithParents,
    currentUser,
    //
    //    TODO: CLEANUP SELECTORS!
    //
  };
}

function mapDispatch(dispatch) {
  const actions = Object.assign({}, usersAndResourcesActions, editorActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(UsersAndResources);
