import * as apihelper from "../../selectors/apihelper";

/*
One of the inputs of the component "ClientAndProjectPicker" is something called a "group"
Which is an object, who instructs the "ClientAndProjectPicker" of how it should visualise the given items.
This has similarities to a tree and its branches. That is, something on the form:

            0
         /  |  \ 
        0   0   0 
      / | \.. / | \
(Note that there will most likely be more than one root in the generated tree!)
These 0 will then become a sub-dropdown in the representation of the items. (This is similarly to the headlines in word, which may have more than one sub-headline) 
The pickerTree gives us a way to generate such a tree. In the above illustration the pickerNode class will become the 0's 
*/

import { ResourceObject } from "../../lib/models";

export type ParentOptions = "Parent" | "Orphan" | "Exclude";

export const ParentOptions = {
  PARENT: "Parent", // has another entity as parent
  ORPHAN: "Orphan", // is valid but does not have a parent (mount under root)
  EXCLUDE: "Exclude", // disregard this entity in the picker tree
};

export class PickerNode {
  private parentNode: PickerNode | null = null;

  constructor(
    private entity: ResourceObject,
    private parentOption: ParentOptions
  ) {}

  getParentOption = () => this.parentOption;

  setParent(parentNode: PickerNode) {
    this.parentNode = parentNode;
  }

  getParent = () => this.parentNode;

  getEntity = () => this.entity;
}

export const getEntityKey = (entity: ResourceObject) =>
  apihelper.getEntityType(entity) + "-" + apihelper.getEntityId(entity);

export class PickerTree {
  private nodes: Record<string, PickerNode>;
  private parentMap: Record<string, string[]>;

  constructor() {
    this.nodes = {}; // key -> PickerNode
    this.parentMap = {}; // parent key -> [child keys]
  }

  getNodeByEntityKey(entityKey: string): PickerNode | undefined {
    return this.nodes[entityKey];
  }

  insert(
    entity: ResourceObject,
    parentOption: ParentOptions,
    parentEntity: ResourceObject
  ) {
    if (parentOption === ParentOptions.EXCLUDE) {
      // Makes sure we do not add excluded notes to the tree
      return;
    }

    const n = new PickerNode(entity, parentOption);
    const nKey = getEntityKey(entity);
    this.nodes[nKey] = n;

    if (parentOption === ParentOptions.PARENT) {
      //If the pickerNode is a child of another node:
      const pKey = getEntityKey(parentEntity); //Get the key of the parent
      this.parentMap[pKey] = this.parentMap[pKey] || []; //Makes sure that we have an array to push the child-key into
      this.parentMap[pKey].push(nKey); //add the child to the parent

      if (this.nodes[pKey]) {
        //If the parent is already added to the tree, inform the child of the relation (note that this has no effect if the parents are inserted to the tree first)
        n.setParent(this.nodes[pKey]);
      }
    }

    if (
      Array.isArray(this.parentMap[nKey]) &&
      this.parentMap[nKey].length > 0
    ) {
      this.parentMap[nKey].forEach(
        (
          childKey // If the entity n has children, it informs the children that n is the parent
        ) => this.nodes[childKey].setParent(n),
        this
      );
    }
  }

  getTopLevelNodes() {
    return Object.values(this.nodes).filter(
      (node) => node.getParentOption() === ParentOptions.ORPHAN
    );
  }

  getChildrenOfParent(parentNode: PickerNode) {
    const pKey = getEntityKey(parentNode.getEntity());
    const childKeys = this.parentMap[pKey] || []; // Get childrenKeys of the parentNode, if there are any.
    return childKeys.map((cKey) => this.nodes[cKey], this); // return these pickerNodes
  }
}
