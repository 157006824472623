import React, { useCallback, useMemo } from "react";
import StateMerge from "../../lib/stateMerge/StateMerge";
import HourSet from "../../lib/HourSet";
import * as apihelper from "../../selectors/apihelper";
import WeekGrid from "./WeekGrid";
import WeekCellField from "./WeekCellField";
import ProjectBrowserModal from "../projectbrowser/ProjectBrowserModal";
import { IconButton } from "@fluentui/react/lib/Button";
import ExtensionAlert from "./extensionAlert";
import * as Icons from "../../constants/Icons";
import { isProjectResourceActiveAtDate } from "../../lib/projectResources";
import "../../lib/times";
import { isSameDay } from "../../lib/date";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import { ValidationCollection } from "../../lib/modelValidators";
import * as datehelper from "../../lib/date";
import * as workLocationRegistrationSelectors from "../../selectors/reducers/workLocationRegistration";
import WorkFromHomeToggle from "./WorkFromHomeToggle";
import { UserPermissionSet } from "../../lib/PermissionSet";
import { useCurrentUserContext } from "../../hooks/contexts";
import { DelayedRender, Stack, Text } from "@fluentui/react";

type RegistrationTableRowWeekDay = {
  value: number;
  hasCurrentEdit: boolean;
  isClosed: boolean;
  isDisabled: boolean;
  failedValidations: Record<string, ValidationCollection>;
  hasActiveProjectResource: boolean;
  numberOfTimeRegistrations: number;
};

type RegistrationTableRow = {
  projectId: string;
  project: ResourceObject;
  hasWritePermissionAssigned: boolean;
  names: {
    short: string;
    remaining: string;
    full: string;
  };
  isRemovable: boolean;
  week: Record<number, RegistrationTableRowWeekDay>;
  hash: string;
  projectResources: ResourceObjects;
  contractRoles: ResourceObjects;
};

const toRow = (
  project: ResourceObject,
  isRemovable: boolean,
  selectedResource: ResourceObject,
  firstDayOfWeek: Date,
  currentEdit: ResourceObject | null,
  closedDatesInWeek: Date[],
  hourSet: HourSet,
  stateMerge: StateMerge,
  currentUserPermissionSet: UserPermissionSet | null
): RegistrationTableRow => {
  const projectResources = hourSet.getProjectResourcesForResourceAndProject(
    selectedResource,
    project
  );
  const contractRoles = hourSet.getContractRolesForProject(project);
  const client = hourSet.getClientForProject(project);
  const shortName = apihelper.getAttr(project, "name") as string;
  const remainingName = client ? apihelper.getAttr(client, "name") + " - " : "";
  let hash = remainingName + shortName;
  const curEditStartDate =
    currentEdit &&
    new Date(apihelper.getAttr(currentEdit, "startTime") as string);
  const week = [0, 1, 2, 3, 4, 5, 6].reduce((weekResult, offset) => {
    const weekDay = datehelper.addDays(firstDayOfWeek, offset);

    const timeRegs = stateMerge.registrationsByResourceAndProjectAndDay(
      selectedResource,
      project,
      weekDay
    );
    const hasCurrentEdit =
      !!currentEdit &&
      !!apihelper.relRefersToEntity(currentEdit, "project", project) &&
      (curEditStartDate as Date).getDate() === weekDay.getDate();
    const isClosed = !!closedDatesInWeek.find((cd) => isSameDay(cd, weekDay));
    const value = stateMerge.aggrHours(timeRegs);
    const failedValidations: Record<string, ValidationCollection> =
      timeRegs.reduce((acc, timeReg) => {
        const validations = hourSet.getTimeRegFailedValidations(timeReg);
        if (Object.keys(validations).length > 0) {
          acc[apihelper.getEntityId(timeReg) as string] = validations;
        }
        return acc;
      }, {} as Record<string, ValidationCollection>);
    const activeProjectResources =
      projectResources &&
      projectResources.filter(isProjectResourceActiveAtDate(weekDay));

    weekResult[weekDay.getDate()] = {
      value,
      hasCurrentEdit,
      isClosed,
      isDisabled: isClosed,
      failedValidations,
      hasActiveProjectResource: activeProjectResources.length > 0,
      numberOfTimeRegistrations: timeRegs.length,
    };
    hash +=
      value +
      "." +
      hasCurrentEdit +
      "." +
      isClosed +
      "." +
      failedValidations.size +
      ".";
    return weekResult;
  }, {} as Record<number, RegistrationTableRowWeekDay>);

  return {
    projectId: apihelper.getEntityId(project) as string,
    hasWritePermissionAssigned:
      !!currentUserPermissionSet &&
      currentUserPermissionSet.hasProjectWrite(project),
    project,
    names: {
      short: shortName,
      remaining: remainingName,
      full: remainingName + shortName,
    },
    isRemovable,
    week,
    hash,
    projectResources,
    contractRoles,
  };
};

const genRows = (
  favoriteProjectIds: string[],
  hourSet: HourSet,
  stateMerge: StateMerge,
  selectedResource: ResourceObject,
  firstDayOfWeek: Date,
  currentEdit: ResourceObject | null,
  closedDatesInWeek: Date[],
  currentUserPermissionSet: UserPermissionSet | null
) => {
  const favs: Record<string, boolean> = favoriteProjectIds.reduce(
    (acc, cur) => {
      acc[cur] = true;
      return acc;
    },
    {} as Record<string, boolean>
  );
  const projectIds = stateMerge
    .getProjectIdsWithHours()
    .concat(favoriteProjectIds)
    .unique();
  const projects = projectIds
    .map((projectId) => hourSet.getProjectById(projectId))
    .filter((p) => !!p);

  return projects
    .map((project) =>
      toRow(
        project,
        !!favs[apihelper.getEntityId(project) as string],
        selectedResource,
        firstDayOfWeek,
        currentEdit,
        closedDatesInWeek,
        hourSet,
        stateMerge,
        currentUserPermissionSet
      )
    )
    .sort((a: RegistrationTableRow, b: RegistrationTableRow) =>
      a.names.full < b.names.full ? -1 : a.names.full > b.names.full ? 1 : 0
    );
};

type ProjectHeaderProps = {
  shortName: string;
  remainingName: string;
  projectResources: ResourceObjects;
  contractRoles: ResourceObjects;
  weekDays: Date[];
  isRemovable: boolean;
  onRemoveFavorite: () => void;
};

function ProjectHeader({
  shortName,
  remainingName,
  projectResources,
  contractRoles,
  weekDays,
  onRemoveFavorite,
  isRemovable,
}: ProjectHeaderProps) {
  return (
    <div>
      <span className="ms-hiddenLgDown">{remainingName}</span>
      <span>{shortName}</span>
      <ExtensionAlert
        projectResources={projectResources}
        contractRoles={contractRoles}
        weekDays={weekDays}
      />
      {isRemovable ? (
        <IconButton
          className="remove-project-icon"
          //              tabIndex="-1"
          iconProps={{ iconName: Icons.ICON_NAME_REMOVE }}
          onClick={onRemoveFavorite}
        />
      ) : null}
    </div>
  );
}

function WorkLocationSummary({
  workLocationRegistration,
}: {
  workLocationRegistration?: ResourceObject;
}) {
  const timeIntervalString = workLocationRegistration
    ? `${datehelper.toHhMm(
        new Date(
          apihelper.getAttr(workLocationRegistration, "startTime") as string
        )
      )} to ${datehelper.toHhMm(
        new Date(
          apihelper.getAttr(workLocationRegistration, "endTime") as string
        )
      )}`
    : "";

  const description = workLocationRegistration
    ? apihelper.getAttr(workLocationRegistration, "description")
    : "";

  return (
    <DelayedRender>
      <div style={{ maxWidth: "300px" }}>
        <Stack
          tokens={{
            childrenGap: 10,
          }}
        >
          <Text block variant="small" style={{ fontWeight: "600" }}>
            {timeIntervalString}
          </Text>
          {description ? (
            <Text block variant="small">
              {description}
            </Text>
          ) : null}
        </Stack>
      </div>
    </DelayedRender>
  );
}

export type RegistrationTableProps = {
  selectedResource: ResourceObject;
  dayInWeek: Date;
  stateMerge: StateMerge;
  hourSet: HourSet;
  favoriteProjectIds: string[];
  currentEdit: ResourceObject | null;
  closedDatesInWeek: Date[];
  workLocationRegistrations: ResourceObjects;
  startEditInCell: (project: ResourceObject, date: Date) => void;
  addNewInCell: (project: ResourceObject, date: Date) => void;
  onCurrentEditCellValueChanged: (fractionalHours: number, date: Date) => void;
  onRemoveFromFavorites: (project: ResourceObject) => void;
  onAddToFavorites: (projects: ResourceObjects) => void;
  onWorkFromHomeToggleChange: (date: Date, checked?: boolean) => void;
};

export default function RegistrationTable({
  selectedResource,
  dayInWeek,
  stateMerge,
  hourSet,
  favoriteProjectIds,
  currentEdit,
  closedDatesInWeek,
  startEditInCell,
  addNewInCell,
  onCurrentEditCellValueChanged,
  onRemoveFromFavorites,
  onAddToFavorites,
  workLocationRegistrations,
  onWorkFromHomeToggleChange,
}: RegistrationTableProps) {
  const startOfWeek = datehelper.startOfWeek(dayInWeek);
  const { currentUserPermissionSet, user: currentUser } =
    useCurrentUserContext();
  const rows = useMemo(
    () =>
      genRows(
        favoriteProjectIds,
        hourSet,
        stateMerge,
        selectedResource,
        startOfWeek,
        currentEdit,
        closedDatesInWeek,
        currentUserPermissionSet
      ),
    [
      favoriteProjectIds,
      hourSet,
      stateMerge,
      selectedResource,
      dayInWeek,
      currentEdit,
      closedDatesInWeek,
    ]
  );
  const rowIds = useMemo(() => rows.map((row) => row.projectId), [rows]);
  const rowLookup = useMemo(
    () =>
      rows.reduce((dictionary, row) => {
        dictionary[row.projectId] = row;
        return dictionary;
      }, {} as Record<string, RegistrationTableRow>),
    [rows]
  );
  const favoriteProjects = useMemo(
    () =>
      favoriteProjectIds
        .map((pId) => hourSet.getProjectById(pId))
        .filter((p) => !!p),
    [hourSet]
  );

  const currentUserIsSelectedResource = useMemo<boolean>(
    () =>
      currentUser !== null &&
      !!apihelper.relRefersToEntity(currentUser, "resource", selectedResource),
    [selectedResource, currentUser]
  );
  const firstWorkLocationRegistrationOfDay: (
    weekDay: Date
  ) => ResourceObject | undefined = useCallback(
    (weekDay: Date) =>
      workLocationRegistrationSelectors.filterByPeriod(
        workLocationRegistrations,
        datehelper.startOfDay(weekDay),
        datehelper.endOfDay(weekDay)
      )[0],
    [dayInWeek, workLocationRegistrations]
  );

  const renderRowHeader = useCallback(
    (rowId: string, weekDays: Date[]) => {
      const row = rowLookup[rowId];
      return (
        <ProjectHeader
          onRemoveFavorite={() => onRemoveFromFavorites(row.project)}
          shortName={row.names.short}
          remainingName={row.names.remaining}
          projectResources={row.projectResources}
          contractRoles={row.contractRoles}
          weekDays={weekDays}
          isRemovable={row.isRemovable}
        />
      );
    },
    [rows]
  );

  const renderRowCell = useCallback(
    (rowId: string, weekDay: Date) => {
      const row = rowLookup[rowId];
      const startEdit = () => startEditInCell(row.project, weekDay);
      const addNew = () => addNewInCell(row.project, weekDay);
      const rowDay = row.week[weekDay.getDate()];
      const onChange = (fracHours: number) =>
        onCurrentEditCellValueChanged(fracHours, weekDay);
      const isEditable =
        rowDay.hasActiveProjectResource &&
        !rowDay.isClosed &&
        (currentUserIsSelectedResource || row.hasWritePermissionAssigned);
      return (
        <WeekCellField
          onFocus={startEdit}
          onFocusReject={startEdit}
          onChange={onChange}
          onAddNewClick={addNew}
          value={rowDay.value}
          showAddNewButton={
            !rowDay.isClosed &&
            rowDay.hasCurrentEdit &&
            rowDay.numberOfTimeRegistrations > 0
          }
          isHighlighted={rowDay.hasCurrentEdit}
          isFocusable={rowDay.numberOfTimeRegistrations < 2 && isEditable}
          failedValidations={Object.values(rowDay.failedValidations)}
          disabled={rowDay.isDisabled || !rowDay.hasActiveProjectResource}
          editable={isEditable}
        />
      );
    },
    [rows, onCurrentEditCellValueChanged, startEditInCell, addNewInCell]
  );

  const renderRowSummation = useCallback(
    (rowId: string) => {
      const row = rowLookup[rowId];
      return <div>{stateMerge.getTotalByProject(row.project)}</div>;
    },
    [rows]
  );

  const renderDaySummation = useCallback(
    (weekDay: Date) => {
      return <div>{stateMerge.getTotalByDay(weekDay)}</div>;
    },
    [rows]
  );

  const renderWeekSummation = useCallback(() => {
    return <div>{stateMerge.getTotalDuration()}</div>;
  }, [rows]);

  const renderFooter = useCallback(
    () => (
      <ProjectBrowserModal
        projects={hourSet.getProjects()}
        selectedProjects={favoriteProjects}
        clients={hourSet.getClients()}
        contracts={hourSet.getContracts()}
        onItemSelected={onAddToFavorites}
        hourSet={hourSet}
        selectedResource={selectedResource}
      />
    ),
    [hourSet, selectedResource]
  );

  const renderWorkLocationIndicator = (weekDay: Date) => {
    const workLocationRegistration =
      firstWorkLocationRegistrationOfDay(weekDay);
    const isWorkingFromHome = typeof workLocationRegistration !== "undefined";
    const isSameDayAsCurrentEdit =
      currentEdit &&
      datehelper.isSameDay(
        new Date(apihelper.getAttr(currentEdit, "startTime") as string),
        weekDay
      );

    const onChange = (
      event: React.MouseEvent<HTMLElement>,
      checked?: boolean
    ) => onWorkFromHomeToggleChange(weekDay, checked);

    const isEditable =
      rows.some((row) => row.hasWritePermissionAssigned) ||
      currentUserIsSelectedResource;

    // todo: isClosed
    return (
      <WorkFromHomeToggle
        isModifyable={!!isSameDayAsCurrentEdit}
        showIndicator={isSameDayAsCurrentEdit || isWorkingFromHome}
        isIndicatorExpandable={!!workLocationRegistration}
        onChange={onChange}
        isEditable={isEditable}
        value={isWorkingFromHome}
      >
        <WorkLocationSummary
          workLocationRegistration={workLocationRegistration}
        />
      </WorkFromHomeToggle>
    );
  };

  return (
    <WeekGrid
      startOfWeek={startOfWeek}
      rowIds={rowIds}
      renderRowHeader={renderRowHeader}
      renderRowCell={renderRowCell}
      renderRowSummation={renderRowSummation}
      renderDaySummation={renderDaySummation}
      renderWeekSummation={renderWeekSummation}
      renderFooterHeader={renderFooter}
      renderAdditionalHeader={renderWorkLocationIndicator}
    />
  );
}
