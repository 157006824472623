import * as types from "../../constants/ActionTypes";

export function pageOpened() {
  return {
    type: types.APP_PAGE_TIMEREGISTRATIONEXPORT_OPENED,
  };
}

export function selectDates(startDate, endDate) {
  return updateFilter({ startDate, endDate });
}

export function selectProjectIds(projectIds) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_INIT,
    payload: {
      projectIds,
    },
  };
}

export function selectResourceTypeIds(resourceTypeIds) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_INIT,
    payload: {
      resourceTypeIds,
    },
  };
}

export function selectResourceIds(resourceIds) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_INIT,
    payload: {
      resourceIds,
    },
  };
}

export function updateFilter({
  projectIds,
  resourceTypeIds,
  resourceIds,
  showInactiveResources,
  startDate,
  endDate,
} = {}) {
  //Logic for deciding props for payload
  let payload = {};
  if (projectIds) payload.projectIds = projectIds;
  if (resourceTypeIds) payload.resourceTypeIds = resourceTypeIds;
  if (resourceIds) payload.resourceIds = resourceIds;
  if (showInactiveResources)
    payload.showInactiveResources = showInactiveResources;
  if (startDate)
    payload.startTime =
      startDate instanceof Date ? startDate.toISOString() : startDate;
  if (endDate)
    payload.endTime = endDate instanceof Date ? endDate.toISOString() : endDate;

  return {
    type: types.APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_INIT,
    payload,
  };
}
