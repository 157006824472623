import * as types from "../../constants/ActionTypes";

const initialState = {
  selectedCell: null,
  filter: {
    projectId: null,
    clientId: null,
    resourceTypeIds: [],
    showInactiveResources: false,
    startTime: null,
    endTime: null,
  },
  viewDesc: {},
  showProsseingDialog: false,
  processingProgression: 0,
};

function resetSelectedCell(state) {
  return Object.assign({}, state, {
    selectedCell: null,
  });
}

function updateSelectedCell(state, { resourceId, date, timeRegistrationIds }) {
  return Object.assign({}, state, {
    selectedCell: {
      resourceId,
      date,
      timeRegistrationIds,
    },
  });
}

export default function resourceManagement(state = initialState, action) {
  switch (action.type) {
    case types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_CHANGE: {
      return Object.assign({}, state, {
        filter: Object.assign({}, action.payload),
      });
    }

    case types.APP_PAGE_RESOURCEMANAGEMENT_CLEAR_SELECTED_CELL: {
      return resetSelectedCell(state);
    }

    case types.APP_PAGE_RESOURCEMANAGEMENT_CELL_SELECTED: {
      return updateSelectedCell(state, action.payload);
    }

    case types.APP_PAGE_RESOURCEMANAGEMENT_TR_SELECTED: {
      return Object.assign({}, state, {
        timeRegistration: action.payload,
      });
    }

    case types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_VIEW_DESC: {
      return Object.assign({}, state, { viewDesc: action.payload });
    }

    case types.APP_PAGE_RESOURCEMANAGEMENT_TOGGLE_PROCESSING_DIALOG: {
      return Object.assign({}, state, {
        showProsseingDialog: !state.showProsseingDialog,
      });
    }

    case types.APP_PAGE_RESOURCEMANAGEMENT_PERIODS_PROCESSING_PROGRESS: {
      return Object.assign({}, state, {
        processingProgression: action.payload.progressInPercent,
      });
    }

    default:
      return state;
  }
}
