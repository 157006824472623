import { currentUser } from "./user";
import {
  allProjectResourcesByProject,
  allProjectResourcesByProjectAndTimestamp,
  allProjectResourcesByProjects,
} from "./projectResource";
import { allProjectsByClient } from "./project";
import { accountByClient, currentUserAccount } from "./account";
import { contractRoleById } from "./contractRole";
import { clientByContractRole } from "./client";
import {
  entityHasId,
  getAttr,
  getEntityId,
  getRelId,
  relIsPresent,
  relRefersToEntity,
} from "../apihelper";
import { allResourceType } from "./resourceType";
import "../../lib/groupBy";
import "../../lib/unique";
import * as contractRoleSelectors from "./contractRole";
import * as contractSelectors from "./contract";
import * as clientSelectors from "./client";
import * as accountSelectors from "./account";
import * as storehelper from "../storehelper";
import * as projectResourceSelectors from "./projectResource";
import { ResourceObject, ResourceObjects } from "../../lib/models";

const ResourceTypeValues = allResourceType;

export const allResources = (state: any): ResourceObjects =>
  state.api.resource.allResources;

export const filterResourcesByTypeIds = (
  resources: ResourceObjects,
  resourceTypeIds: string[]
) =>
  resources.filter((c) =>
    resourceTypeIds.includes(getRelId(c, "resourceType") as string)
  );

export const filterActiveResources = (resources: ResourceObjects) =>
  resources.filter((resource) => getAttr(resource, "isActive"));

export const filterByAccounts = (
  resources: ResourceObjects,
  accounts: ResourceObjects
) =>
  storehelper.filterByReferenceToRelatedEntities(
    resources,
    accounts,
    "account"
  );

export const filterByProjectResources = (
  resources: ResourceObjects,
  projectResources: ResourceObjects
) => storehelper.filterByRelatedEntity(resources, projectResources, "resource");

// return all resources from the provided set that are assignable as either via default project resources or are directly assigned
export const filterAssignableResources = (
  resources: ResourceObjects,
  projectResources: ResourceObjects,
  contractRoles: ResourceObjects,
  contracts: ResourceObjects,
  clients: ResourceObjects,
  accounts: ResourceObjects
) => {
  const defaultProjectResources = projectResources
    ? projectResources.filter(projectResourceSelectors.isDefaultProjectResource)
    : [];
  const defaultProjectResourceAccounts = accountSelectors.filterByClients(
    accounts,
    clientSelectors.filterByContracts(
      clients,
      contractSelectors.filterByContractRoles(
        contracts,
        contractRoleSelectors.filterByProjectResources(
          contractRoles,
          defaultProjectResources
        )
      )
    )
  );

  // every resource at an account can be allocated to a default project resource
  const acctResources = filterByAccounts(
    resources,
    defaultProjectResourceAccounts
  );

  // now get the direct assignments
  const userSpecificProjectResources = projectResources
    ? projectResources.filter(
        (pr) => !projectResourceSelectors.isDefaultProjectResource(pr)
      )
    : [];
  const directlyAssignedResources = filterByProjectResources(
    resources,
    userSpecificProjectResources
  );

  return Array.from(new Set([...acctResources, ...directlyAssignedResources]));
};

export const filterByResourceId = (
  allResources: ResourceObjects,
  resourceId: string
) => storehelper.findById(allResources, resourceId);

export const allResourcesByTypeIds = (state: any, resourceTypeIds: string[]) =>
  filterResourcesByTypeIds(allResources(state), resourceTypeIds);

export const allResourcesByAccount = (
  state: any,
  account: ResourceObject,
  resourceTypes = ResourceTypeValues(state)
) => {
  const resources = allResources(state).filter((c) =>
    relRefersToEntity(c, "account", account)
  );
  return filterResourcesByTypeIds(
    resources,
    resourceTypes.map((rt: ResourceObject) => getEntityId(rt))
  );
};

export const allResourcesForCurrentAccount = (state: any) =>
  allResourcesByAccount(state, currentUserAccount(state));

export const resourceById = (state: any, resourceId: string) =>
  filterByResourceId(allResources(state), resourceId);

export const resourcebyIds = (state: any, resourceIds: string[]) =>
  allResources(state).filter((r: ResourceObject) =>
    resourceIds.includes(getEntityId(r) as string)
  );

export const resourceByUser = (state: any, user: ResourceObject) =>
  user != null &&
  relIsPresent(user, "resource") &&
  resourceById(state, getRelId(user, "resource") as string);

export const resourceForCurrentUser = (state: any) =>
  resourceByUser(state, currentUser(state) as ResourceObject);

export const resourcesByProject = (
  state: any,
  project: ResourceObject,
  resourceTypes = ResourceTypeValues(state)
) => {
  const projectResources = allProjectResourcesByProject(state, project);
  const resources = resourcesFromProjectResources(state, projectResources);
  return filterResourcesByTypeIds(
    resources,
    resourceTypes.map((rt: ResourceObject) => getEntityId(rt))
  );
};

export const allResourcesAllocatedToProject = (
  state: any,
  project: ResourceObject,
  date: Date
) =>
  resourcesFromProjectResources(
    state,
    allProjectResourcesByProjectAndTimestamp(state, project, date)
  );

const resourcesFromProjectResources = (
  state: any,
  projectResources: ResourceObjects
) => {
  const { nonDefault: notDefault, default: isDefault } =
    projectResourceSelectors.splitByDefaultProjectResource(projectResources);

  // get resources from each account
  const accts: ResourceObjects = isDefault
    .map((pr) => getRelId(pr, "contractRole") as string)
    .unique()
    .map((crId) => contractRoleById(state, crId) as ResourceObject)
    .map((cr) => clientByContractRole(state, cr) as ResourceObject)
    .map((cl) => accountByClient(state, cl) as ResourceObject)
    .unique();
  const acctResources = accts
    .map((acct) => allResourcesByAccount(state, acct))
    .flatten();

  // get resources for specific project resources
  const specificResources = notDefault
    .map((pr) => getRelId(pr, "resource") as string)
    .map((rId) => resourceById(state, rId));

  return Array.from(new Set([...acctResources, ...specificResources]));
};

export const resourcesByClient = (
  state: any,
  client: ResourceObject,
  resourceTypes = ResourceTypeValues(state)
) => {
  const projectResources = allProjectResourcesByProjects(
    state,
    allProjectsByClient(state, client)
  );
  const resources = resourcesFromProjectResources(state, projectResources);
  return filterResourcesByTypeIds(resources, resourceTypes.map(getEntityId));
};

export const currentUserResource = (state: any) =>
  state.api.resource.currentUserResource;
