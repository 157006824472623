import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "../../lib/flatten";
import "../../lib/unique";
import {
  createEmptyModel,
  createEmptyModelAttributes,
} from "../../lib/emptyModelCreation";
import { AnimatePresence, motion } from "framer-motion";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog";
import { Icon } from "@fluentui/react/lib/Icon";
import WeekPicker from "../../components/uifabricextensions/WeekPicker";
import { Resource } from "../../components/uifabricextensions/Resource";
import ResourcePicker from "../../components/uifabricextensions/ResourcePicker";
import HourField from "../../components/HourField";
import TimeRegistrationForm from "../../components/forms/TimeRegistrationForm";
import RegistrationTable from "../../components/timeRegistration/RegistrationTable";
import Grid from "../../components/grid/Grid";
import Row from "../../components/grid/Row";
import Column from "../../components/grid/Column";
import HorizontalRule from "../../components/HorizontalRule";
import OutsideAlerter from "../../components/OutsideAlerter";
import TimeRegistrationList from "../../components/lists/TimeRegistrationList";

import * as permissionSelectors from "../../selectors/reducers/permission";
import * as accountReducerSelectors from "../../selectors/reducers/account";
import * as authReducerSelectors from "../../selectors/reducers/auth";
import * as timeRegistrationSelectors from "../../selectors/pages/timeRegistration";
import * as writeBackQueueReducerSelectors from "../../selectors/reducers/writeBackQueue";
import * as apihelper from "../../selectors/apihelper";
import * as userReducerSelectors from "../../selectors/reducers/user";
import * as resourceReducerSelectors from "../../selectors/reducers/resource";
import * as projectReducerSelectors from "../../selectors/reducers/project";
import * as contractRoleReducerSelectors from "../../selectors/reducers/contractRole";
import * as timeRegistrationActions from "../../actions/pages/timeRegistration";
import * as lockedTimePeriodActions from "../../actions/api/lockedTimePeriod";
import * as editorActions from "../../actions/EntityEditor";
import * as billabilitySelectors from "../../selectors/reducers/billabilityTypes";
import * as timeRegistrationStatusSelectors from "../../selectors/reducers/timeRegistrationStatus";
import * as workLocationSelectors from "../../selectors/reducers/workLocation";

import * as EntityTypes from "../../constants/EntityTypes";
import * as Icons from "../../constants/Icons";
import { isProjectResourceActiveAtDate } from "../../lib/projectResources";
import * as storehelper from "../../selectors/storehelper";
import * as projectStatusSelectors from "../../selectors/reducers/projectStatus";
import {
  NewResourceObject,
  ResourceIdentifier,
  ResourceObject,
  ResourceObjects,
} from "../../lib/models";
import StateMerge from "../../lib/stateMerge/StateMerge";
import LockedTimePeriodIndex from "../../lib/LockedTimePeriodIndex";
import HourSet from "../../lib/HourSet";
import { Notification } from "../../reducers/app/notification";
import * as datehelper from "../../lib/date";
import { v1 as uuidv1 } from "uuid";
import { UserPermissionSet } from "../../lib/PermissionSet";
import { QueueChangeStatus } from "../../lib/ModelMergeQueue";
import { IColumn, MessageBar, MessageBarType } from "@fluentui/react";
import WorkLocationRegistrationForm from "../../components/forms/WorkLocationRegistrationForm";
import DeleteForm from "../../components/forms/DeleteForm";

const roundAndFraction = (hours: number, minutes: number) => {
  // round to closest 15 mins
  const m = ((((minutes + 7.5) / 15) | 0) * 15) % 60;
  const h = (((minutes / 105 + 0.5) | 0) + hours) % 24;
  // make into fractional number
  return h + m / 60;
};

const dateAtFraction = (date: Date | string, fractionalHours: number) =>
  datehelper.sameDayAt(
    new Date(date),
    Math.trunc(fractionalHours),
    (fractionalHours % 1) * 60
  );

const getIntervalWithinDay = (
  startTime: Date,
  durationInFractionalHours: number
): { startTime: Date; endTime: Date } => {
  let startFractionalHour = roundAndFraction(
    startTime.getHours(),
    startTime.getMinutes()
  );
  const latestFractionalHour = 23.75;
  const duration = Math.min(durationInFractionalHours, latestFractionalHour);
  let endFractionalHour = startFractionalHour + duration;
  const endOverLatest = Math.max(endFractionalHour - latestFractionalHour, 0);
  startFractionalHour = startFractionalHour - endOverLatest;
  endFractionalHour = endFractionalHour - endOverLatest;

  return {
    startTime: dateAtFraction(startTime, startFractionalHour),
    endTime: dateAtFraction(startTime, endFractionalHour),
  };
};

const defaultStartTime = (date: Date) => datehelper.sameDayAt(date, 9);

type CellData = {
  timeRegistrations: Array<ResourceObject>;
  resource: ResourceObject;
  project: ResourceObject;
  date: {
    dateObj: Date;
  };
  projectResources: Array<ResourceObject>;
};

type TimeRegistrationLockedPeriodProps = {
  closedDates: Date[];
  isWeekClosed: boolean;
  canWeekClose: boolean;
  startOfWeekCloseDate: null | Date;
  endOfWeekCloseDate: null | Date;
  isEndOfMonth: boolean;
  isNextMonthPartOfWeekPeriodClosed: null | boolean;
  isCurrentMonthPartOfWeekPeriodClosed: null | boolean;
  startOfNextMonthCloseDate: null | Date;
  endOfCurrentMonthCloseDate: null | Date;
  isCurrentEditClosed: boolean;
  periodsForWeek: null | LockedTimePeriodIndex;
  periodsForCurrentMonth: null | LockedTimePeriodIndex;
  periodsForNextMonth: null | LockedTimePeriodIndex;
};

type TimeRegistrationProps = {
  actions: {
    pageOpened: () => void;
    weekSelected: (date: Date) => void;
    resourceSelected: (resource: ResourceObject | undefined) => void;
    beginEditTimeRegistration: (
      timeReg: ResourceObject | NewResourceObject
    ) => void;
    delayedClearSelectedTimeRegistration: () => void;
    deleteRegistration: (timeReg: ResourceObject) => void;
    addSavedProject: (projects: Array<ResourceObject>) => void;
    removeSavedProject: (projects: Array<ResourceObject>) => void;
    lockPeriod: (startDate: Date, endDate: Date, resourceId: string) => void;
    deleteLockedTimePeriod: (ltp: ResourceObject) => void;
    openDialog: (title: string, message: string, callback: () => void) => void;
    closeDialog: () => void;
    makeChangeToCurrentEdit: (change: ResourceObject) => void;
    makeChangeToModel: (change: ResourceObject, model: ResourceObject) => void;
    startEdit: (
      model: ResourceObject | NewResourceObject,
      form: (new (...args: any[]) => React.Component) | React.FC
    ) => void;
  };
  selectedResource: ResourceObject;
  stateMerge: StateMerge;
  currentEdit: {
    timeRegistrationUnderEdit: null | ResourceObject;
  };
  currentEditTimeReg: null | ResourceObject;
  projectForTimeRegUnderEdit: ResourceObject | null;
  startTimeForTimeRegUnderEdit: Date | null;
  isCurrentEditBeingDeleted: boolean;
  startTimeFilter: Date | null;
  endTimeFilter: Date | null;
  account: null | ResourceObject;
  clientForTimeRegUnderEdit: null | ResourceObject;
  currentUserAccount: null | ResourceObject;
  contractRoles: ResourceObject[];
  projects: ResourceObject[];
  projectResourcesForSelectedResource: ResourceObject[];
  allResourcesForCurrentAccount: ResourceObject[];
  hasCurrentEdit: boolean;
  dialogHeader: null | string;
  dialogMessage: null | string;
  dialogCallback: () => void | undefined;
  allTimeRegistrationStatus: ResourceObject[];
  allBillabilityTypes: ResourceObject[];
  selectedResources: ResourceObject[];
  projectsForSelectedResource: ResourceObject[];
  contractRolesForSelectedResource: ResourceObject[];
  contractsForSelectedResource: ResourceObject[];
  clientsForSelectedResource: ResourceObject[];
  currentCellRegistrations: ResourceObject[];
  usersForCurrentAccount: ResourceObject[];
  projectsForResourceAndClient: ResourceObject[];
  activeProjectResourcesForChosenProject: ResourceObject[] | null;
  allActiveResourcesForCurrentEditProject: ResourceObject[];
  savedProjectIds: string[];
  currentUserAccessLevelsForTimeReg: string[];
  hourSet: HourSet;
  idForNotBillableType: string;
  isCurrentEditEditable: boolean;
  isCurrentEditDeletable: boolean;
  notificationsForCurrentEdit: Notification[];
  isSelectedTimeRegistrationBillable: boolean;
  projectStatusForFinishedProject: undefined | ResourceObject;
  projectStatusForOngoingProject: undefined | ResourceObject;
  lockedPeriods: TimeRegistrationLockedPeriodProps;
  currentUserPermissionSet: UserPermissionSet;
  timeRegistrationStatusOpen?: ResourceObject;
  resourceForCurrentUser: ResourceObject | undefined;
  workLocationRegistrationForCurrentEdit: ResourceObject | undefined;
  workLocationHome: ResourceObject;
  weekWorkLocationRegistrations: ResourceObjects;
  showCloseLastWeekNotification: boolean;
};

type TimeRegistrationState = Record<string, never>;

class TimeRegistration extends Component<
  TimeRegistrationProps,
  TimeRegistrationState
> {
  constructor(props: TimeRegistrationProps) {
    super(props);

    this._onSelectWeek = this._onSelectWeek.bind(this);
    this._onSelectResource = this._onSelectResource.bind(this);
    this._onBeginCellEdit = this._onBeginCellEdit.bind(this);

    this._getEmptyTimeRegistration = this._getEmptyTimeRegistration.bind(this);
    this._beginNewTimeRegistrationFromCellData =
      this._beginNewTimeRegistrationFromCellData.bind(this);
    this._beginNewTimeRegistrationFromCurrentEdit =
      this._beginNewTimeRegistrationFromCurrentEdit.bind(this);

    this._timeRegistrationUpdate = this._timeRegistrationUpdate.bind(this);
    this._deleteTimeRegistrationUnderEdit =
      this._deleteTimeRegistrationUnderEdit.bind(this);

    this._selectTimeRegistrationFromList =
      this._selectTimeRegistrationFromList.bind(this);
    this._onItemAddToView = this._onItemAddToView.bind(this);
    this._onProjectRemoveFromView = this._onProjectRemoveFromView.bind(this);
    this._getEmptyTimeRegistrationFromCellData =
      this._getEmptyTimeRegistrationFromCellData.bind(this);

    this._getFieldStatus = this._getFieldStatus.bind(this);
    this._onOutsideClick = this._onOutsideClick.bind(this);
    this._clickCloseWeek = this._clickCloseWeek.bind(this);
    this._closeWeek = this._closeWeek.bind(this);
    this._closeEoW = this._closeEoW.bind(this);
    this._openEoW = this._openEoW.bind(this);
    this._openWeek = this._openWeek.bind(this);
    this._showConfirmClosePeriodDialog =
      this._showConfirmClosePeriodDialog.bind(this);
    this._closeDialog = this._closeDialog.bind(this);
    this._onAddNewClick = this._onAddNewClick.bind(this);
    this._onChangeCell = this._onChangeCell.bind(this);
    this._onChangeListHourField = this._onChangeListHourField.bind(this);
    this._onFocusListHourField = this._onFocusListHourField.bind(this);

    this._startEditInCell = this._startEditInCell.bind(this);

    this._currentWeekCrossesCalendarMonths =
      this._currentWeekCrossesCalendarMonths.bind(this);
    this._compareTimeRegistrations = this._compareTimeRegistrations.bind(this);
    this._onWorkLocationRegistrationChange =
      this._onWorkLocationRegistrationChange.bind(this);
    this._onCurrentEditCellValueChanged =
      this._onCurrentEditCellValueChanged.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  _onSelectWeek(firstDayOfWeek: Date) {
    this.props.actions.weekSelected(firstDayOfWeek);
  }

  _onSelectResource(selectedResources: Array<ResourceObject>) {
    const { selectedResource } = this.props;
    const newSelectedResource = selectedResource
      ? selectedResources.find(
          (resource) => resource.id !== selectedResource.id
        )
      : selectedResources[0];
    this.props.actions.resourceSelected(newSelectedResource);
  }

  _onBeginCellEdit(cellData: CellData) {
    const { stateMerge, currentEdit, selectedResource } = this.props;
    const timeRegDate = cellData.date.dateObj;
    const timeRegProject = cellData.project;
    const timeRegistrationsForCell =
      timeRegistrationSelectors.sortTimeRegsByStartTime(
        stateMerge.registrationsByResourceAndProjectAndDay(
          selectedResource,
          timeRegProject,
          timeRegDate
        )
      );

    if (timeRegistrationsForCell.length === 0) {
      return this._beginNewTimeRegistrationFromCellData(cellData);
    } else if (
      !currentEdit.timeRegistrationUnderEdit ||
      !timeRegistrationsForCell.includes(currentEdit.timeRegistrationUnderEdit)
    ) {
      this.switchToTimeRegistration(timeRegistrationsForCell[0]);
    }
  }

  _beginNewTimeRegistrationFromCellData(cellData: CellData) {
    const reg = this._getEmptyTimeRegistrationFromCellData(cellData);
    if (reg) {
      this.switchToTimeRegistration(reg);
    }
  }

  _beginNewTimeRegistrationFromCurrentEdit() {
    const { projectForTimeRegUnderEdit, startTimeForTimeRegUnderEdit } =
      this.props;
    if (
      projectForTimeRegUnderEdit !== null &&
      startTimeForTimeRegUnderEdit !== null
    ) {
      const cellData = this._createCellData(
        projectForTimeRegUnderEdit,
        startTimeForTimeRegUnderEdit
      );
      const reg = this._getEmptyTimeRegistrationFromCellData(cellData);
      if (reg) {
        this.switchToTimeRegistration(reg);
      }
    }
  }

  _selectTimeRegistrationFromList(items: ResourceObjects) {
    if (items.length === 1) {
      this.switchToTimeRegistration(items[0]);
    }
  }

  _onFocusListHourField(
    _event: any,
    item: { timeRegistration: ResourceObject }
  ) {
    this.switchToTimeRegistration(item.timeRegistration);
  }

  _onAddNewClick(project: ResourceObject, date: Date) {
    this._beginNewTimeRegistrationFromCellData(
      this._createCellData(project, date)
    );
  }

  private switchToTimeRegistration(treg: ResourceObject | NewResourceObject) {
    if (!apihelper.isPersistedEntity(treg) && !treg.meta?.feId) {
      treg.meta = treg.meta || {};
      treg.meta.feId = uuidv1();
    }
    this.props.actions.beginEditTimeRegistration(treg);
  }

  _compareTimeRegistrations(
    reg1: ResourceObject,
    reg2: ResourceObject
  ): boolean {
    return (
      (!!reg1.meta?.feId && reg1.meta?.feId === reg2.meta?.feId) ||
      !!apihelper.entityHasId(reg1, apihelper.getEntityId(reg2))
    );
  }

  _timeRegistrationUpdate(change: ResourceObject, treg: ResourceObject) {
    this.props.actions.makeChangeToModel(change, treg);
  }

  _onOutsideClick(event: any) {
    function hasSomeParentTheClass(
      element: HTMLElement,
      classname: string
    ): boolean {
      if (
        (typeof element.className == "string" ? element.className : "")
          .split(" ")
          .indexOf(classname) >= 0
      )
        return true;
      return (
        !!element.parentElement &&
        hasSomeParentTheClass(element.parentElement, classname)
      );
    }

    const isMsCallout = hasSomeParentTheClass(event.target, "ms-Layer");
    if (!isMsCallout && this.props.currentEdit.timeRegistrationUnderEdit) {
      this.props.actions.delayedClearSelectedTimeRegistration();
    }
  }

  _deleteTimeRegistrationUnderEdit() {
    const { isCurrentEditBeingDeleted, currentEditTimeReg } = this.props;
    if (!isCurrentEditBeingDeleted && currentEditTimeReg !== null) {
      this.props.actions.deleteRegistration(currentEditTimeReg);
    }
  }

  _onItemAddToView(items: Array<ResourceObject>) {
    this.props.actions.addSavedProject(items);
  }

  _onProjectRemoveFromView(project: ResourceObject) {
    this.props.actions.removeSavedProject([project]);
  }

  _clickCloseWeek() {
    const { stateMerge } = this.props;
    const coversTwoWeeks = this._currentWeekCrossesCalendarMonths();

    const totalDuration = stateMerge.getTotalDuration();
    const lowerGuard = 7.2;
    const upperGuard = 9.6;

    if (
      (totalDuration > lowerGuard * 5 && totalDuration < upperGuard * 5) ||
      coversTwoWeeks
    ) {
      this._closeWeek();
    } else {
      this._showConfirmClosePeriodDialog(
        totalDuration,
        totalDuration > upperGuard * 5,
        "week",
        this._closeWeek
      );
    }
  }

  _closeWeek() {
    const { lockedPeriods, selectedResource, actions } = this.props;
    const endDate = lockedPeriods.isEndOfMonth
      ? lockedPeriods.endOfCurrentMonthCloseDate
      : lockedPeriods.endOfWeekCloseDate;
    if (lockedPeriods.startOfWeekCloseDate !== null && endDate !== null) {
      actions.lockPeriod(
        lockedPeriods.startOfWeekCloseDate,
        endDate,
        selectedResource.id
      );
    }
  }

  _openWeek() {
    const { lockedPeriods, actions } = this.props;
    const ltpIndex = (
      lockedPeriods.isEndOfMonth
        ? lockedPeriods.periodsForCurrentMonth
        : lockedPeriods.periodsForWeek
    ) as LockedTimePeriodIndex;
    ltpIndex
      .getLockedTimePeriods()
      .forEach((ltp) => actions.deleteLockedTimePeriod(ltp));
  }

  _closeEoW() {
    const { lockedPeriods, selectedResource, actions } = this.props;
    if (
      lockedPeriods.startOfNextMonthCloseDate !== null &&
      lockedPeriods.endOfWeekCloseDate !== null
    ) {
      actions.lockPeriod(
        lockedPeriods.startOfNextMonthCloseDate,
        lockedPeriods.endOfWeekCloseDate,
        selectedResource.id
      );
    }
  }

  _openEoW() {
    const { lockedPeriods, actions } = this.props;
    lockedPeriods.periodsForNextMonth
      ?.getLockedTimePeriods()
      .forEach((ltp) => actions.deleteLockedTimePeriod(ltp));
  }

  _showConfirmClosePeriodDialog(
    hours: number,
    isHigh: boolean,
    periodName: string,
    callback: () => void
  ) {
    const title = `Are you sure you want to close the ${periodName}?`;
    const message = `There are ${hours} hours in the period you are about to close, which is ${
      isHigh ? "higher" : "lower"
    } than expected.`;
    this.props.actions.openDialog(title, message, callback);
  }

  _closeDialog() {
    this.props.actions.closeDialog();
  }

  _isTimePeriodeClosed(date: Date) {
    const { lockedPeriods, selectedResource } = this.props;
    return lockedPeriods.periodsForWeek?.isDateLockedForResource(
      date,
      apihelper.getEntityId(selectedResource)
    );
  }

  _currentWeekCrossesCalendarMonths() {
    const { startTimeFilter, endTimeFilter } = this.props;

    if (startTimeFilter && endTimeFilter) {
      return !datehelper.isInSameCalendarMonth(startTimeFilter, endTimeFilter);
    }
    return false;
  }

  _getFieldStatus(model: ResourceObject, fieldPath: string): QueueChangeStatus {
    return this.props.stateMerge.getFieldStatus(
      model,
      fieldPath
    ) as QueueChangeStatus;
  }

  _getEmptyTimeRegistration(
    startTimeParam: string | Date,
    contractRoleId: string,
    projectId: string,
    resource: ResourceObject,
    timeRegistrationStatus: ResourceObject
  ) {
    const { account } = this.props;
    const startTime = new Date(startTimeParam);
    if (startTime.getHours() === 0) {
      startTime.setHours(9);
    }

    const endTime = new Date(startTime);
    const attrs = {
      startTime,
      endTime,
      description: "",
    };

    return createEmptyModel(EntityTypes.TIMEREGISTRATION, attrs, {
      account: account as ResourceObject,
      resource,
      project: {
        id: projectId,
        type: EntityTypes.PROJECT,
      } as ResourceIdentifier,
      contractRole: {
        id: contractRoleId,
        type: EntityTypes.CONTRACTROLE,
      } as ResourceIdentifier,
      timeRegistrationStatus,
    });
  }

  _getEmptyTimeRegistrationFromCellData(
    cellData: CellData
  ): NewResourceObject | null {
    const { timeRegistrationStatusOpen } = this.props;
    const { resource, project } = cellData;
    const contractRoleId =
      cellData.projectResources.length > 0
        ? apihelper.getRelId(cellData.projectResources[0], "contractRole")
        : null;
    if (contractRoleId !== null && timeRegistrationStatusOpen) {
      const timeRegistrations = this.props.stateMerge.registrationsByDay(
        cellData.date.dateObj
      );
      let date = timeRegistrations
        .map((tr) => new Date(apihelper.getAttr(tr, "endTime") as string))
        .reduce(
          (maxDate, date) => (maxDate > date ? maxDate : date),
          cellData.date.dateObj
        );
      date =
        date.getDate() !== cellData.date.dateObj.getDate()
          ? cellData.date.dateObj
          : date; // in case so much time is added it crosses day
      return this._getEmptyTimeRegistration(
        date,
        contractRoleId,
        apihelper.getEntityId(project) as string,
        resource,
        timeRegistrationStatusOpen
      );
    }
    return null;
  }

  _createCellData(project: ResourceObject, date: Date): CellData {
    const {
      selectedResource,
      stateMerge,
      projectResourcesForSelectedResource,
    } = this.props;

    const projectResources = selectedResource
      ? projectResourcesForSelectedResource.filter(
          isProjectResourceActiveAtDate(date)
        )
      : [];
    const tregs = stateMerge.registrationsByResourceAndProjectAndDay(
      selectedResource,
      project,
      date
    );
    return {
      timeRegistrations: tregs,
      resource: selectedResource,
      project,
      date: { dateObj: date },
      projectResources: projectResources.filter((pr) =>
        apihelper.relRefersToEntity(pr, "project", project)
      ),
    };
  }

  _startEditInCell(project: ResourceObject, date: Date) {
    this._onBeginCellEdit(this._createCellData(project, date));
  }

  // direct edits on the hours in the cell is propagated here from the Registration table
  _onChangeCell(change: ResourceObject | NewResourceObject) {
    if (this.props.hasCurrentEdit) {
      this.props.actions.makeChangeToCurrentEdit(change as ResourceObject);
    }
  }

  _onCurrentEditCellValueChanged(fractionalHours: number, date: Date) {
    const { currentEdit, hasCurrentEdit } = this.props;
    if (hasCurrentEdit && currentEdit.timeRegistrationUnderEdit) {
      const startTimeStr = apihelper.getAttr(
        currentEdit.timeRegistrationUnderEdit,
        "startTime"
      ) as string;
      const curStartTime =
        (startTimeStr && new Date(startTimeStr)) || defaultStartTime(date);
      const change = createEmptyModelAttributes(
        EntityTypes.TIMEREGISTRATION,
        getIntervalWithinDay(curStartTime, fractionalHours)
      );
      this.props.actions.makeChangeToCurrentEdit(change as ResourceObject);
    }
  }

  // todo: this should be unified in one function with _onCurrentEditCellValueChanged
  _onChangeListHourField(
    newVal: number,
    item: { timeRegistration: ResourceObject }
  ) {
    const startTimeStr = apihelper.getAttr(
      item.timeRegistration,
      "startTime"
    ) as string;
    const curStartTime = new Date(startTimeStr);
    this._onChangeCell(
      createEmptyModelAttributes(
        EntityTypes.TIMEREGISTRATION,
        getIntervalWithinDay(curStartTime, newVal)
      )
    );
  }

  _onWorkLocationRegistrationChange(date: Date, checked?: boolean): void {
    const { workLocationRegistrationForCurrentEdit } = this.props;
    if (checked) {
      const workLocationRegistration =
        workLocationRegistrationForCurrentEdit ||
        createEmptyModel(
          EntityTypes.WORKLOCATIONREGISTRATION,
          {
            startTime: datehelper.sameDayAt(date, 9),
            endTime: datehelper.sameDayAt(date, 9),
          },
          {
            account: this.props.account,
            resource: this.props.selectedResource,
            workLocation: this.props.workLocationHome,
          }
        );

      this.props.actions.startEdit(
        workLocationRegistration,
        WorkLocationRegistrationForm as React.FC
      );
    } else if (workLocationRegistrationForCurrentEdit) {
      this.props.actions.startEdit(
        workLocationRegistrationForCurrentEdit,
        DeleteForm
      );
    }
  }

  render() {
    const {
      allResourcesForCurrentAccount,
      currentEdit,
      stateMerge,
      clientForTimeRegUnderEdit,
      currentUserAccount,
      currentUserAccessLevelsForTimeReg,
      startTimeFilter,
      dialogHeader,
      dialogMessage,
      dialogCallback,
      hasCurrentEdit,
      projectForTimeRegUnderEdit,
      startTimeForTimeRegUnderEdit,
      selectedResource,
      projectResourcesForSelectedResource,
      allTimeRegistrationStatus,
      allBillabilityTypes,
      selectedResources,
      contractRolesForSelectedResource,
      contractsForSelectedResource,
      usersForCurrentAccount,
      hourSet,
      allActiveResourcesForCurrentEditProject,
      savedProjectIds,
      lockedPeriods,
      isCurrentEditEditable,
      isCurrentEditDeletable,
      notificationsForCurrentEdit,
      activeProjectResourcesForChosenProject,
      projectsForResourceAndClient,
      idForNotBillableType,
      projectStatusForFinishedProject,
      projectStatusForOngoingProject,
      currentCellRegistrations,
      isCurrentEditBeingDeleted,
      currentUserPermissionSet,
      resourceForCurrentUser,
      weekWorkLocationRegistrations,
      showCloseLastWeekNotification,
    } = this.props;

    let timeRegFormTitle = "",
      showAddNewTimeRegButton = false,
      additionalFormData;

    if (hasCurrentEdit) {
      if (projectForTimeRegUnderEdit) {
        showAddNewTimeRegButton =
          stateMerge.registrationsByProjectAndDay(
            projectForTimeRegUnderEdit,
            startTimeForTimeRegUnderEdit as Date
          ).length > 0;
        if (startTimeForTimeRegUnderEdit) {
          timeRegFormTitle =
            (clientForTimeRegUnderEdit
              ? apihelper.getAttr(clientForTimeRegUnderEdit, "name") + " - "
              : "") +
            apihelper.getAttr(projectForTimeRegUnderEdit, "name") +
            ". " +
            moment(startTimeForTimeRegUnderEdit).format("dddd, MMMM Do");
        }
      }

      additionalFormData = {
        contractRoles: contractRolesForSelectedResource,
        projects: projectsForResourceAndClient,
        projectForTimeReg: projectForTimeRegUnderEdit,
        resourcesForSelectedProject: allActiveResourcesForCurrentEditProject,
        projectResources: projectResourcesForSelectedResource,
        projectResourcesForSelectedProject:
          activeProjectResourcesForChosenProject,
        account: currentUserAccount,
        users: usersForCurrentAccount,
        currentUserAccessLevels: currentUserAccessLevelsForTimeReg,
        idForNotBillableType: idForNotBillableType,
        projectStatusForFinishedProject: projectStatusForFinishedProject,
        projectStatusForOngoingProject: projectStatusForOngoingProject,
      };
    }

    const registrationsForList = currentCellRegistrations;

    const hasWriteAccessToSelectedAccount =
      currentUserPermissionSet.hasSelectedAccountWrite();

    return (
      <div className="novatime-container">
        <div className="novatime-page-timeregistration">
          {showCloseLastWeekNotification ? (
            <>
              <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                dismissButtonAriaLabel="Close"
              >
                Remember to close your hours from last week.
              </MessageBar>
            </>
          ) : undefined}

          <Dialog
            hidden={!dialogMessage}
            onDismiss={this._closeDialog}
            dialogContentProps={{
              title: dialogHeader || "",
              subText: dialogMessage || "",
            }}
            modalProps={{
              isBlocking: true,
            }}
          >
            {dialogCallback ? (
              <DialogFooter>
                <PrimaryButton onClick={dialogCallback} text="OK" />
                <DefaultButton onClick={this._closeDialog} text="Cancel" />
              </DialogFooter>
            ) : undefined}
          </Dialog>

          <HorizontalRule text="Select" alignment="left" />

          <Grid>
            <Row>
              <Column sm={12} md={12} lg={6}>
                <WeekPicker
                  value={startTimeFilter}
                  onSelectWeek={this._onSelectWeek}
                />
              </Column>
              <Column sm={12} md={12} lg={6}>
                <ResourcePicker
                  resources={allResourcesForCurrentAccount}
                  users={usersForCurrentAccount}
                  selectedResources={selectedResources}
                  onResourceSelected={this._onSelectResource}
                  itemLimit={2}
                />
              </Column>
            </Row>
          </Grid>

          <HorizontalRule
            className="novatime-page-timeregistration-calendar-hr"
            text="Calendar"
            alignment="left"
          />

          <OutsideAlerter onOutsideClick={this._onOutsideClick}>
            <div className="novatime-close-buttons">
              {lockedPeriods.isEndOfMonth ? (
                <>
                  <DefaultButton
                    onClick={
                      lockedPeriods.isCurrentMonthPartOfWeekPeriodClosed
                        ? this._openWeek
                        : this._clickCloseWeek
                    }
                    text={
                      lockedPeriods.isCurrentMonthPartOfWeekPeriodClosed
                        ? "Open end of month"
                        : "Close end of month"
                    }
                    disabled={
                      !lockedPeriods.canWeekClose ||
                      (!!lockedPeriods.isCurrentMonthPartOfWeekPeriodClosed &&
                        !hasWriteAccessToSelectedAccount)
                    }
                    title={
                      !lockedPeriods.canWeekClose
                        ? "There are unfinished registrations preventing closing this week"
                        : lockedPeriods.isWeekClosed &&
                          !hasWriteAccessToSelectedAccount
                        ? "This week has already been closed"
                        : undefined
                    }
                  />
                  <DefaultButton
                    onClick={
                      lockedPeriods.isNextMonthPartOfWeekPeriodClosed
                        ? this._openEoW
                        : this._closeEoW
                    }
                    disabled={
                      !!lockedPeriods.isNextMonthPartOfWeekPeriodClosed &&
                      !hasWriteAccessToSelectedAccount
                    }
                    text={
                      lockedPeriods.isNextMonthPartOfWeekPeriodClosed
                        ? "Open remaining week"
                        : "Close remaining week"
                    }
                  />
                </>
              ) : (
                <DefaultButton
                  onClick={
                    lockedPeriods.isWeekClosed
                      ? this._openWeek
                      : this._clickCloseWeek
                  }
                  text={lockedPeriods.isWeekClosed ? "Open week" : "Close week"}
                  disabled={
                    !lockedPeriods.canWeekClose ||
                    (lockedPeriods.isWeekClosed &&
                      !hasWriteAccessToSelectedAccount)
                  }
                  title={
                    !lockedPeriods.canWeekClose
                      ? "There are unfinished registrations preventing closing this week"
                      : lockedPeriods.isWeekClosed &&
                        !hasWriteAccessToSelectedAccount
                      ? "This week has already been closed"
                      : undefined
                  }
                />
              )}
            </div>
            <RegistrationTable
              currentEdit={currentEdit.timeRegistrationUnderEdit}
              selectedResource={selectedResource}
              dayInWeek={startTimeFilter || new Date()}
              stateMerge={stateMerge}
              hourSet={hourSet}
              workLocationRegistrations={weekWorkLocationRegistrations}
              onCurrentEditCellValueChanged={
                this._onCurrentEditCellValueChanged
              }
              startEditInCell={this._startEditInCell}
              favoriteProjectIds={savedProjectIds}
              addNewInCell={this._onAddNewClick}
              onAddToFavorites={this._onItemAddToView}
              onRemoveFromFavorites={this._onProjectRemoveFromView}
              closedDatesInWeek={lockedPeriods.closedDates}
              onWorkFromHomeToggleChange={
                this._onWorkLocationRegistrationChange
              }
            />
            <div className="height4em hiddenLgUp" />
            {timeRegFormTitle && (
              <HorizontalRule text={timeRegFormTitle} alignment="left" />
            )}
            {showAddNewTimeRegButton ? (
              <DefaultButton
                iconProps={{ iconName: Icons.ICON_NAME_NEW }}
                text="Add new registration"
                className="new-time-registration-icon-standalone"
                onClick={this._beginNewTimeRegistrationFromCurrentEdit}
              />
            ) : undefined}

            {registrationsForList.length > 1 ? (
              <TimeRegistrationList
                timeRegistrations={registrationsForList}
                columnRenderOverrides={{
                  duration: (item) => (
                    <HourField
                      onChange={this._onChangeListHourField}
                      value={item.duration}
                      editable={true}
                      disabled={this._isTimePeriodeClosed(item.date)}
                      additionalData={item}
                      onFocus={this._onFocusListHourField}
                    />
                  ),
                }}
                selectedTimeRegistration={currentEdit.timeRegistrationUnderEdit}
                onSelect={this._selectTimeRegistrationFromList}
                hourSet={hourSet}
              />
            ) : null}
            <AnimatePresence>
              {currentEdit.timeRegistrationUnderEdit ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 0.3 } }}
                  exit={{ opacity: 0 }}
                  key="fade"
                >
                  <TimeRegistrationForm
                    model={currentEdit.timeRegistrationUnderEdit}
                    contractRoles={contractRolesForSelectedResource}
                    contracts={contractsForSelectedResource}
                    projects={projectsForResourceAndClient}
                    projectResources={projectResourcesForSelectedResource}
                    isUnderDelete={isCurrentEditBeingDeleted}
                    fieldStatus={this._getFieldStatus}
                    onChange={this._timeRegistrationUpdate}
                    onDelete={this._deleteTimeRegistrationUnderEdit}
                    autoShowDetails={false}
                    canDelete={isCurrentEditDeletable}
                    canEdit={isCurrentEditEditable}
                    notifications={notificationsForCurrentEdit}
                    allTimeRegistrationStatus={allTimeRegistrationStatus}
                    allBillabilityTypes={allBillabilityTypes}
                    resourcesForSelectedProject={
                      allActiveResourcesForCurrentEditProject
                    }
                    compareModels={this._compareTimeRegistrations}
                    users={usersForCurrentAccount}
                    currentUserPermissionSet={currentUserPermissionSet}
                    idForNotBillableType={idForNotBillableType}
                    resourceForCurrentUser={resourceForCurrentUser}
                  />
                </motion.div>
              ) : null}
            </AnimatePresence>
          </OutsideAlerter>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  let account: null | ResourceObject | undefined = null,
    allActiveResourcesForCurrentEditProject: ResourceObject[] = [],
    projectForTimeRegUnderEdit: null | ResourceObject | undefined = null,
    clientsForSelectedResource: ResourceObjects = [],
    clientForTimeRegUnderEdit: null | ResourceObject | undefined = null,
    contractRolesForSelectedResource: ResourceObjects = [],
    contractsForSelectedResource: ResourceObjects = [],
    currentUserAccount = null,
    projectsForSelectedResource: ResourceObjects = [],
    projectResourcesForSelectedResource: null | ResourceObject[] = [],
    startTimeForTimeRegUnderEdit: Date | null = null,
    currentEditTimeReg: null | ResourceObject = null,
    currentUserAccessLevelsForTimeReg: string[] = [],
    projectsForResourceAndClient: ResourceObjects = [],
    activeProjectResourcesForChosenProject: ResourceObjects = [],
    usersForCurrentAccount: ResourceObjects = [];

  const selectedResource = timeRegistrationSelectors.selectedResource(state);
  const selectedResources = timeRegistrationSelectors.selectedResources(state);

  if (selectedResource) {
    account = timeRegistrationSelectors.accountForSelectedResource(state);
    projectResourcesForSelectedResource =
      timeRegistrationSelectors.projectResourcesForSelectedResource(state);
    projectsForSelectedResource =
      timeRegistrationSelectors.projectsForSelectedResource(state) || [];
    contractRolesForSelectedResource =
      timeRegistrationSelectors.contractRolesForSelectedResource(state) || [];
    contractsForSelectedResource =
      timeRegistrationSelectors.contractsForSelectedResource(state) || [];
    clientsForSelectedResource =
      timeRegistrationSelectors.clientsForSelectedResurce(state) || [];
  }

  const isLoggedIn = authReducerSelectors.isLoggedIn(state);
  if (isLoggedIn) {
    currentUserAccount = accountReducerSelectors.currentUserAccount(state);
    usersForCurrentAccount =
      userReducerSelectors.allUsersInCurrentUserAccount(state) || [];
  }

  const startTimeFilter = timeRegistrationSelectors.startTimeFilter(state);
  const endTimeFilter = timeRegistrationSelectors.endTimeFilter(state);
  const queue = writeBackQueueReducerSelectors.queue(state);
  const stateMerge =
    timeRegistrationSelectors.stateMergeForCurrentFilter(state);
  const currentEditVal = timeRegistrationSelectors.currentEdit(state);
  const hasCurrentEdit = timeRegistrationSelectors.hasCurrentEdit(state);

  if (hasCurrentEdit) {
    currentEditTimeReg = currentEditVal.timeRegistrationUnderEdit;
    projectForTimeRegUnderEdit =
      timeRegistrationSelectors.projectForCurrentEdit(state);
    startTimeForTimeRegUnderEdit =
      timeRegistrationSelectors.startTimeForTimeRegUnderEdit(state);
    allActiveResourcesForCurrentEditProject =
      timeRegistrationSelectors.assignableResourcesForCurrentEdit(state) || [];
    clientForTimeRegUnderEdit =
      timeRegistrationSelectors.clientForCurrentEdit(state);
    currentUserAccessLevelsForTimeReg =
      timeRegistrationSelectors.currentUserAccessLevelsForTimeReg(state);
    projectsForResourceAndClient = (
      timeRegistrationSelectors.registerableProjectsForCurrentEditClient(
        state
      ) || []
    ).sort(
      storehelper.sortByLocaleCompare((entity) =>
        (apihelper.getAttr(entity, "name") as string).toLowerCase()
      )
    );
    activeProjectResourcesForChosenProject =
      timeRegistrationSelectors.activeProjectResourcesForCurrentEdit(state) ||
      [];
  }

  const currentWeekRegistrations =
    timeRegistrationSelectors.currentWeekRegistrations(state);

  const hourSet = timeRegistrationSelectors.hourSetForSelectedResource(state);

  const datesInWeek =
    timeRegistrationSelectors.weekDatesForCurrentFilter(state);

  /* It varies how a week can be locked, depending on whether the week crosses a calendar month or not
    
    Calendar week
    <==================================================================================================>

    Case 1, entire week is within the same month. isEndOfMonth == false
    <--------------------------------- isWeekClosed / canWeekClose ------------------------------------>
    ^                                                                                                  ^
    startOfWeekCloseDate                                                              endOfWeekCloseDate

    Case 2, week crosses a calendar month. isEndOfMonth == true
    <---------------- isEndOfMonthClosed ---------------> <-------- isWeekClosed (all week) ----------->
    ^                                                   ^ ^                                            ^
    startOfWeekCloseDate       endOfCurrentMonthCloseDate startOfNextMonthCloseDate   endOfWeekCloseDate   

    */

  const lockedPeriods: TimeRegistrationLockedPeriodProps = {
    closedDates: [],
    isWeekClosed: false,
    canWeekClose: false,
    startOfWeekCloseDate: null,
    endOfWeekCloseDate: null,
    // if this week is the end of a month, the week is divided into two, with separate close actions
    isEndOfMonth: false,
    isNextMonthPartOfWeekPeriodClosed: null,
    isCurrentMonthPartOfWeekPeriodClosed: null,
    startOfNextMonthCloseDate: null,
    endOfCurrentMonthCloseDate: null,
    isCurrentEditClosed: false,
    periodsForWeek: null,
    periodsForCurrentMonth: null,
    periodsForNextMonth: null,
  };

  if (selectedResource && startTimeFilter && endTimeFilter) {
    lockedPeriods.startOfWeekCloseDate = startTimeFilter;
    lockedPeriods.endOfWeekCloseDate = endTimeFilter;
    lockedPeriods.isEndOfMonth =
      timeRegistrationSelectors.weekForCurrentFilterCrossesMonth(state);
    lockedPeriods.closedDates =
      timeRegistrationSelectors.closedDatesInWeekForCurrentFilter(state);
    lockedPeriods.isWeekClosed =
      timeRegistrationSelectors.isEntireCalendarWeekLocked(state);
    lockedPeriods.periodsForWeek =
      timeRegistrationSelectors.lockedTimePeriodIndexForEntireWeek(state);

    lockedPeriods.canWeekClose = lockedPeriods.isWeekClosed
      ? true
      : !timeRegistrationSelectors.isDirty(state); // isDirty look for time registrations for which endTime > startTime.
    // There is a bug in which a dirty timeReg will give an error, which sticks to the sheet even if I change user, even on timetest.novataris.com

    if (lockedPeriods.isEndOfMonth) {
      lockedPeriods.endOfCurrentMonthCloseDate =
        timeRegistrationSelectors.endOfCurrentMonthCloseDate(state);
      lockedPeriods.startOfNextMonthCloseDate =
        timeRegistrationSelectors.startOfNextMonthPeriodStartDate(state);
      lockedPeriods.isNextMonthPartOfWeekPeriodClosed =
        timeRegistrationSelectors.isNextMonthPartOfWeekPeriodClosed(state);
      lockedPeriods.isCurrentMonthPartOfWeekPeriodClosed =
        timeRegistrationSelectors.isCurrentMonthPartOfWeekPeriodClosed(state);
      lockedPeriods.periodsForCurrentMonth =
        timeRegistrationSelectors.lockedtimePeriodIndexForCurrentMonth(state);
      lockedPeriods.periodsForNextMonth =
        timeRegistrationSelectors.lockedTimePeriodIndexForNextMonth(state);
    }
    lockedPeriods.isCurrentEditClosed =
      timeRegistrationSelectors.isCurrentEditClosed(state);
  }

  const idForNotBillableType =
    timeRegistrationSelectors.idForNotBillableType(state);

  const projectStatusForOngoingProject =
    projectStatusSelectors.projectStatusForOngoingProject(state);
  const projectStatusForFinishedProject =
    projectStatusSelectors.projectStatusForFinishedProject(state);
  const contractRoles = contractRoleReducerSelectors.allContractRoles(state);
  const projects = projectReducerSelectors.allProjects(state);
  const isSelectedTimeRegistrationBillable =
    timeRegistrationSelectors.isCurrentEditBillable(state);
  const isCurrentEditEditable =
    timeRegistrationSelectors.isCurrentEditEditable(state);
  const isCurrentEditDeletable =
    timeRegistrationSelectors.isCurrentEditDeletable(state);
  const notificationsForCurrentEdit =
    timeRegistrationSelectors.notificationsForCurrentEdit(state);

  return {
    notificationsForCurrentEdit,
    account,
    allActiveResourcesForCurrentEditProject,
    allResourcesForCurrentAccount:
      resourceReducerSelectors.allResourcesForCurrentAccount(state),
    allTimeRegistrationStatus:
      timeRegistrationStatusSelectors.allTimeRegistrationStatus(state),
    allBillabilityTypes: billabilitySelectors.allBillabilityTypes(state),
    clientForTimeRegUnderEdit,
    clientsForSelectedResource,
    contractRolesForSelectedResource,
    contractsForSelectedResource,
    contractRoles,
    currentWeekRegistrations,
    currentEditTimeReg,
    currentUserAccount,
    endTimeFilter,
    hourSet,
    currentEdit: currentEditVal,
    currentUser: userReducerSelectors.currentUser(state),
    currentUserAccessLevelsForTimeReg,
    isCurrentEditBeingDeleted:
      timeRegistrationSelectors.isCurrentEditBeingDeleted(state),
    dialogHeader: timeRegistrationSelectors.dialogHeader(state),
    dialogMessage: timeRegistrationSelectors.dialogMessage(state),
    dialogCallback: timeRegistrationSelectors.dialogCallback(state),
    idForNotBillableType,
    isSelectedTimeRegistrationBillable,
    datesInWeek,
    lockedPeriods,
    projects,
    projectsForSelectedResource,
    projectForTimeRegUnderEdit,
    projectResourcesForSelectedResource,
    savedProjectIds: timeRegistrationSelectors.savedProjectIds(state),
    selectedResources,
    selectedResource,
    stateMerge: stateMerge,
    startTimeForTimeRegUnderEdit,
    startTimeFilter,
    queue,
    usersForCurrentAccount,
    hasCurrentEdit,
    isCurrentEditEditable,
    isCurrentEditDeletable,
    activeProjectResourcesForChosenProject,
    projectsForResourceAndClient,
    resourceForCurrentUser:
      resourceReducerSelectors.resourceForCurrentUser(state),
    projectStatusForOngoingProject,
    projectStatusForFinishedProject,
    currentCellRegistrations:
      timeRegistrationSelectors.currentCellRegistrations(state),
    currentUserPermissionSet:
      permissionSelectors.currentUserPermissionSet(state),
    timeRegistrationStatusOpen:
      timeRegistrationStatusSelectors.timeRegistrationStatusOpen(state),
    workLocationRegistrationForCurrentEdit:
      timeRegistrationSelectors.workLocationRegistrationForCurrentEdit(state),
    workLocationHome: workLocationSelectors.workLocationHome(state),
    weekWorkLocationRegistrations:
      timeRegistrationSelectors.weekWorkLocationRegistrations(state),
    showCloseLastWeekNotification:
      timeRegistrationSelectors.showCloseLastWeekNotification(state),
  };
}

function mapDispatch(dispatch: any) {
  const actions = Object.assign({}, timeRegistrationActions, editorActions, {
    deleteLockedTimePeriod: lockedTimePeriodActions.deleteLockedTimePeriod,
  });
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(TimeRegistration as any);
