import * as types from "../../constants/ActionTypes";

const initialState = {
  filter: {
    resourceTypeIds: [],
    resourceIds: [],
    projectIds: [],
    startTime: null,
    endTime: null,
  },
};

export default function timeregistrationexport(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case types.APP_PAGE_TIMEREGISTRATIONEXPORT_UPDATE_FILTER_CHANGE: {
      return Object.assign({}, state, {
        filter: Object.assign({}, action.payload),
      });
    }
    default:
      return state;
  }
}
