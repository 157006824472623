import { ResourceObject } from "../models";
import * as apihelper from "../../selectors/apihelper";
import * as datehelper from "../date";

export type FilterLiteral = {
  startTime?: Date | string | null;
  endTime?: Date | string | null;
  projects?: Array<ResourceObject | string> | null;
  resources?: Array<ResourceObject | string> | null;
  contractRoles?: Array<ResourceObject | string> | null;
  timeRegistrations?: Array<ResourceObject | string> | null;
};

function parseDate(date?: null | string | Date): Date | null {
  if (typeof date === "string") {
    const parsedStartTime = Date.parse(date);
    if (!isNaN(parsedStartTime)) {
      return new Date(date);
    }
    return null;
  }
  return date || null;
}

export default class Filter {
  private startTime: Date | null;
  private endTime: Date | null;
  private projectIds: string[] | null;
  private resourceIds: string[] | null;
  private contractRoleIds: string[] | null;
  private timeRegistrationIds: string[] | null;

  constructor(
    filter: FilterLiteral | Filter = {
      startTime: null,
      endTime: null,
      projects: null,
      resources: null,
      contractRoles: null,
      timeRegistrations: null,
    }
  ) {
    this.filter = this.filter.bind(this);

    this.startTime =
      this.endTime =
      this.projectIds =
      this.resourceIds =
      this.contractRoleIds =
      this.timeRegistrationIds =
        null;

    if (filter instanceof Filter) {
      this.startTime = filter.startTime;
      this.endTime = filter.endTime;
      this.projectIds = filter.projectIds;
      this.resourceIds = filter.resourceIds;
      this.contractRoleIds = filter.contractRoleIds;
      this.timeRegistrationIds = filter.timeRegistrationIds;
    } else if (filter) {
      this.startTime = parseDate(filter.startTime);
      this.endTime = parseDate(filter.endTime);

      if (Array.isArray(filter.projects)) {
        this.projectIds = filter.projects.map((p) =>
          apihelper.isEntity(p)
            ? (apihelper.getEntityId(p as ResourceObject) as string)
            : (p as string)
        );
      }
      if (Array.isArray(filter.resources)) {
        this.resourceIds = filter.resources.map((r) =>
          apihelper.isEntity(r)
            ? (apihelper.getEntityId(r as ResourceObject) as string)
            : (r as string)
        );
      }
      if (Array.isArray(filter.contractRoles)) {
        this.contractRoleIds = filter.contractRoles.map((cr) =>
          apihelper.isEntity(cr)
            ? (apihelper.getEntityId(cr as ResourceObject) as string)
            : (cr as string)
        );
      }
      if (Array.isArray(filter.timeRegistrations)) {
        this.timeRegistrationIds = filter.timeRegistrations.map((tr) =>
          apihelper.isEntity(tr)
            ? (apihelper.getEntityId(tr as ResourceObject) as string)
            : (tr as string)
        );
      }
    }
  }

  hasTimeRegistrations() {
    return this.timeRegistrationIds !== null;
  }

  visitTimeRegistrationIds(func: (tRegId: string) => void) {
    this.hasTimeRegistrations() &&
      (this.timeRegistrationIds as string[]).forEach(func);
  }

  hasProjects() {
    return this.projectIds !== null && this.projectIds.length > 0;
  }

  visitProjectIds(func: (pId: string) => void) {
    this.hasProjects() && (this.projectIds as string[]).forEach(func);
  }

  hasResources() {
    return this.resourceIds !== null && this.resourceIds.length > 0;
  }

  visitResourceIds(func: (rId: string) => void) {
    this.hasResources() && (this.resourceIds as string[]).forEach(func);
  }

  hasContractRoles() {
    return this.contractRoleIds !== null && this.contractRoleIds.length > 0;
  }

  visitContractRoles(func: (crId: string) => void) {
    this.hasContractRoles() && (this.contractRoleIds as string[]).forEach(func);
  }

  hasStartTime() {
    return this.startTime !== null;
  }

  hasEndTime() {
    return this.endTime !== null;
  }

  getStartTime() {
    return this.startTime;
  }

  getEndTime() {
    return this.endTime;
  }

  filter(timeReg: ResourceObject) {
    const startTime = new Date(
        apihelper.getAttr(timeReg, "startTime") as string
      ),
      endTime = new Date(apihelper.getAttr(timeReg, "endTime") as string);
    return (
      (this.startTime == null ||
        datehelper.isSameDayOrAfter(startTime, this.startTime)) &&
      (this.endTime == null ||
        datehelper.isBeforeOrSameDay(endTime, this.endTime)) &&
      (!this.hasProjects() ||
        !apihelper.relHasReference(timeReg, "project") ||
        (this.projectIds as string[]).includes(
          apihelper.getRelId(timeReg, "project") as string
        )) &&
      (!this.hasResources() ||
        !apihelper.relHasReference(timeReg, "resource") ||
        (this.resourceIds as string[]).includes(
          apihelper.getRelId(timeReg, "resource") as string
        )) &&
      (!this.hasContractRoles() ||
        !apihelper.relHasReference(timeReg, "contractRole") ||
        (this.contractRoleIds as string[]).includes(
          apihelper.getRelId(timeReg, "contractRole") as string
        )) &&
      (!this.hasTimeRegistrations() ||
        !apihelper.getEntityId(timeReg) ||
        (this.timeRegistrationIds as string[]).includes(
          apihelper.getEntityId(timeReg) as string
        ))
    );
  }
}
