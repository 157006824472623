import { Stack, Toggle } from "@fluentui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export type ToggleItem<T> = {
  displayName: string;
  value: T;
};

type ToggleStackProps<T> = {
  toggles: Array<ToggleItem<T>>;
  onChange: (enabled: T[]) => void;
};

export default function ToggleStack<T>({
  toggles,
  onChange,
}: ToggleStackProps<T>) {
  const [enabled, setEnabled] = useState<Array<number>>([]);
  useEffect(() => setEnabled([]), [toggles]);
  const onToggle = useCallback(
    (index: number) => {
      const isEnabled = enabled.includes(index);
      const newEnabled = isEnabled
        ? enabled.filter((enabledIndex) => enabledIndex !== index)
        : enabled.concat(index);
      setEnabled(newEnabled);
      onChange(newEnabled.map((idx) => toggles[idx].value));
    },
    [onChange, enabled, setEnabled]
  );

  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: "25px",
      }}
    >
      {toggles.map((tgl, idx) => (
        <Stack.Item key={idx}>
          <Toggle
            label={tgl.displayName}
            checked={enabled.includes(idx)}
            onChange={(_: React.MouseEvent<HTMLElement>, checked?: boolean) =>
              onToggle(idx)
            }
          />
        </Stack.Item>
      ))}
    </Stack>
  );
}
