import * as types from "../../constants/ActionTypes";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as apihelper from "../../selectors/apihelper";

export function pageOpened() {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_OPENED,
  };
}

export function selectCell(cellData: {
  resource?: ResourceObject;
  timeRegistrations: ResourceObjects;
  date?: Date;
}) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_CELL_SELECTED,
    payload: {
      resourceId: apihelper.getEntityId(cellData.resource),
      date: cellData.date?.toISOString(),
      timeRegistrationIds: cellData.timeRegistrations.map(
        apihelper.getEntityId
      ),
    },
  };
}

export function selectTimeRegistration(timeRegistration: ResourceObjects) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_TR_SELECTED,
    payload: timeRegistration,
  };
}

export function clearSelectedCell() {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_CLEAR_SELECTED_CELL,
  };
}

export function selectProjectId(projectId: string) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
    payload: {
      projectId,
    },
  };
}

export function selectMonth(firstDayOfMonth: Date) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
    payload: {
      startTime:
        firstDayOfMonth instanceof Date
          ? firstDayOfMonth.toISOString()
          : firstDayOfMonth,
    },
  };
}

export function selectClientId(clientId: string) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
    payload: {
      clientId,
      projectId: null,
    },
  };
}

export function selectAllClients() {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
    payload: {
      clientId: null,
      projectId: null,
    },
  };
}

export function selectAllProjects() {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
    payload: {
      projectId: null,
    },
  };
}

export function selectResourceTypeIds(
  resourceTypeIds: string[],
  showInactiveResources: boolean
) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_FILTER_INIT,
    payload: {
      resourceTypeIds,
      showInactiveResources,
    },
  };
}

export function updateViewDesc(viewDesc: any) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_UPDATE_VIEW_DESC,
    payload: viewDesc,
  };
}

export function lockPeriods(resourcesToClose: ResourceObjects) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS,
    payload: resourcesToClose,
  };
}

export function openPeriods(resourcesToOpen: ResourceObjects) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_OPEN_PERIODS,
    payload: resourcesToOpen,
  };
}

export function toggleProcessingDialog() {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_TOGGLE_PROCESSING_DIALOG,
  };
}

export function lockPeriodsProcessingProgress(progressInPercent: number) {
  return {
    type: types.APP_PAGE_RESOURCEMANAGEMENT_PERIODS_PROCESSING_PROGRESS,
    payload: {
      progressInPercent,
    },
  };
}
