import React from "react";
import { Label } from "@fluentui/react/lib/Label";
import { TimePicker } from "@fluentui/react";
import * as datehelper from "../lib/date";
import ErrorText from "./uifabricextensions/ErrorText";

export type TimeInputFieldProps = {
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  minuteStep?: number;
  onChange: (time: Date) => void;
  showSeconds?: boolean;
  value: Date;
  required?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
};

export default function TimeInputField({
  value,
  label,
  disabled,
  required,
  errorMessage,
  showSeconds,
  minuteStep,
  onChange,
  onOpen,
  onClose,
}: TimeInputFieldProps) {
  const dateAnchor = datehelper.sameDayAt(value, 7);
  const localOnChange = React.useCallback(
    (_, newTime: Date) => {
      const timeAtSameDay = datehelper.sameDayAt(
        value,
        newTime.getHours(),
        newTime.getMinutes()
      );
      onChange(timeAtSameDay);
    },
    [onChange, value]
  );

  return (
    <div className="time-input-field">
      <Label>{label}</Label>
      <TimePicker
        showSeconds={!!showSeconds}
        allowFreeform={false}
        increments={minuteStep || 15}
        dateAnchor={dateAnchor}
        value={value}
        onChange={localOnChange}
        onMenuOpen={onOpen}
        onMenuDismissed={onClose}
        required={required}
        disabled={disabled}
      />
      {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
    </div>
  );
}
