import React from "react";
import { Stack } from "@fluentui/react";

const bigCenteredNumberStyle = {
  root: {
    textAlign: "center",
    fontWeight: "bold",
  },
};
const smallCenteredFieldDescription = {
  root: {
    textAlign: "center",
    color: "gray",
  },
};
const themedSmallStackTokens = {
  childrenGap: "s1",
  padding: "s1",
};

export type Kpi = {
  title: string;
  value: string | number;
  description: string;
};

type KpiStackProps = {
  kpis: Kpi[];
};

export default function KpiStack({ kpis }: KpiStackProps = { kpis: [] }) {
  return (
    <Stack horizontal horizontalAlign="space-evenly">
      {kpis.map((kpi, idx) => (
        <Stack key={`${kpi.title}-${idx}`} tokens={themedSmallStackTokens}>
          <Stack.Item styles={bigCenteredNumberStyle}>{kpi.value}</Stack.Item>
          <Stack.Item
            styles={smallCenteredFieldDescription}
            title={kpi.description}
          >
            {kpi.title}
          </Stack.Item>
        </Stack>
      ))}
    </Stack>
  );
}
