import * as types from "../../constants/ActionTypes";
import * as EntityType from "../../constants/EntityTypes";
import moment from "moment";
import * as EntityTypes from "../../constants/EntityTypes";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import { ResourceObject } from "../../lib/models";

export function pageOpened() {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_OPENED,
  };
}

export function resourceSelected(resource: ResourceObject) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_RESOURCE_SELECTED,
    payload: resource,
  };
}

export function makeChangeToCurrentEdit(change: ResourceObject) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_MAKE_CHANGE_TO_CURRENT_EDIT,
    payload: {
      change,
    },
  };
}

export function makeChangeToModel(
  change: ResourceObject,
  model: ResourceObject
) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_MAKE_CHANGE_TO_MODEL,
    payload: { change, model },
  };
}

export function weekSelected(firstDayOfWeek: Date | string) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_WEEK_SELECTED,
    payload: {
      firstDayOfWeek:
        firstDayOfWeek instanceof Date
          ? firstDayOfWeek.toISOString()
          : firstDayOfWeek,
    },
  };
}

export function beginEditTimeRegistration(registration: ResourceObject) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_BEGIN_EDIT_TIME_REGISTRATION,
    payload: { registration },
  };
}

export function deleteWritebackComplete(success: boolean, apiPayload: any) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_DELETE_WRITEBACK_COMPLETE,
    payload: { success, apiPayload },
  };
}

export function delayedClearSelectedTimeRegistration() {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_DELAYED_CLEAR_SELECTION,
  };
}

export function clearSelectedTimeRegistration() {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_CLEAR_SELECTED_TIME_REGISTRATION,
  };
}

export function deleteRegistration(registration: ResourceObject) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_DELETE_REGISTRATION,
    payload: {
      registration,
    },
  };
}

export function addSavedProject(projects: Array<ResourceObject>) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_ADD_SAVED_PROJECT,
    payload: {
      projects,
    },
  };
}

export function removeSavedProject(projects: Array<ResourceObject>) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_REMOVE_SAVED_PROJECT,
    payload: {
      projects,
    },
  };
}

export function lockPeriod(startDate: Date, endDate: Date, resourceId: string) {
  const lockedTimePeriod = createEmptyModel(
    EntityTypes.LOCKEDTIMEPERIOD,
    {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    },
    {
      resource: { data: { id: resourceId, type: EntityType.RESOURCE } },
    }
  );
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_LOCK_PERIOD,
    payload: {
      lockedTimePeriod,
    },
  };
}

export function openDialog(
  header: string,
  message: string,
  callback: () => void
) {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_OPEN_DIALOG,
    payload: {
      header,
      message,
      callback,
    },
  };
}

export function closeDialog() {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_CLOSE_DIALOG,
  };
}

export function checkLockedLastWeek() {
  return {
    type: types.APP_PAGE_TIMEREGISTRATION_CHECK_LOCKED_LAST_WEEK,
  };
}
