import { ResourceObject } from "./models";
import * as datehelper from "./date";
import * as apihelper from "../selectors/apihelper";

export const getDuration = (timeRegistration: ResourceObject) => {
  const startTime = new Date(
    apihelper.getAttr(timeRegistration, "startTime") as string
  );
  const endTime = new Date(
    apihelper.getAttr(timeRegistration, "endTime") as string
  );
  return datehelper.diffInHours(endTime, startTime);
};

export const isBetween = (
  timeRegistration: ResourceObject,
  startTime: Date,
  endTime: Date
) => {
  const startTimeStr = apihelper.getAttr(
    timeRegistration,
    "startTime"
  ) as string;
  const endTimeStr = apihelper.getAttr(timeRegistration, "endTime") as string;
  return datehelper.isWithinPeriod(
    startTime,
    endTime,
    new Date(startTimeStr),
    new Date(endTimeStr)
  );
};
