import React, { Component } from "react";
import { GroupedList, IGroup } from "@fluentui/react/lib/GroupedList";
import { DetailsRow } from "@fluentui/react/lib/DetailsList";
import {
  Selection,
  SelectionMode,
  SelectionZone,
} from "@fluentui/react/lib/Selection";
import * as apihelper from "../../selectors/apihelper";
import * as storehelper from "../../selectors/storehelper";
import * as EntityTypes from "../../constants/EntityTypes";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import {
  getEntityKey,
  ParentOptions,
  PickerNode,
  PickerTree,
} from "./PickerTree";

/*

// Description of how one may call the following component, given the following information:

projects,
clients,
contracts


<ClientConstractProjectPickerClass 
  entities={projects.concat(clients)}
  hierarchyData={{
    projects,
    clients,
    contracts
  }}
  getEntityParentFunction={(entity, hierarchyData) => {
    let eType = apihelper.getEntityType(entity);
    if(eType === EntityType.PROJECT){
      let contract = hierarchyData.contracts.find(contract => apihelper.relRefersToEntity(entity, 'contract', contract));
      if(contract){
        let client = hierarchyData.clients.find(client => apihelper.relRefersToEntity(contract, 'client', client));
        if(client){
          return {
            type: ParentOption.PARENT,
            value: client
          }
        }
      }
    }
    return {
      type: ParentOption.ORPHAN
    }
}}

/>

*/

export type NodeParent = {
  type: ParentOptions;
  value?: ResourceObject;
};

export type GetEntityParentFunction = (
  entity: ResourceObject,
  hierarchyData: any
) => NodeParent;

export type ClientProjectPickerProps = {
  selectedProjects: ResourceObjects;
  entities: ResourceObjects;
  inSearch?: boolean;
  onSelection: (projectIds: string[]) => void;
  hierarchyData: any;
  getEntityParentFunction: GetEntityParentFunction;
};

type CollapsedGroupSetting = {
  key: string;
  isCollapsed: boolean;
};

type ClientProjectPickerState = {
  selection: Selection;
  collapsedGroups: CollapsedGroupSetting[];
  useCallback: boolean;
};

type Row = {
  name: string;
  key: string;
  type: string;
  id: string;
};

const projectToRow = (project: ResourceObject): Row => ({
  name: apihelper.getAttr(project, "name") as string,
  key: getEntityKey(project),
  type: apihelper.getEntityType(project),
  id: apihelper.getEntityId(project) as string,
});

const generateTree = (
  entities: ResourceObjects,
  hierarchyData: any,
  getEntityParentFunction: GetEntityParentFunction
) => {
  const tree = new PickerTree();
  entities.forEach((entity) =>
    tree.insert(
      entity,
      getEntityParentFunction(entity, hierarchyData).type,
      getEntityParentFunction(entity, hierarchyData).value as ResourceObject
    )
  );
  return tree;
};

const SELECTED_OR_DESELECT_ALL_PROJECTS = "SelectOrDeselectAllProjects";
export class ClientProjectPicker extends Component<
  ClientProjectPickerProps,
  ClientProjectPickerState
> {
  constructor(props: ClientProjectPickerProps) {
    super(props);

    this._onRenderCell = this._onRenderCell.bind(this);
    this._onSelectionChanged = this._onSelectionChanged.bind(this);
    this._onGroupToggle = this._onGroupToggle.bind(this);
    this.state = {
      selection: new Selection({
        onSelectionChanged: this._onSelectionChanged,
      }),
      collapsedGroups: [
        { key: SELECTED_OR_DESELECT_ALL_PROJECTS, isCollapsed: false },
      ],
      useCallback: false,
    };
  }

  componentDidMount() {
    this.props.selectedProjects.forEach((projects) =>
      this.state.selection.setKeySelected(
        this.getProjectKey(projects),
        true,
        false
      )
    );
    this.setState({ useCallback: true });
  }

  _onSelectionChanged() {
    if (
      this.props.inSearch &&
      this.props.onSelection &&
      this.state.useCallback
    ) {
      // They have written something in the search field.

      const currentlyNotDisplayedSelectedProject: ResourceObjects =
        this.props.selectedProjects.filter(
          (project) =>
            !this.props.entities.find(
              (item) =>
                apihelper.getEntityType(item) === EntityTypes.PROJECT &&
                apihelper.getEntityId(item) === apihelper.getEntityId(project)
            )
        );

      this.props.onSelection(
        (this.state.selection.getSelection() as ResourceObjects)
          .concat(currentlyNotDisplayedSelectedProject)
          .map(
            (project) =>
              apihelper.getEntityId(project as ResourceObject) as string
          )
      );
    }
    if (
      !this.props.inSearch &&
      this.props.onSelection &&
      this.state.useCallback
    ) {
      // They have not written something in the search field.
      this.props.onSelection(
        this.state.selection
          .getSelection()
          .map(
            (project) =>
              apihelper.getEntityId(project as ResourceObject) as string
          )
      );
    }
  }

  _onRenderCell(
    nestingDepth?: number,
    item?: any,
    itemIndex?: number /*, group*/
  ) {
    const columns = [
      {
        key: "name",
        name: "name",
        fieldName: "name",
        minWidth: 500,
        maxWidth: 500,
      },
    ];

    return item && typeof itemIndex === "number" && itemIndex > -1 ? (
      <DetailsRow
        columns={columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex}
        selection={this.state.selection}
        selectionMode={SelectionMode.multiple}
        compact={true}
        /*group={group}*/
      />
    ) : null;
  }

  getProjectKey(entity: ResourceObject) {
    return (
      apihelper.getEntityType(entity) + "-" + apihelper.getEntityId(entity)
    );
  }

  _onGroupToggle(group: IGroup) {
    const updatedCollapsedGroups = this.state.collapsedGroups.slice();
    const index = updatedCollapsedGroups.indexOf(
      updatedCollapsedGroups.find(
        (e) => e.key === group.key
      ) as CollapsedGroupSetting
    );

    if (index === -1) {
      // the group with the corresponding key has not yet been toggled
      updatedCollapsedGroups.push({
        key: group.key,
        isCollapsed: !!group.isCollapsed,
      }); // Add the group and the toggle state
    } else {
      updatedCollapsedGroups[index].isCollapsed =
        !updatedCollapsedGroups[index].isCollapsed;
    }

    this.setState({ collapsedGroups: updatedCollapsedGroups });
  }

  render() {
    const { entities, hierarchyData, getEntityParentFunction } = this.props;

    const tree = generateTree(entities, hierarchyData, getEntityParentFunction);

    const [groupToUse, itemsToUse] =
      this.generateGroupsAndItemsOfListOfDepthTwo(tree);
    this.state.selection.setItems(itemsToUse, false);

    return (
      <div>
        <SelectionZone
          selection={this.state.selection}
          selectionMode={SelectionMode.multiple}
          selectionPreservedOnEmptyClick={true}
          isSelectedOnFocus={true}
        >
          <GroupedList
            items={itemsToUse}
            onRenderCell={this._onRenderCell}
            selection={this.state.selection}
            selectionMode={SelectionMode.multiple}
            groupProps={{
              showEmptyGroups: true,
              headerProps: {
                onToggleCollapse: (group) => this._onGroupToggle(group),
              },
            }}
            groups={groupToUse}
            compact={true}
          />
        </SelectionZone>
      </div>
    );
  }

  generateGroupsAndItemsOfListOfDepthTwo(tree: PickerTree): [IGroup[], Row[]] {
    const topLevelNodes = tree
      .getTopLevelNodes()
      .sort(
        storehelper.sortByLocaleCompare((pickerNode: PickerNode) =>
          (
            apihelper.getAttr(pickerNode.getEntity(), "name") as string
          ).toLowerCase()
        )
      );
    let itemArray: Row[] = [];
    const group: IGroup[] = [];
    let currentItemLength = 0;

    topLevelNodes.forEach((topLevelNode) => {
      const secondLevelNodes = tree
        .getChildrenOfParent(topLevelNode)
        .sort(
          storehelper.sortByLocaleCompare((pickerNode: PickerNode) =>
            (
              apihelper.getAttr(pickerNode.getEntity(), "name") as string
            ).toLowerCase()
          )
        );

      if (secondLevelNodes.length > 0) {
        itemArray = itemArray.concat(
          secondLevelNodes.map((secondLevelNode) =>
            projectToRow(secondLevelNode.getEntity())
          )
        );

        group.push({
          count: secondLevelNodes.length,
          isCollapsed:
            this.state.collapsedGroups.find(
              (e) => e.key === getEntityKey(topLevelNode.getEntity())
            )?.isCollapsed ?? this.props.inSearch
              ? false
              : true,
          key: getEntityKey(topLevelNode.getEntity()),
          level: 1,
          name: apihelper.getAttr(topLevelNode.getEntity(), "name") as string,
          startIndex: currentItemLength,
        });
        currentItemLength += secondLevelNodes.length;
      }
    });
    const groupWithAllSelection: IGroup[] = [
      {
        children: group,
        isCollapsed:
          this.state.collapsedGroups.find(
            (e) => e.key === SELECTED_OR_DESELECT_ALL_PROJECTS
          )?.isCollapsed || false,
        count: currentItemLength,
        key: SELECTED_OR_DESELECT_ALL_PROJECTS,
        level: 0,
        name: "Select/Deselect All",
        startIndex: 0,
      },
    ];

    return [groupWithAllSelection, itemArray];
  }
}
