import React, { useCallback, useMemo, useState } from "react";
import DetailsListBasic from "./DetailsListBasic";
import {
  IColumn,
  IDetailsRowProps,
  IGroup,
  IObjectWithKey,
  IRenderFunction,
} from "@fluentui/react";
import { GroupDefinition, SortCommand } from "./types";

type ProcessingGroup<TRow> = {
  groupDefinition: GroupDefinition<TRow>;
  toGroupKey: Map<TRow, string>;
  groupKeys: Set<string>;
};

type StackItem<TRow> = {
  group: IGroup;
  groupDefinition: GroupDefinition<TRow>;
  processingGroup: ProcessingGroup<TRow>;
  groupKey: string;
};

type ColumnGroupListProps<TRow> = {
  groupDefinitions: Array<GroupDefinition<TRow>>;
  //  groupState: GroupState<TRow>;
  sortCommand?: SortCommand<TRow>;
  items: Array<TRow>;
  columns: IColumn[];
  onRenderItemColumn?: (
    item?: any,
    index?: number,
    column?: IColumn
  ) => React.ReactNode;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
  onSelect: (selection: TRow[]) => void;
  isItemSelected: (item: TRow) => boolean;
};

const defaultStringCompare = (a: string, b: string) => a.localeCompare(b);

function prepareProcessGroups<TRow>(
  items: TRow[],
  groupDefinitions: GroupDefinition<TRow>[]
): ProcessingGroup<TRow>[] {
  const processGroups: ProcessingGroup<TRow>[] = groupDefinitions.map(
    (group) => ({
      groupDefinition: group,
      toGroupKey: new Map<TRow, string>(),
      groupKeys: new Set<string>(),
    })
  );

  // discover all groups and map items to them
  items.forEach((item) => {
    processGroups.forEach((procGrp) => {
      const groupKey = procGrp.groupDefinition.getGroupKey(item);
      procGrp.toGroupKey.set(item, groupKey);
      procGrp.groupKeys.add(groupKey);
    });
  });

  return processGroups;
}

function sortItemsByProcessGroups<TRow>(
  items: TRow[],
  processGroups: ProcessingGroup<TRow>[],
  columnSortCompare: (a: TRow, b: TRow) => number
): TRow[] {
  const sortByGroups = (a: TRow, b: TRow): number => {
    for (let i = 0; i < processGroups.length; i++) {
      const g = processGroups[i];
      const aGrp = g.toGroupKey.get(a) as string;
      const bGrp = g.toGroupKey.get(b) as string;
      const cmpFunc =
        g.groupDefinition.groupKeysCompare || defaultStringCompare;
      const cmpResult = cmpFunc(aGrp, bGrp);
      if (cmpResult !== 0) {
        return cmpResult;
      }
    }
    return columnSortCompare(a, b);
  };

  // sort according to selected groups and sort column
  return items.slice().sort(sortByGroups);
}

function buildGroupsFromOrderedItems<TRow>(
  orderedItems: TRow[],
  processGroups: ProcessingGroup<TRow>[],
  collapsedGroups: Record<string, boolean>
): IGroup[] {
  const getGroupKeys = (
    item: TRow,
    procGroup: ProcessingGroup<TRow>,
    parent?: StackItem<TRow>
  ): [string, string] => {
    const groupKey = procGroup.toGroupKey.get(item) as string;
    const hierarchicalGroupKey = `${
      parent ? parent.group.key : ""
    }.${groupKey}`;
    return [hierarchicalGroupKey, groupKey];
  };

  // make lookup structure for collapsed groups
  // build the set of groups
  const groups: IGroup[] = [];
  const stack: StackItem<TRow>[] = [];
  const finishTopmostGroup = () => {
    const stackItem = stack.pop() as StackItem<TRow>;
    stackItem.group.name = stackItem.groupDefinition.getGroupDisplayName(
      stackItem.groupKey,
      orderedItems.slice(
        stackItem.group.startIndex,
        stackItem.group.startIndex + stackItem.group.count
      )
    );
  };
  const getGroupIdForItemWithCurrentStack = (
    item: TRow,
    lastProcGrp?: ProcessingGroup<TRow>
  ): string =>
    stack
      .map((stackItem) => stackItem.processingGroup)
      .concat(lastProcGrp || [])
      .map((procGrp) => procGrp.toGroupKey.get(item) as string)
      .join(".");

  for (let itemIdx = 0; itemIdx < orderedItems.length; itemIdx++) {
    const item = orderedItems[itemIdx];
    // pop groups off stack that the item is not member of
    while (stack.length > 0) {
      const { group: resultGroup } = stack[stack.length - 1];
      const hGroupKey = getGroupIdForItemWithCurrentStack(item);
      if (hGroupKey !== resultGroup.key) {
        // items does not belong in this group and so will none of the remaining items, since the list is sorted by groups.
        // this means this group is accounted for. give it the proper name and book it
        finishTopmostGroup();
      } else {
        // this group is still relevant for this item, and so will the remaining groups, leave it
        break;
      }
    }

    // build up the groups that are relevant to this item
    while (stack.length < processGroups.length) {
      const processGroup = processGroups[stack.length];
      const groupParent =
        stack.length > 0 ? stack[stack.length - 1] : undefined;
      const hGroupKey = getGroupIdForItemWithCurrentStack(item, processGroup);
      const newGroup: IGroup = {
        key: hGroupKey,
        name: "",
        isCollapsed: !!collapsedGroups[hGroupKey],
        startIndex: itemIdx,
        count: 0,
        children: [],
        level: stack.length,
      };
      stack.push({
        group: newGroup,
        groupDefinition: processGroup.groupDefinition,
        processingGroup: processGroup,
        groupKey: processGroup.toGroupKey.get(item) as string,
      });
      if (groupParent) {
        // add the new group as child of the parent group
        groupParent.group.children?.push(newGroup);
      }
      if (stack.length === 1) {
        // outermost group
        groups.push(newGroup);
      }
    }

    // increment every group on the stack for this item
    stack.forEach((stackItem) => stackItem.group.count++);
  }

  // finish the groups remaining on the stack
  while (stack.length > 0) {
    finishTopmostGroup();
  }

  return groups;
}

export default function GroupList<TRow>({
  columns,
  items,
  onRenderItemColumn,
  groupDefinitions,
  sortCommand,
  onRenderRow,
  isItemSelected,
  onSelect,
}: ColumnGroupListProps<TRow>) {
  const [collapsedGroups, setCollapsedGroups] = useState<
    Record<string, boolean>
  >({});
  const columnSortCompare = useCallback(
    (a: TRow, b: TRow): number =>
      sortCommand ? sortCommand.compareFunction(a, b) : 0,
    [columns, sortCommand]
  );

  const processGroups = useMemo(
    () => prepareProcessGroups(items, groupDefinitions),
    [items, groupDefinitions]
  );

  const orderedItems = useMemo(
    () => sortItemsByProcessGroups(items, processGroups, columnSortCompare),
    [processGroups, items, columnSortCompare]
  );

  const groups = useMemo(() => {
    return buildGroupsFromOrderedItems(
      orderedItems,
      processGroups,
      collapsedGroups
    );
  }, [orderedItems, processGroups, collapsedGroups]);

  const sortNotatedColumns = useMemo(
    () =>
      columns.map((column) =>
        sortCommand?.columnKey === column.key
          ? Object.assign({}, column, {
              isSorted: true,
              isSortedDescending: !sortCommand.isSortedAscending,
            })
          : column
      ),
    [sortCommand, columns]
  );

  const onToggleCollapse = (group: IGroup) => {
    collapsedGroups[group.key] = !collapsedGroups[group.key];
    setCollapsedGroups(collapsedGroups);
  };

  return (
    <DetailsListBasic
      className="novatime-time-registration-list"
      columns={sortNotatedColumns}
      items={orderedItems}
      onRenderItemColumn={onRenderItemColumn}
      onRenderRow={onRenderRow}
      onSelect={onSelect as (selection: IObjectWithKey[]) => void}
      selectable={true}
      multiselect={false}
      isItemSelected={isItemSelected}
      groups={groups.length > 0 ? groups : undefined}
      groupProps={{
        headerProps: {
          onToggleCollapse: onToggleCollapse,
        },
      }}
    />
  );
}
