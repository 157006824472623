import React, { Component } from "react";

export default class Jumbotron extends Component {
  render() {
    let { className, ...other } = this.props;
    className = (className || "") + " novatime-jumbotron";

    return (
      <div className={className} {...other}>
        {this.props.children}
      </div>
    );
  }
}
