import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Label } from "@fluentui/react/lib/Label";
import { ActionButton, DefaultButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import DatePicker from "../../components/uifabricextensions/DatePicker";
import BasePickerSimple, {
  BasePickerSimpleDivider,
} from "../../components/uifabricextensions/BasePickerSimple";
import HorizontalRule from "../../components/HorizontalRule";
import TimeRegistrationList from "../../components/lists/TimeRegistrationList";
import Grid from "../../components/grid/Grid";
import Row from "../../components/grid/Row";
import Column from "../../components/grid/Column";
import BillableSet from "../../lib/BillableSet";
import { ClientAndProjectTagPicker } from "../../components/pickers/ClientAndProjectTagPicker";
import * as billabilityTypes from "../../constants/BillabilityTypes";
import * as timeRegistrationExportActions from "../../actions/pages/timeRegistrationExport";
import * as contractRoleSelectors from "../../selectors/reducers/contractRole";
import * as billabilityTypeSelectors from "../../selectors/reducers/billabilityTypes";
import * as timeRegistrationExportSelectors from "../../selectors/pages/timeRegistrationExport";
import * as appDomainSelectors from "../../selectors/app/domain";
import * as apihelper from "../../selectors/apihelper";
import * as icons from "../../constants/Icons";
import * as ExcelJs from "exceljs";
import * as EntityTypes from "../../constants/EntityTypes";
import * as contractSelectors from "../../selectors/reducers/contract";
import * as resourceTypeSelectors from "../../selectors/reducers/resourceType";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import HourSet from "../../lib/HourSet";
import * as datehelper from "../../lib/date";
import { ParentOptions } from "../../components/pickers/PickerTree";
import { NodeParent } from "../../components/pickers/ClientAndProjectPicker";
import ExcelExport from "../../components/timeRegistration/ExcelExport";

type TimeRegistrationExportProps = {
  startTime: Date | null;
  endTime: Date | null;
  allClients: ResourceObjects;
  allProjects: ResourceObjects;
  hourSet: HourSet;
  selectedResourceTypeIds: string[];
  selectedResourceTypes: ResourceObjects;
  resourceTypeOptions: ResourceObjects;
  selectableResources: ResourceObjects;
  selectedResourceIds: string[];
  allContractRoles: ResourceObjects;
  billableSet: BillableSet;
  allContracts: ResourceObjects;
  timeRegs: ResourceObjects;
  selectedResources: ResourceObjects;
  selectedProjectsForTagPicker: ResourceObjects;
  addDividerByChangeInResourceTypeId: BasePickerSimpleDivider[];
  actions: {
    pageOpened: () => void;
    selectDates: (start: Date | null, end: Date | null) => void;
    selectProjectIds: (selectedProjectIds: string[]) => void;
    selectResourceTypeIds: (selectedResourceTypeIds: string[]) => void;
    selectResourceIds: (selectedResourceIds: string[]) => void;
  };
};

class TimeRegistrationExport extends Component<TimeRegistrationExportProps> {
  constructor(props: TimeRegistrationExportProps) {
    super(props);
    this._onSelectStartDate = this._onSelectStartDate.bind(this);
    this._onSelectEndDate = this._onSelectEndDate.bind(this);
    this._onSelectionProjects = this._onSelectionProjects.bind(this);
    this._onChangeResourceType = this._onChangeResourceType.bind(this);
    this._onChangeResource = this._onChangeResource.bind(this);
    this._onSelectRemoveAllResources =
      this._onSelectRemoveAllResources.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  _onSelectStartDate(date: Date) {
    const { endTime } = this.props;
    this.props.actions.selectDates(datehelper.startOfDay(date), endTime);
  }

  _onSelectEndDate(date: Date) {
    const { startTime } = this.props;
    this.props.actions.selectDates(startTime, datehelper.endOfDay(date));
  }

  _onSelectionProjects(selectedProjectIds: string[]) {
    this.props.actions.selectProjectIds(selectedProjectIds);
  }

  _onChangeResourceType(selectedEntity: ResourceObject | null) {
    const { selectedResourceTypeIds } = this.props;

    if (selectedEntity !== null) {
      const isRemove = selectedResourceTypeIds.includes(
        apihelper.getEntityId(selectedEntity) as string
      );
      const updatedSelectedResourceTypeIds = isRemove
        ? selectedResourceTypeIds.filter(
            (id) => !apihelper.entityHasId(selectedEntity, id)
          )
        : [
            ...selectedResourceTypeIds,
            apihelper.getEntityId(selectedEntity) as string,
          ];

      this.props.actions.selectResourceTypeIds(updatedSelectedResourceTypeIds);
    }
  }

  _onChangeResource(selectedEntity: ResourceObject | null) {
    let { selectedResourceIds } = this.props;

    if (selectedEntity !== null) {
      if (
        selectedResourceIds.includes(
          apihelper.getEntityId(selectedEntity) as string
        )
      ) {
        //If we press a already selected resource
        selectedResourceIds = selectedResourceIds.filter(
          (id) => !apihelper.entityHasId(selectedEntity, id)
        ); //Filter selectedresourceID based on the ID of the chosen resource. That is; retain only that ID
      } else {
        selectedResourceIds = selectedResourceIds.slice();
        selectedResourceIds.push(
          apihelper.getEntityId(selectedEntity) as string
        ); //Else add the chosen Id to selectedresourceID
      }
    }

    this.props.actions.selectResourceIds(selectedResourceIds);
  }

  _onSelectRemoveAllResources() {
    this.props.actions.selectResourceIds([]);
  }

  render() {
    const {
      startTime,
      endTime,
      allClients,
      allProjects,
      selectedResourceTypes,
      resourceTypeOptions,
      selectableResources,
      timeRegs,
      hourSet,
      allContracts,
      selectedResources,
      selectedProjectsForTagPicker,
      addDividerByChangeInResourceTypeId,
      billableSet,
    } = this.props;

    const disabled = timeRegs.length === 0;

    return (
      <div className="novatime-page-timeregistrationexport">
        <div className="novatime-container">
          <Grid>
            <Row>
              <Column sm={12} lg={4} xl={4}>
                <Label>Start date</Label>
                <DatePicker
                  value={startTime}
                  onSelectDate={this._onSelectStartDate}
                />
                <div className="height2em ms-hiddenXlUp" />
              </Column>
              <Column sm={12} lg={4} xl={4}>
                <Label>End date</Label>
                <DatePicker
                  value={endTime}
                  onSelectDate={this._onSelectEndDate}
                />
                <div className="height2em ms-hiddenXlUp" />
              </Column>
            </Row>
            <br />
            <Row>
              <Column sm={12} lg={5} xl={5}>
                <Label>Projects</Label>
                <ClientAndProjectTagPicker
                  onSelection={this._onSelectionProjects}
                  entities={allProjects.concat(allClients)}
                  hierarchyData={{
                    allProjects,
                    allClients,
                    allContracts,
                  }}
                  getEntityParentFunction={(
                    entity,
                    hierarchyData
                  ): NodeParent => {
                    const eType = apihelper.getEntityType(entity);
                    if (eType === EntityTypes.PROJECT) {
                      const contract = hierarchyData.allContracts.find(
                        (contract: ResourceObject) =>
                          apihelper.relRefersToEntity(
                            entity,
                            "contract",
                            contract
                          )
                      );
                      if (contract) {
                        const client = hierarchyData.allClients.find(
                          (client: ResourceObject) =>
                            apihelper.relRefersToEntity(
                              contract,
                              "client",
                              client
                            )
                        );
                        if (client) {
                          return {
                            type: ParentOptions.PARENT as ParentOptions, //projects have parents, clients are orphans
                            value: client,
                          };
                        }
                      }
                    }
                    return {
                      type: ParentOptions.ORPHAN as ParentOptions,
                    };
                  }}
                  selectedProjects={selectedProjectsForTagPicker}
                />
              </Column>
              <Column sm={12} lg={5} xl={5}>
                <Label>Resources</Label>
                <div className="novatime-timeregexportresourcepicker-container">
                  <div className="novatime-timeregexportresourcepicker-dropdown">
                    <BasePickerSimple
                      placeholder={"All resources"}
                      selectedEntities={selectedResources}
                      entities={selectableResources}
                      multiSelect={true}
                      onEntitySelected={this._onChangeResource}
                      nameFunction={(e) =>
                        apihelper.getAttr(e, "name") as string
                      }
                      idFunction={(e) => e.id}
                      dividerList={addDividerByChangeInResourceTypeId} //array of objects with keys (entityId, headerName, dividerId) - divider and header should be added in change of dividerId
                    />
                  </div>
                  <div className="novatime-timeregexportresourcepicker-removeall">
                    <DefaultButton
                      allowDisabledFocus={true}
                      disabled={false}
                      checked={true}
                      text="Clear"
                      onClick={this._onSelectRemoveAllResources}
                    />
                  </div>
                </div>
              </Column>
              <Column sm={12} lg={2} xl={2}>
                <BasePickerSimple
                  label={"Resource Type"}
                  placeholder={"Select resource type"}
                  selectedEntities={selectedResourceTypes}
                  entities={resourceTypeOptions}
                  multiSelect={true}
                  onEntitySelected={this._onChangeResourceType}
                  nameFunction={(e) => apihelper.getAttr(e, "name") as string}
                  idFunction={(e) => e.id}
                />
                <div className="height2em" />
              </Column>
            </Row>
          </Grid>
        </div>
        <div className="novatime-container">
          <div className="height4em hiddenLgUp" />
          <HorizontalRule
            text={"Time Registrations - " + timeRegs.length + " found"}
            alignment="left"
          />
          <ExcelExport
            hourSet={hourSet}
            timeRegistrations={timeRegs}
            disabled={disabled}
            billableSet={billableSet}
          />
          {timeRegs.length > 0 ? (
            <TimeRegistrationList
              hourSet={hourSet}
              omitStatusColumn={true}
              timeRegistrations={timeRegs}
              enableGrouping={true}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const localStartTime = timeRegistrationExportSelectors.startTime(state), //Chosen start date
    localEndTime = timeRegistrationExportSelectors.endTime(state), //Chosen end date
    localSelectedResourceIds =
      timeRegistrationExportSelectors.selectedResourceIds(state),
    localAllContractRoles = contractRoleSelectors.allContractRoles(state),
    billableSet = new BillableSet(
      billabilityTypeSelectors.allBillabilityTypes(state)
    );

  const allContracts = contractSelectors.allContracts(state);
  const allClients = appDomainSelectors.allClientsForSelectedAccount(state);

  const allProjects =
    timeRegistrationExportSelectors.allProjectsForAccount(state);
  const localSelectedResourceTypeIds =
    timeRegistrationExportSelectors.selectedResourceTypeIds(state);
  const selectedResourceTypes =
    timeRegistrationExportSelectors.selectedResourceTypes(state);
  const resourceTypeOptions =
    timeRegistrationExportSelectors.resourceTypeOptions(state);
  const selectableResources =
    timeRegistrationExportSelectors.selectableResources(state);
  const selectedResources =
    timeRegistrationExportSelectors.selectedResources(state);
  const timeRegs =
    timeRegistrationExportSelectors.timeRegistrationsForCurrentFilter(state);
  const selectedProjectsForTagPicker =
    timeRegistrationExportSelectors.selectedProjectsForTagPicker(state);

  const addDividerByChangeInResourceTypeId = selectableResources
    .slice()
    .map((resource) => {
      if (!apihelper.getAttr(resource, "isActive")) {
        return {
          entityId: apihelper.getEntityId(resource),
          headerName: "Inactive Resources",
          dividerId: resourceTypeOptions.length,
        };
      }
      return {
        entityId: apihelper.getEntityId(resource),
        headerName: resourceTypeSelectors.resourceTypeNameById(
          state,
          apihelper.getRelId(resource, "resourceType")
        ),
        dividerId: apihelper.getRelId(resource, "resourceType"),
      };
    });

  return {
    startTime: localStartTime,
    endTime: localEndTime,
    allClients: allClients,
    allProjects: allProjects,
    selectedResourceTypeIds: localSelectedResourceTypeIds,
    selectedResourceTypes: selectedResourceTypes,
    resourceTypeOptions: resourceTypeOptions,
    selectableResources: selectableResources,
    selectedResourceIds: localSelectedResourceIds,
    allContractRoles: localAllContractRoles,
    billableSet,
    allContracts: allContracts,
    timeRegs: timeRegs,
    hourSet:
      timeRegistrationExportSelectors.hourSetForTimeRegistrationList(state),
    selectedResources,
    selectedProjectsForTagPicker: selectedProjectsForTagPicker,
    addDividerByChangeInResourceTypeId,
  };
}

function mapDispatch(dispatch: any) {
  const actions = Object.assign({}, timeRegistrationExportActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(TimeRegistrationExport as any);
