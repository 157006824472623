import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";
import * as apihelper from "../../selectors/apihelper";
import { DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import { SelectableOptionMenuItemType } from "@fluentui/react";

const toOption = (
  key: string,
  text: string,
  disabled: boolean,
  dividerId: string,
  headerName: string
): Option => ({
  key,
  text,
  disabled,
  dividerId,
  headerName,
});

type Option = {
  key: string;
  text?: string | null;
  disabled?: boolean;
  dividerId?: string;
  headerName?: string;
  itemType?: SelectableOptionMenuItemType;
};

export type BasePickerSimpleDivider = {
  entityId: string;
  headerName: string;
  dividerId: string;
};

type BasePickerSimpleProps = {
  placeholder?: string;
  isSelectionOptional?: boolean;
  disabled?: boolean;
  entities: ResourceObjects;
  disabledEntityIds?: string[];
  icon?: string;
  idFunction: (entity: ResourceObject) => string;
  isRequired?: boolean;
  label?: string;
  multiSelect?: boolean;
  nameFunction: (entity: ResourceObject) => string;
  onEntitySelected: (entity: ResourceObject | null) => void;
  required?: boolean;
  selectedEntity?: ResourceObject;
  selectedEntities?: ResourceObjects;
  // if silentIfBlank == false then a callback is generated when _blankObject is chosen. True as default.
  silentIfBlank?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  //array of objects with keys (entityId, headerName, dividerId) - divider and header should be added in change of dividerId
  dividerList?: Array<BasePickerSimpleDivider>;
};

type BasePickerSimpleState = {
  errorMessage?: string;
};

export default class BasePickerSimple extends Component<
  BasePickerSimpleProps,
  BasePickerSimpleState
> {
  public _blankObject: IDropdownOption = { key: "-1", text: "" };

  constructor(props: BasePickerSimpleProps) {
    super(props);

    this._onChange = this._onChange.bind(this);
    this._onDismiss = this._onDismiss.bind(this);
    this._onOpen = this._onOpen.bind(this);
    this.state = {};
  }

  static defaultProps = {
    idFunction: (entity: ResourceObject) =>
      apihelper.getEntityType(entity) + "." + apihelper.getEntityId(entity),
    nameFunction: (entity: ResourceObject) =>
      apihelper.getAttr(entity, "name") || apihelper.getAttr(entity, "title"),
    multiSelect: false,
    isSelectionOptional: false,
    silentIfBlank: true,
  };

  _onChange(
    event: React.FormEvent<HTMLDivElement>,
    value?: IDropdownOption,
    index?: number //  (e, value)
  ) {
    const { onEntitySelected, entities, idFunction } = this.props;

    if (!value) {
      return;
    }

    if (
      value === this._blankObject &&
      !this.props.silentIfBlank &&
      onEntitySelected
    ) {
      onEntitySelected(null); // Callback if silentIfBlank == false.
    }

    if (value !== this._blankObject && onEntitySelected) {
      const selectedEntity = entities.find((p) => idFunction(p) === value.key);
      if (selectedEntity) {
        onEntitySelected(selectedEntity);
      }
    }
  }

  _onDismiss() {
    const { required } = this.props;
    if (required) {
      this.setState({
        errorMessage: "Field is required",
      });
    }
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  _onOpen() {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  isEntityDisabled(entity: ResourceObject) {
    const { disabledEntityIds } = this.props;
    return !!(
      disabledEntityIds &&
      disabledEntityIds.includes(apihelper.getEntityId(entity) as string)
    );
  }

  addIdForDivider(entity: ResourceObject) {
    const { dividerList } = this.props;
    const dividerId = dividerList
      ? dividerList.find((item) => apihelper.entityHasId(entity, item.entityId))
          ?.dividerId
      : null;
    return dividerId;
  }

  addNameForHeader(entity: ResourceObject) {
    const { dividerList } = this.props;
    const headerName = dividerList
      ? dividerList.find((item) => apihelper.entityHasId(entity, item.entityId))
          ?.headerName
      : null;
    return headerName;
  }

  render() {
    const {
      selectedEntity,
      selectedEntities,
      entities,
      label,
      placeholder,
      idFunction,
      nameFunction,
      multiSelect,
      required,
      disabled,
      icon,
      isSelectionOptional,
    } = this.props;
    const selectedEntityId = selectedEntity
      ? idFunction(selectedEntity)
      : undefined;
    const selectedEntityIds = Array.isArray(selectedEntities)
      ? selectedEntities.map(idFunction)
      : undefined;

    const options: Option[] = (entities || []).map((e) =>
      toOption(
        idFunction(e),
        nameFunction(e),
        this.isEntityDisabled(e),
        this.addIdForDivider(e) as string,
        this.addNameForHeader(e) as string
      )
    );

    //we add divider and header whenever there is a change in the dividerId
    for (let i = 1; i < options.length; i++) {
      const prev = options[i - 1];
      const next = options[i];
      const dividerItem = {
        key: "divider" + i,
        text: "-",
        itemType: DropdownMenuItemType.Divider,
      };
      const headerItem = {
        key: "header" + (i + 1),
        text: next.headerName,
        itemType: DropdownMenuItemType.Header,
      };

      if (
        prev.dividerId &&
        next.dividerId &&
        next.headerName &&
        prev.dividerId !== next.dividerId
      ) {
        options.splice(i, 0, dividerItem);
        options.splice(i + 1, 0, headerItem);
        i += 2;
      }
    }

    //we add the first header
    if (options[0] && options[0].headerName) {
      options.splice(0, 0, {
        key: "header",
        text: options[0].headerName,
        itemType: DropdownMenuItemType.Header,
      });
    }

    if (isSelectionOptional) {
      options.unshift(this._blankObject as Option);
    }

    const selectProps: {
      selectedKeys?: string[];
      multiSelect?: boolean;
      selectedKey?: string;
    } = {};
    if (multiSelect) {
      selectProps.selectedKeys = selectedEntityIds;
      selectProps.multiSelect = !!multiSelect;
    } else {
      selectProps.selectedKey = selectedEntityId;
    }

    return (
      <div className="novatime-base-picker-simple">
        {label ? (
          <Label className={required ? "label-required" : ""}>{label}</Label>
        ) : undefined}
        <Dropdown
          required={required}
          placeholder={placeholder}
          {...selectProps}
          options={options as IDropdownOption[]}
          onChange={this._onChange}
          disabled={disabled}
          styles={{ label: { color: "black" } }}
          onDismiss={this._onDismiss}
          calloutProps={{
            onLayerMounted: this._onOpen,
          }}
          panelProps={{
            onOpen: this._onOpen,
          }}
          errorMessage={
            selectedEntityId || selectedEntityIds
              ? undefined
              : this.state.errorMessage
          }
        />
        {icon ? <Icon className="indicator" iconName={icon} /> : undefined}
      </div>
    );
  }
}
