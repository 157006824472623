import PropTypes from "prop-types";
import StateMerge from "../lib/stateMerge/StateMerge";
import HourSet from "../lib/HourSet";
import * as EntityTypes from "./EntityTypes";
import LockedTimePeriodIndex from "../lib/LockedTimePeriodIndex";

export const lockedTimePeriodIndex = PropTypes.instanceOf(
  LockedTimePeriodIndex
);
export const stateMerge = PropTypes.instanceOf(StateMerge);
export const hourSet = PropTypes.instanceOf(HourSet);

let outerEntityShape = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  relationships: PropTypes.object,
};

export const jsonApiEntity = PropTypes.shape(outerEntityShape).isRequired;
export const jsonApiEntities = PropTypes.arrayOf(jsonApiEntity);

export const clientEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.CLIENT]).isRequired,
  })
);
export const clientEntities = PropTypes.arrayOf(clientEntity);

export const projectEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.PROJECT]).isRequired,
  })
);
export const projectEntities = PropTypes.arrayOf(projectEntity);

export const resourceTypeEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.RESOURCE]).isRequired,
  })
);
export const resourceTypeEntities = PropTypes.arrayOf(resourceTypeEntity);

export const projectResourceEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.PROJECTRESOURCE]).isRequired,
  })
);
export const projectResourceEntities = PropTypes.arrayOf(projectResourceEntity);

export const contractRoleEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.CONTRACTROLE]).isRequired,
  })
);
export const contractRoleEntities = PropTypes.arrayOf(contractRoleEntity);

export const timeRegistrationEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.TIMEREGISTRATION]).isRequired,
  })
);
export const timeRegistrationEntities = PropTypes.arrayOf(
  timeRegistrationEntity
);

export const contractEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.CONTRACT]).isRequired,
  })
);
export const contractEntities = PropTypes.arrayOf(contractEntity);

export const lockedTimePeriodEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.LOCKEDTIMEPERIOD]).isRequired,
  })
);
export const lockedTimePeriodEntities = PropTypes.arrayOf(
  lockedTimePeriodEntity
);

export const resourceEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.RESOURCE]).isRequired,
  })
);
export const resourceEntities = PropTypes.arrayOf(resourceEntity);
export const singleOrArrayOfResourceEntities = PropTypes.oneOfType([
  resourceEntity,
  resourceEntities,
]);

export const userEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.USER]).isRequired,
  })
);
export const userEntities = PropTypes.arrayOf(userEntity);

export const accountEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.ACCOUNT]).isRequired,
  })
);
export const accountEntities = PropTypes.arrayOf(accountEntity);

export const permissionEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.PERMISSION]).isRequired,
  })
);
export const permissionEntities = PropTypes.arrayOf(permissionEntity);

export const permissionTypeEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.PERMISSIONTYPE]).isRequired,
  })
);
export const permissionTypeEntities = PropTypes.arrayOf(permissionTypeEntity);

export const absencePeriodEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.ABSENCEPERIOD]).isRequired,
  })
);
export const absencePeriodEntities = PropTypes.arrayOf(absencePeriodEntity);

export const absencePeriodStatusEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.ABSENCEPERIODSTATUS]).isRequired,
  })
);
export const absencePeriodStatusEntities = PropTypes.arrayOf(
  absencePeriodStatusEntity
);

export const absencePeriodCommentEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.ABSENCEPERIODCOMMENT]).isRequired,
  })
);
export const absencePeriodCommentEntities = PropTypes.arrayOf(
  absencePeriodCommentEntity
);

export const currencyEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.CURRENCY]).isRequired,
  })
);
export const currencyEntities = PropTypes.arrayOf(currencyEntity);

export const localeEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.LOCALE]).isRequired,
  })
);
export const localeEntities = PropTypes.arrayOf(localeEntity);

export const billabilityEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.BILLABILITYTYPE]).isRequired,
  })
);
export const billabilityEntities = PropTypes.arrayOf(billabilityEntity);

export const absenceRegistrationProjectEntity = PropTypes.shape(
  Object.assign({}, outerEntityShape, {
    type: PropTypes.oneOf([EntityTypes.ABSENCEREGISTRATIONPROJECT]).isRequired,
  })
);
export const absenceRegistrationProjectEntities = PropTypes.arrayOf(
  absenceRegistrationProjectEntity
);
