import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import * as editorActions from "../actions/EntityEditor";
import BillableSet from "../lib/BillableSet";
import * as appDomainSelectors from "../selectors/app/domain";
import * as currencySelectors from "../selectors/reducers/currency";
import * as languageSelectors from "../selectors/reducers/language";
import * as editorSelectors from "../selectors/EntityEditorSelectors";
import * as billabilitySelectors from "../selectors/reducers/billabilityTypes";
import * as projectAndRolesSelectors from "../selectors/pages/projectsAndRoles";
import * as projectStatusSelectors from "../selectors/reducers/projectStatus";
import * as usersAndResourcesSelectors from "../selectors/pages/usersAndResources";
import * as permissionTypesSelectors from "../selectors/reducers/permissionTypes";
import * as resourceTypeSelectors from "../selectors/reducers/resourceType";
import * as localeSelectors from "../selectors/reducers/locale";
import * as reducerAccountSelectors from "../selectors/reducers/account";
import * as reducerPermissionSelectors from "../selectors/reducers/permission";
import * as absencePeriodStatusSelectors from "../selectors/reducers/absencePeriodStatus";
import * as absencePeriodTypeSelectors from "../selectors/reducers/absencePeriodType";
import * as pageSelectors from "../selectors/pages/absencePeriods";
import * as reducerUserSelectors from "../selectors/reducers/user";
import * as apihelper from "../selectors/apihelper";
import * as reducerProjectSelectors from "../selectors/reducers/project";
import * as reducerProjectResourceSelectors from "../selectors/reducers/projectResource";
import * as storehelper from "../selectors/storehelper";
import { ResourceObject, ResourceObjects } from "../lib/models";
import FormCommand from "../lib/FormCommand";

import AccountForm from "../components/forms/AccountForm";
import ClientForm from "../components/forms/ClientForm";
import ContractForm from "../components/forms/ContractForm";
import ContractRoleForm from "../components/forms/ContractRoleForm";
import ProjectForm from "../components/forms/ProjectForm";
import ProjectResourceForm from "../components/forms/ProjectResourceForm";
import DeleteForm from "../components/forms/DeleteForm";
import UserForm from "../components/forms/UserForm";
import ResourceForm from "../components/forms/ResourceForm";
import PermissionForm from "../components/forms/PermissionForm";
import AbsencePeriodForm from "../components/forms/AbsencePeriodForm";
import AbsencePeriodCommentForm from "../components/forms/AbsencePeriodCommentForm";
import WorkLocationRegistrationForm from "../components/forms/WorkLocationRegistrationForm";

const sortEntityByName = storehelper.sortByLocaleCompare(
  (entity: ResourceObject) =>
    (
      (apihelper.getAttr(entity, "name") as string) ||
      (apihelper.getAttr(entity, "title") as string) ||
      ""
    ).toLowerCase()
);

const formMap: Record<
  string,
  React.FC | (new (...args: any[]) => React.Component)
> = {
  AccountForm: AccountForm,
  ClientForm: ClientForm as React.FC,
  ContractForm: ContractForm,
  ContractRoleForm: ContractRoleForm,
  ProjectForm: ProjectForm,
  ProjectResourceForm: ProjectResourceForm,
  DeleteForm: DeleteForm,
  UserForm: UserForm,
  ResourceForm: ResourceForm,
  PermissionForm: PermissionForm,
  AbsencePeriodForm: AbsencePeriodForm,
  AbsencePeriodCommentForm: AbsencePeriodCommentForm,
  WorkLocationRegistrationForm: WorkLocationRegistrationForm as React.FC,
};

export const getFormName = (
  form: (new (...args: any[]) => React.Component) | React.FC
): string | undefined =>
  Object.keys(formMap).find((formName) => formMap[formName] === form);

type EntityEditorDispatchProps = {
  actions: {
    endEdit: (commands: FormCommand[]) => void;
    clearEdit: () => void;
    selectTempInternalClientId: (clientId: string) => void;
  };
};

type EntityEditorStateProps = {
  modelUnderEdit: ResourceObject | null;
  modelForm: React.FC | (new (...args: any[]) => React.Component);
  allCurrency: ResourceObjects;
  allLanguage: ResourceObjects;
  projectsByInternalClient: ResourceObjects;
  clientsForSelectedAccount: ResourceObjects;
  billableSet: BillableSet;
  billableBillabilityTypes: ResourceObjects;
  subcontractorBillabilityTypes: ResourceObjects;
  allResourcesForSelectedAccount: ResourceObjects;
  allContractsForSelectedClient: ResourceObjects;
  allProjectsForSelectedClient: ResourceObjects;
  allProjectResourcesForSelectedProject: ResourceObjects;
  allContractRolesForSelectedClient: ResourceObjects;
  allProjectStatus: ResourceObjects;
  allUsersForSelectedAccount: ResourceObjects;
  modelsForAccountForm: ResourceObjects;
  clientByProjectIdMap: Array<{ projectId: string; client: ResourceObject }>;
  permissionTypeNameToIds: Record<string, string>;
  allClientsForPermissions: ResourceObjects;
  allProjectsForPermissions: ResourceObjects;
  selectedPermissionUser?: ResourceObject;
  selectedPermissionUserPermissions: ResourceObjects;
  allUsersForSelectedAccountWithoutPermissions: ResourceObjects;
  locale: ResourceObject;
  allPermissionTypes: ResourceObjects;
  allResourceType: ResourceObjects;
  locales: ResourceObjects;
  allAccounts: ResourceObjects;
  allAbsencePeriodStatus: ResourceObjects;
  allAbsencePeriodTypes: ResourceObjects;
  usersForComments: ResourceObjects;
  commentsForSelectedAbsencePeriod: ResourceObjects;
  selectedAccount: ResourceObject;
  absenceRegistrationProjects: ResourceObjects;
};

type EntityEditorOwnProps = Record<string, never>;

type EntityEditorProps = EntityEditorStateProps & EntityEditorDispatchProps;

class EntityEditor extends Component<EntityEditorProps> {
  constructor(props: EntityEditorProps) {
    super(props);

    this._onSaveForm = this._onSaveForm.bind(this);
    this._dismissAllPanels = this._dismissAllPanels.bind(this);

    this._onInternalClientSelect = this._onInternalClientSelect.bind(this);
  }

  _onSaveForm(formCommands: FormCommand[]) {
    this.props.actions.endEdit(formCommands);
  }

  // panel control
  _dismissAllPanels() {
    // todo: fix this. the form should handle this somehow
    if (
      this.props.modelUnderEdit &&
      apihelper.getEntityType(this.props.modelUnderEdit) === "Account"
    ) {
      this._onInternalClientSelect(
        apihelper.getRelId(
          this.props.modelUnderEdit,
          "internalClient"
        ) as string
      ); // Have to reselect old clientId for account if window is dismissed.
    } // If not, the project will not load if you re-open account edit.

    if (this.props.modelUnderEdit) {
      this.props.actions.clearEdit();
    }
  }

  _onInternalClientSelect(clientId: string) {
    this.props.actions.selectTempInternalClientId(clientId);
  }

  render() {
    const {
      modelUnderEdit,
      modelForm,
      allCurrency,
      allLanguage,
      projectsByInternalClient,
      clientsForSelectedAccount,
      billableSet,
      billableBillabilityTypes,
      subcontractorBillabilityTypes,
      allResourcesForSelectedAccount,
      allContractsForSelectedClient,
      allProjectsForSelectedClient,
      allProjectResourcesForSelectedProject,
      allContractRolesForSelectedClient,
      allProjectStatus,
      allUsersForSelectedAccount,
      modelsForAccountForm,
      clientByProjectIdMap,
      permissionTypeNameToIds,
      allClientsForPermissions,
      allProjectsForPermissions,
      selectedPermissionUser,
      selectedPermissionUserPermissions,
      allUsersForSelectedAccountWithoutPermissions,
      locale,
      allPermissionTypes,
      allResourceType,
      locales,
      allAccounts,
      allAbsencePeriodStatus,
      allAbsencePeriodTypes,
      usersForComments,
      commentsForSelectedAbsencePeriod,
    } = this.props;

    const showPanel = !!modelUnderEdit;
    let panelForm: React.ReactElement | null = null;

    if (modelUnderEdit) {
      if (modelForm === AccountForm) {
        panelForm = (
          <AccountForm
            models={modelsForAccountForm}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
            clientForAccount={clientsForSelectedAccount}
            internalClientSelect={this._onInternalClientSelect}
            projectsByInternalClient={projectsByInternalClient.sort(
              sortEntityByName
            )}
            allAbsencePeriodTypes={allAbsencePeriodTypes}
            allCurrency={allCurrency}
            allLanguage={allLanguage}
          />
        );
      } else if (modelForm === ClientForm) {
        panelForm = (
          <ClientForm
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
          />
        );
      } else if (modelForm === ContractForm) {
        panelForm = (
          <ContractForm
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
          />
        );
      } else if (modelForm === ContractRoleForm) {
        panelForm = (
          <ContractRoleForm
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
            billableSet={billableSet}
            billableBillabilityTypes={billableBillabilityTypes}
            subcontracterBillabilityTypes={subcontractorBillabilityTypes}
            allCurrency={allCurrency}
          />
        );
      } else if (modelForm === ProjectForm) {
        panelForm = (
          <ProjectForm
            contracts={allContractsForSelectedClient}
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
            allProjectStatus={allProjectStatus}
          />
        );
      } else if (modelForm === ProjectResourceForm) {
        panelForm = (
          <ProjectResourceForm
            resources={allResourcesForSelectedAccount}
            contractRoles={allContractRolesForSelectedClient}
            projects={allProjectsForSelectedClient}
            projectResources={allProjectResourcesForSelectedProject}
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
          />
        );
      } else if (modelForm === UserForm) {
        panelForm = (
          <UserForm
            users={allUsersForSelectedAccount}
            resources={allResourcesForSelectedAccount}
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
          />
        );
      } else if (modelForm === ResourceForm) {
        panelForm = (
          <ResourceForm
            users={allUsersForSelectedAccount}
            resources={allResourcesForSelectedAccount}
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
            locales={locales}
            locale={locale}
            allResourceType={allResourceType}
          />
        );
      } else if (modelForm === PermissionForm) {
        panelForm = (
          <PermissionForm
            users={allUsersForSelectedAccountWithoutPermissions}
            user={selectedPermissionUser}
            models={selectedPermissionUserPermissions}
            allAccounts={allAccounts}
            allClients={allClientsForPermissions}
            allProjects={allProjectsForPermissions}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
            permissionTypes={allPermissionTypes}
            clientByProjectIdMap={clientByProjectIdMap}
            permissionTypeNameToIds={permissionTypeNameToIds}
          />
        );
      } else if (modelForm === AbsencePeriodForm) {
        panelForm = (
          <AbsencePeriodForm
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
            allAbsencePeriodStatus={allAbsencePeriodStatus}
            allAbsencePeriodTypes={allAbsencePeriodTypes}
          />
        );
      } else if (modelForm === AbsencePeriodCommentForm) {
        panelForm = (
          <AbsencePeriodCommentForm
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
            usersForComments={usersForComments}
            comments={commentsForSelectedAbsencePeriod}
          />
        );
      } else if (modelForm === DeleteForm) {
        panelForm = (
          <DeleteForm
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onDelete={this._onSaveForm}
            hasReferences={false}
          />
        );
      } else if (modelForm === WorkLocationRegistrationForm) {
        panelForm = (
          <WorkLocationRegistrationForm
            model={modelUnderEdit}
            onDismiss={this._dismissAllPanels}
            onSave={this._onSaveForm}
          />
        );
      }
    }

    return (
      <Panel
        isOpen={showPanel}
        type={PanelType.largeFixed}
        onDismiss={this._dismissAllPanels}
        layerProps={{ styles: { root: { zIndex: 999998 } } }}
        closeButtonAriaLabel="Close"
      >
        {panelForm}
      </Panel>
    );
  }
}

function mapState(state: any): EntityEditorStateProps {
  const modelUnderEdit = editorSelectors.modelUnderEdit(state);
  const modelForm = formMap[editorSelectors.form(state)];

  //Panel - Edit Account
  const selectedAccount = appDomainSelectors.selectedAccount(state);
  const clientsForSelectedAccount = selectedAccount
    ? editorSelectors.allClientsBySelectedAccountSorted(state)
    : [];
  const projectsByInternalClient =
    editorSelectors.projectsForInternalClientForSelectedAccount(state);
  const allCurrency = currencySelectors.allCurrency(state);
  const allLanguage = languageSelectors.allLanguage(state);
  const modelsForAccountForm = editorSelectors.modelsForAccountForm(
    state
  ) as ResourceObjects;
  const absenceRegistrationProjects =
    appDomainSelectors.allAbsenceRegistrationProjectsForSelectedAccount(state);

  //Panel - Contract Role
  const billableSet = new BillableSet(
    billabilitySelectors.allBillabilityTypes(state)
  );
  const billableBillabilityTypes =
    billabilitySelectors.billableBillabilityTypes(state);
  const subcontractorBillabilityTypes =
    billabilitySelectors.subcontracterBillabilityTypes(state);

  //Panel - Project and ProjectResource
  let allContractsForSelectedClient: ResourceObjects = [];
  const allProjectStatus = projectStatusSelectors.allProjectStatus(state);
  const allResourcesForSelectedAccount =
    usersAndResourcesSelectors.allResourcesForSelectedAccount(state);
  let allContractRolesForSelectedClient: ResourceObjects = [];
  let allProjectsForSelectedClient: ResourceObjects = [];
  let allProjectResourcesForSelectedProject: ResourceObjects = [];

  let selectedProject: ResourceObject | null = null;
  let selectedProjectResource: ResourceObject | null = null;
  const selectedClientId = projectAndRolesSelectors.selectedClientId(state);
  const selectedProjectId = projectAndRolesSelectors.selectedProjectId(state);
  const selectedProjectResourceId =
    projectAndRolesSelectors.selectedProjectResourceId(state);

  if (selectedAccount && selectedClientId) {
    allContractRolesForSelectedClient =
      projectAndRolesSelectors.allContractRolesForSelectedClient(state);
    allProjectsForSelectedClient =
      projectAndRolesSelectors.allProjectsBySelectedClient(state);
    allContractsForSelectedClient =
      projectAndRolesSelectors.allContractsForSelectedClient(state);

    if (selectedProjectId) {
      selectedProject = reducerProjectSelectors.projectById(
        state,
        selectedProjectId
      ) as ResourceObject;
      allProjectResourcesForSelectedProject =
        reducerProjectResourceSelectors.allProjectResourcesByProject(
          state,
          selectedProject
        );
      if (selectedProjectResourceId) {
        selectedProjectResource =
          reducerProjectResourceSelectors.projectResourceById(
            state,
            selectedProjectResourceId
          ) as ResourceObject;
      }
    }
  }

  //Panel - User
  const allUsersForSelectedAccount =
    usersAndResourcesSelectors.allUsersForSelectedAccount(state);

  //Panel - Resource
  const localeId =
    modelUnderEdit &&
    !Array.isArray(modelUnderEdit) &&
    apihelper.getRelId(modelUnderEdit, "employedAtLocale");
  const locale = localeSelectors.localeById(state, localeId);
  const locales = localeSelectors.allLocale(state);
  const allResourceType = resourceTypeSelectors.allResourceType(state);

  //Panel - Permission
  const allPermissions = reducerPermissionSelectors.allPermissions(state);
  const permissionTypeNameToIds: Record<string, string> =
    usersAndResourcesSelectors.permissionTypeNameToIds(state);
  const userPermissionMap: Record<string, Record<string, ResourceObjects>> = {};
  allPermissions.forEach((permission: ResourceObject) => {
    const userId = apihelper.getRelId(permission, "user") as string;
    const typeId = apihelper.getRelId(permission, "permissionType") as string;
    if (!userPermissionMap[userId]) {
      userPermissionMap[userId] = Object.values(permissionTypeNameToIds).reduce(
        (map: Record<string, ResourceObjects>, cur: string) => {
          map[cur] = [];
          return map;
        },
        {}
      );
    }
    if (userPermissionMap[userId][typeId]) {
      userPermissionMap[userId][typeId].push(permission);
    }
  });

  const allUsersForSelectedAccountWithoutPermissions =
    allUsersForSelectedAccount.filter(
      (u) => !userPermissionMap[apihelper.getEntityId(u) as string]
    );
  const selectedPermissionUser =
    usersAndResourcesSelectors.selectedPermissionUser(state);
  const selectedPermissionUserPermissions =
    usersAndResourcesSelectors.selectedPermissionUserPermissions(state);
  const allAccounts = reducerAccountSelectors.allAccounts(state);
  const clientByProjectIdMap = usersAndResourcesSelectors.clientByProjectIdMap(
    state
  ) as Array<{ projectId: string; client: ResourceObject }>;

  const allClientsForPermissions =
    usersAndResourcesSelectors.allClientsForPermissions(state);
  const allClientsForSelectedAccount =
    usersAndResourcesSelectors.allClientsForSelectedAccount(state);
  allClientsForSelectedAccount.forEach((c1) => {
    if (
      !allClientsForPermissions.find((c2: ResourceObject) =>
        apihelper.entityHasId(c1, apihelper.getEntityId(c2))
      )
    ) {
      allClientsForPermissions.push(c1);
    }
  });

  const allProjectsForPermissions =
    usersAndResourcesSelectors.allProjectsForPermissions(state);
  const allProjectsForSelectedAccount =
    usersAndResourcesSelectors.allProjectsForSelectedAccount(state);
  allProjectsForSelectedAccount.forEach((p1) => {
    if (
      !allProjectsForPermissions.find((p2: ResourceObject) =>
        apihelper.entityHasId(p1, apihelper.getEntityId(p2))
      )
    ) {
      allProjectsForPermissions.push(p1);
    }
  });

  const allPermissionTypes = permissionTypesSelectors.allPermissionTypes(state);

  //Panel - AbsencePeriod
  const allAbsencePeriodStatus =
    absencePeriodStatusSelectors.allAbsencePeriodStatus(state);
  const allAbsencePeriodTypes =
    absencePeriodTypeSelectors.allAbsencePeriodTypes(state);

  //Panel - AbsencePeriod
  const commentsForSelectedAbsencePeriod =
    pageSelectors.selectedAbsencePeriodsComments(state);
  const usersForComments = commentsForSelectedAbsencePeriod
    .map((c: ResourceObject) => apihelper.getRelId(c, "sentByUser"))
    .unique()
    .map((id: string) => reducerUserSelectors.userById(state, id))
    .filter((u: ResourceObject) => apihelper.isEntity(u));

  return {
    modelUnderEdit,
    modelForm,
    allCurrency,
    allLanguage,
    selectedAccount,
    modelsForAccountForm,
    absenceRegistrationProjects,
    projectsByInternalClient,
    clientsForSelectedAccount,
    billableSet,
    billableBillabilityTypes,
    subcontractorBillabilityTypes,
    allContractsForSelectedClient,
    allClientsForPermissions,
    allProjectsForPermissions,
    allResourcesForSelectedAccount,
    allContractRolesForSelectedClient,
    allProjectStatus,
    allProjectsForSelectedClient,
    allProjectResourcesForSelectedProject,
    allPermissionTypes,
    allResourceType,
    clientByProjectIdMap,
    selectedPermissionUser,
    selectedPermissionUserPermissions,
    allUsersForSelectedAccount,
    allUsersForSelectedAccountWithoutPermissions,
    allAccounts,
    permissionTypeNameToIds,
    locale,
    locales,
    allAbsencePeriodStatus,
    allAbsencePeriodTypes,
    usersForComments,
    commentsForSelectedAbsencePeriod,
  };
}

function mapDispatch(dispatch: Dispatch): EntityEditorDispatchProps {
  const actions = Object.assign({}, editorActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect<
  EntityEditorStateProps,
  EntityEditorDispatchProps,
  EntityEditorOwnProps
>(
  mapState,
  mapDispatch
)(EntityEditor);
