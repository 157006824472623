import React, { Component } from "react";

/**
 * Component that alerts if you click outside of it
 */
type OutsideAlerterProps = {
  onOutsideClick: (event: MouseEvent) => void;
};

export default class OutsideAlerter extends Component<OutsideAlerterProps> {
  private wrapperRef: HTMLDivElement | undefined;

  constructor(props: OutsideAlerterProps) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node: HTMLDivElement) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event: MouseEvent) {
    // it has an inner element, the click is NOT in the inner element, but the click is on the page body (NOT on the scroll bar)
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target as Node) &&
      window.document.body.contains(event.target as Node)
    ) {
      if (this.props.onOutsideClick) {
        this.props.onOutsideClick(event);
      }
    }
  }

  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}
