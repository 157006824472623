import { createSelector } from "reselect";
import { lexicalInsensitiveNameSortFunc } from "../../lib/sorting";
import * as apihelper from "../apihelper";
import * as storehelper from "../storehelper";
import * as appDomainSelectors from "../app/domain";
import * as reducerProjectSelectors from "../reducers/project";
import * as contractRoleSelectors from "../reducers/contractRole";
import * as reducerAccountSelectors from "../reducers/account";
import * as reducerResourceTypeSelectors from "../reducers/resourceType";
import * as reducerResourceSelectors from "../reducers/resource";
import * as reducerTimeRegistrationSelectors from "../reducers/timeRegistration";
import { TimeRegistrationIndex } from "../../lib/stateMerge/TimeRegistrationIndex";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as apiCallReducerSelectors from "../reducers/apiCall";
import * as writeBackQueueReducerSelectors from "../reducers/writeBackQueue";
import StateMerge from "../../lib/stateMerge/StateMerge";
import * as contractSelectors from "../reducers/contract";
import HourSet from "../../lib/HourSet";

const selectableResourceSort = (a: ResourceObject, b: ResourceObject) => {
  const aIsActive = apihelper.getAttr(a, "isActive");
  const bIsActive = apihelper.getAttr(b, "isActive");
  if (aIsActive && !bIsActive) {
    return -1;
  } else if (bIsActive && !aIsActive) {
    return 1;
  } else {
    const aResourceTypeId = apihelper.getRelId(a, "resourceType") as string;
    const bResourceTypeId = apihelper.getRelId(b, "resourceType") as string;
    if (aResourceTypeId === bResourceTypeId) {
      return lexicalInsensitiveNameSortFunc(a, b);
    } else {
      return +bResourceTypeId - +aResourceTypeId;
    }
  }
};

export const selectedProjectIds = (state: any) =>
  state.page.timeRegistrationExport.filter.projectIds;

export const selectedResourceTypeIds = (state: any) =>
  state.page.timeRegistrationExport.filter.resourceTypeIds;

export const selectedResourceIds = (state: any) =>
  state.page.timeRegistrationExport.filter.resourceIds;

const startTimeFilter = (state: any) =>
  state.page.timeRegistrationExport.filter.startTime;

export const startTime = createSelector([startTimeFilter], (st) =>
  st === null ? st : new Date(st)
);

const endTimeFilter = (state: any) =>
  state.page.timeRegistrationExport.filter.endTime;

export const endTime = createSelector([endTimeFilter], (et) =>
  et === null ? et : new Date(et)
);

export const filter = (state: any) => state.page.timeRegistrationExport.filter;

export const selectedResources = createSelector(
  [selectedResourceIds, reducerResourceSelectors.allResources],
  (resourceIds, resources) =>
    resources.filter((r) => resourceIds.includes(apihelper.getEntityId(r)))
);

export const selectedProjects = createSelector(
  [reducerProjectSelectors.allProjects, selectedProjectIds],
  (projects, projectIds) =>
    storehelper.filterEntitiesByIds(projects, projectIds)
);

export const allProjectsForAccount = createSelector(
  [
    reducerAccountSelectors.currentUserAccount,
    reducerProjectSelectors.allProjects,
  ],
  (selectedAccount, allProjects) =>
    storehelper
      .filterByReferenceToRelatedEntities(
        allProjects,
        selectedAccount,
        "account"
      )
      .flatten()
      .sort(lexicalInsensitiveNameSortFunc)
);

export const selectedResourceTypes = createSelector(
  [reducerResourceTypeSelectors.allResourceType, selectedResourceTypeIds],
  (resourceType, selectedResourceTypeIds) =>
    storehelper.filterEntitiesByIds(resourceType, selectedResourceTypeIds)
);

export const resourceTypeOptions = createSelector(
  [reducerResourceTypeSelectors.allResourceType],
  (allResourceType) =>
    allResourceType.slice().sort(lexicalInsensitiveNameSortFunc)
);

export const projectResourcesForCurrentFilter = createSelector(
  [appDomainSelectors.allProjectResourcesForSelectedAccount, selectedProjects],
  (allProjectResourcesForSelectedAccount, selectedProjects) => {
    return selectedProjects.length === 0
      ? allProjectResourcesForSelectedAccount
      : storehelper.filterByReferenceToRelatedEntities(
          allProjectResourcesForSelectedAccount,
          selectedProjects,
          "project"
        );
  }
);

export const allocatableResourcesForCurrentFilter = createSelector(
  [
    projectResourcesForCurrentFilter,
    appDomainSelectors.allResourcesForSelectedAccount,
  ],
  (projectResources, allResourcesForSelectedAccount) => {
    const hasDefaultProjectResource = projectResources.some((pr) =>
      apihelper.getAttr(pr, "isDefaultProjectResource")
    );
    return hasDefaultProjectResource
      ? allResourcesForSelectedAccount
      : storehelper.filterByRelatedEntity(
          allResourcesForSelectedAccount,
          projectResources,
          "resource"
        );
  }
);

export const timeRegistrationIndex = createSelector(
  [reducerTimeRegistrationSelectors.allTimeRegistrations],
  (allTimeRegistrations) => {
    return new TimeRegistrationIndex(allTimeRegistrations);
  }
);

export const timeRegistrationsForCurrentFilter = createSelector(
  [
    timeRegistrationIndex,
    selectedProjectIds,
    startTime,
    endTime,
    selectedResources,
    allocatableResourcesForCurrentFilter,
    selectedResourceTypeIds,
  ],
  (
    timeRegistrationIndex,
    selectedProjectIds,
    startTime,
    endTime,
    selectedResources,
    resourcesForCurrentFilter,
    selectedResourceTypeIds
  ) => {
    let resourcesForFilter: ResourceObjects = resourcesForCurrentFilter;
    if (selectedResources.length > 0) {
      resourcesForFilter = storehelper.intersectEntities(
        selectedResources,
        resourcesForCurrentFilter
      );
    }
    const timeregs = timeRegistrationIndex.query({
      projects: selectedProjectIds,
      resources: resourcesForFilter,
      startTime,
      endTime,
    });
    // add the filter on resource type, if any filters for that has been set
    return timeregs.filter((tr) => {
      let result = true;
      if (selectedResourceTypeIds.length > 0) {
        const resource = storehelper.findById(
          resourcesForCurrentFilter,
          apihelper.getRelId(tr, "resource") as string
        );
        result =
          !resource ||
          selectedResourceTypeIds.includes(
            apihelper.getRelId(resource, "resourceType")
          );
      }
      return result;
    });
  }
);

export const resourcesInTimeregistrationsForCurrentFilter = createSelector(
  [timeRegistrationsForCurrentFilter, reducerResourceSelectors.allResources],
  (timeRegs, allResources) => {
    return storehelper.filterByRelatedEntity(
      allResources,
      timeRegs,
      "resource"
    );
  }
);

export const selectableResources = createSelector(
  [
    selectedResources,
    allocatableResourcesForCurrentFilter,
    resourcesInTimeregistrationsForCurrentFilter,
  ],
  (selectedResources, allocatableResources, resourcesForTregs) => {
    return storehelper
      .unionEntities(selectedResources, allocatableResources, resourcesForTregs)
      .sort(selectableResourceSort);
  }
);

export const selectedProjectsForTagPicker = createSelector(
  [selectedProjects],
  (projects) =>
    projects.map((project) => ({
      name: apihelper.getAttr(project, "name"),
      key:
        apihelper.getEntityType(project) + "-" + apihelper.getEntityId(project),
      type: apihelper.getEntityType(project),
      id: apihelper.getEntityId(project),
    }))
);

export const stateMergeForCurrentFilter = createSelector(
  [
    writeBackQueueReducerSelectors.queue,
    timeRegistrationsForCurrentFilter,
    apiCallReducerSelectors.liveTimeRegistrationWriteRequests,
  ],
  (queue, storeRegistrations, liveRegistrations) => {
    return new StateMerge(storeRegistrations, liveRegistrations, queue);
  }
);

export const contractRolesForCurrentFilter = createSelector(
  [projectResourcesForCurrentFilter, contractRoleSelectors.allContractRoles],
  (projectResources, allContractRoles) =>
    contractRoleSelectors.filterByProjectResources(
      allContractRoles,
      projectResources
    )
);

export const hourSetForTimeRegistrationList = createSelector(
  [
    allProjectsForAccount,
    appDomainSelectors.allClientsForSelectedAccount,
    selectableResources,
    contractSelectors.allContracts,
    stateMergeForCurrentFilter,
    projectResourcesForCurrentFilter,
    contractRolesForCurrentFilter,
  ],
  (
    projects,
    clients,
    resources,
    contracts,
    stateMerge,
    projectResources,
    contractRoles
  ) =>
    new HourSet(
      stateMerge,
      projects,
      resources,
      projectResources,
      clients,
      contracts,
      contractRoles
    )
);
