/* tslint:disable:no-unused-variable */
import React, { Component } from "react";
import "../../lib/setOperations";
import DetailsListBasic from "./DetailsListBasic";
import { sortItemsAscending } from "../../selectors/storehelper";
import { IColumn, IGroup } from "@fluentui/react";
import {
  ColumnSorterCollection,
  SortColumnState,
  SortDefinition,
} from "./types";

type SorterListProps = {
  items: any[];
  columns: IColumn[];
  columnSorters: ColumnSorterCollection;
  groups?: IGroup[];
  initialColumnSorterIndex?: string;
  onRenderItemColumn?: (
    item?: any,
    index?: number,
    column?: IColumn
  ) => React.ReactNode;
  selectable?: boolean;
};

type SorterListState = SortColumnState;

export default class SorterList extends Component<
  SorterListProps,
  SorterListState
> {
  constructor(props: SorterListProps) {
    super(props);
    this._onColumnClick = this._onColumnClick.bind(this);
    this.state = {
      sortedColumnIndex: undefined,
      sortedDescending: undefined,
    };
  }

  _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
    const { columnSorters, columns } = this.props;
    if (columnSorters[column.fieldName || ""] === undefined) {
      return;
    }
    this.setState({
      sortedDescending: column.isSorted ? !column.isSortedDescending : false,
      sortedColumnIndex: column.fieldName,
    });
  };

  // Finds the indicies in items for the deepest levels in the group object. These indicies divides items
  // into blocks, which can be sorted individually without destroying the group structure (this is done
  // in _sortInBlocks).
  _getBlocksFromGroups = (groups: IGroup[], lst = [0]) => {
    groups.forEach((group) => {
      if (group.children?.length == 0) {
        lst.push((lst.at(-1) as number) + group.count);
      } else {
        this._getBlocksFromGroups(group.children || [], lst);
      }
    });
    return lst;
  };

  _sortInBlocks = (
    items: any[],
    sortDef: SortDefinition<any>,
    groups: IGroup[],
    sortedDescending: boolean
  ) => {
    const blockDividers = this._getBlocksFromGroups(groups, [0]);
    const newItems: any[] = [];

    for (let i = 1; i < blockDividers.length; i++) {
      const sorted = sortItemsAscending(
        items.slice(blockDividers[i - 1], blockDividers[i]),
        sortDef
      );
      sortedDescending
        ? newItems.push(sorted.reverse())
        : newItems.push(sorted);
    }
    return newItems.flat();
  };

  static getDerivedStateFromProps(
    props: SorterListProps,
    state: SorterListState
  ) {
    const { initialColumnSorterIndex } = props;
    const { sortedColumnIndex } = state;

    if (sortedColumnIndex == undefined) {
      return { sortedColumnIndex: initialColumnSorterIndex };
    }
    return null;
  }

  render() {
    const { items, columns, columnSorters, groups, ...other } = this.props;
    const { sortedColumnIndex, sortedDescending } = this.state;

    const newCols = columns.map((c) =>
      Object.assign({ isSorted: false, onColumnClick: this._onColumnClick }, c)
    );
    let newItems = items.slice();

    if (sortedColumnIndex !== undefined) {
      const sortedColumn = newCols.find(
        (column) => column.fieldName === sortedColumnIndex
      );

      // we are sorting by some column and found that column
      if (sortedColumn) {
        sortedColumn.isSorted = true;
        sortedColumn.isSortedDescending = sortedDescending;
        // find the sort definition
        const columnSorter = columnSorters[sortedColumn.fieldName as string];

        if (groups) {
          // respect the groups possibly interweaved in the items
          newItems = this._sortInBlocks(
            newItems,
            columnSorter,
            groups,
            !!sortedDescending
          );
        } else {
          // no groups, just normal sorting
          const sorted = sortItemsAscending(newItems, columnSorter);
          newItems = sortedDescending ? sorted.reverse() : sorted;
        }
      }
    }
    return (
      <DetailsListBasic
        {...other}
        items={newItems}
        columns={newCols}
        groups={groups}
      />
    );
  }
}
