import React, { Component } from "react";
import { Label } from "@fluentui/react/lib/Label";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MonthPicker from "../../components/uifabricextensions/MonthPicker";
import TimeRegistrationForm from "../../components/forms/TimeRegistrationForm";
import Row from "../../components/grid/Row";
import Grid from "../../components/grid/Grid";
import Column from "../../components/grid/Column";
import HorizontalRule from "../../components/HorizontalRule";
import OutsideAlerter from "../../components/OutsideAlerter";
import ClientPicker from "../../components/uifabricextensions/ClientPicker";
import ProjectPicker from "../../components/uifabricextensions/ProjectPicker";
import BasePickerSimple from "../../components/uifabricextensions/BasePickerSimple";
import TimeRegistrationList from "../../components/lists/TimeRegistrationList";
import ByResourceTable from "../../components/timeRegistration/ByResourceTable";
import * as accountReducerSelectors from "../../selectors/reducers/account";
import { isLoggedIn } from "../../selectors/reducers/auth";
import * as resourceManagementSelectors from "../../selectors/pages/resourceManagement";
import * as clientSelectors from "../../selectors/reducers/client";
import * as apihelper from "../../selectors/apihelper";
import * as resourceManagementActions from "../../actions/pages/resourceManagement";
import * as billabilitySelectors from "../../selectors/reducers/billabilityTypes";
import * as currencySelectors from "../../selectors/reducers/currency";
import * as localeSelectors from "../../selectors/reducers/locale";
import * as absencePeriodStatusSelectors from "../../selectors/reducers/absencePeriodStatus";
import * as timeRegistrationStatusSelectors from "../../selectors/reducers/timeRegistrationStatus";
import ProcessingDialog from "../../components/ProcessingDialog";
import ResourceCheckboxDialog from "../../components/ResourceCheckboxDialog";
import * as permissionSelectors from "../../selectors/reducers/permission";
import * as appDomainSelectors from "../../selectors/app/domain";
import * as datehelper from "../../lib/date";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import StateMerge from "../../lib/stateMerge/StateMerge";
import LockedTimePeriodIndex from "../../lib/LockedTimePeriodIndex";
import { UserPermissionSet } from "../../lib/PermissionSet";
import HourSet from "../../lib/HourSet";
import { Notification } from "../../reducers/app/notification";
import { QueueChangeStatus } from "../../lib/ModelMergeQueue";

const lexicalInsensitiveNameSortFunc = function (
  a: ResourceObject,
  b: ResourceObject
) {
  return ((apihelper.getAttr(a, "name") as string) || "")
    .toLowerCase()
    .localeCompare(
      ((apihelper.getAttr(b, "name") as string) || "").toLowerCase()
    );
};

const SHOW_INACTIVE_RESOURCE_ID = "inactiveresources";

type ResourceManagementProps = {
  actions: {
    pageOpened: () => void;
    selectMonth: (date: Date) => void;
    selectClientId: (clientId: string) => void;
    selectAllClients: () => void;
    selectAllProjects: () => void;
    selectProjectId: (projectId: string) => void;
    selectResourceTypeIds: (
      resourceTypeIds: string[],
      showInactiveResources: boolean
    ) => void;
    selectCell: (cell: any) => void;
    clearSelectedCell: () => void;
    selectTimeRegistration: (timeRegistration: ResourceObject) => void;
    lockPeriods: (resources: ResourceObjects) => void;
    openPeriods: (resources: ResourceObjects) => void;
    updateViewDesc: (viewDescription: any) => void;
  };
  selectedResourceTypeIds: string[];
  showInactiveResources: boolean;
  selectedCell: any;
  stateMerge: StateMerge;
  selectedTimeRegistration: ResourceObject;
  contractRoles: ResourceObjects;
  projectResources: ResourceObjects;
  showProcessingDialog: boolean;
  allClients: ResourceObjects;
  currentUserAccount: ResourceObject;
  startTime: Date;
  isAllClientsSelected: boolean;
  isAllProjectsSelected: boolean;
  lockedTimePeriodIndex: LockedTimePeriodIndex;
  selectedProject: ResourceObject;
  selectedClient: ResourceObject;
  idForNotBillableType: string;
  allCurrency: ResourceObjects;
  absencePeriodStatuses: ResourceObjects;
  absenceRegistrationProjects: ResourceObjects;
  allTimeRegistrationStatus: ResourceObjects;
  viewDesc: any;
  isSelectedTimeRegistrationBillable: boolean;
  contracts: ResourceObjects;
  projects: ResourceObjects;
  locales: ResourceObjects;
  selectedResourceTypes: ResourceObjects;
  usersForCurrentAccount: ResourceObjects;
  absencePeriods: ResourceObjects;
  resourcesForCurrentFilter: ResourceObjects;
  resourceTypeOptions: ResourceObjects;
  currentUserPermissionSet: UserPermissionSet;
  contractsForSelectedTimeRegistration: ResourceObjects;
  contractsRoleForSelectedTimeRegistration: ResourceObjects;
  notificationsForSelectedTimeRegistration: Array<Notification>;
  hourSet: HourSet;
  currentAccount: ResourceObject;
  processingProgression: number;
  billabilityTypes: ResourceObjects;
};

type ResourceManagementState = {
  closeMonthWasClicked: boolean;
  openMonthWasClicked: boolean;
  cellUnderEdit: any;
};

class ResourceManagement extends Component<
  ResourceManagementProps,
  ResourceManagementState
> {
  constructor(props: ResourceManagementProps) {
    super(props);
    this.state = {
      cellUnderEdit: undefined,
      closeMonthWasClicked: false,
      openMonthWasClicked: false,
    };

    this._onSelectMonth = this._onSelectMonth.bind(this);
    this._onSelectProject = this._onSelectProject.bind(this);
    this._onSelectAllProjects = this._onSelectAllProjects.bind(this);
    this._onSelectClient = this._onSelectClient.bind(this);
    this._onSelectAllClients = this._onSelectAllClients.bind(this);
    this._onChangeResourceType = this._onChangeResourceType.bind(this);
    this._onClickCell = this._onClickCell.bind(this);
    this._selectTimeRegistrationFromList =
      this._selectTimeRegistrationFromList.bind(this);
    this._clearSelectedCell = this._clearSelectedCell.bind(this);
    this._onOutsideClick = this._onOutsideClick.bind(this);
    this._onViewDescChange = this._onViewDescChange.bind(this);
    this._closeMonth = this._closeMonth.bind(this);
    this._clickCloseMonth = this._clickCloseMonth.bind(this);
    this._openMonth = this._openMonth.bind(this);
    this._clickOpenMonth = this._clickOpenMonth.bind(this);
    this._closeDialog = this._closeDialog.bind(this);
    this._confirmSelection = this._confirmSelection.bind(this);
    this._getFieldStatus = this._getFieldStatus.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  _onSelectMonth(firstDayOfWeek: Date) {
    this.props.actions.selectMonth(firstDayOfWeek);
  }

  _onSelectClient(selectedClient: ResourceObject) {
    this.props.actions.selectClientId(
      apihelper.getEntityId(selectedClient) as string
    );
  }

  _onSelectAllClients() {
    this.props.actions.selectAllClients();
  }

  _onSelectAllProjects() {
    this.props.actions.selectAllProjects();
  }

  _onSelectProject(selectedProject: ResourceObject) {
    this.props.actions.selectProjectId(
      apihelper.getEntityId(selectedProject) as string
    );
  }

  _onChangeResourceType(selectedEntity: ResourceObject | null) {
    if (selectedEntity === null) {
      return;
    }

    const { selectedResourceTypeIds } = this.props;
    let { showInactiveResources } = this.props;
    let updatedSelectedResourceTypeIds: string[] = [];
    if (selectedEntity.id == SHOW_INACTIVE_RESOURCE_ID) {
      // SHOW_INACTIVE_RESOURCE_ID = "inactiveresources"
      showInactiveResources = !showInactiveResources;
      updatedSelectedResourceTypeIds = selectedResourceTypeIds;
    } else if (
      selectedResourceTypeIds.includes(
        apihelper.getEntityId(selectedEntity) as string
      )
    ) {
      //If we press a already selected resourcetype
      updatedSelectedResourceTypeIds = selectedResourceTypeIds.filter(
        (id) => !apihelper.entityHasId(selectedEntity, id)
      ); //Filter selectedresourcetypesID based on the ID of the chosen resourcetype. That is; retain only that ID
    } else {
      updatedSelectedResourceTypeIds = [
        ...selectedResourceTypeIds,
        apihelper.getEntityId(selectedEntity) as string,
      ]; //Else add the chosen Id to selectedresourcetypeID
    }
    this.props.actions.selectResourceTypeIds(
      updatedSelectedResourceTypeIds,
      showInactiveResources
    ); //write the new SelectedResourceTypesToThe state filter
  }

  _onClickCell(cellData: any) {
    this.props.actions.selectCell(cellData);
  }

  _clearSelectedCell() {
    this.props.selectedCell != null && this.props.actions.clearSelectedCell();
  }

  _selectTimeRegistrationFromList(timeRegistrations: ResourceObjects) {
    const timeRegistration = timeRegistrations[0] || null;
    this.props.actions.selectTimeRegistration(timeRegistration);
  }

  _onOutsideClick(event: MouseEvent) {
    function hasSomeParentTheClass(element: any, classname: string): boolean {
      if ((element.className || "").split(" ").indexOf(classname) >= 0)
        return true;
      return (
        element.parentNode &&
        hasSomeParentTheClass(element.parentNode, classname)
      );
    }

    // check it's not the panels being opened
    const isMsCallout = hasSomeParentTheClass(event.target, "ms-Layer");
    if (!isMsCallout) {
      this._clearSelectedCell();
    }
  }

  _clickCloseMonth() {
    this.setState({ closeMonthWasClicked: true });
  }

  _clickOpenMonth() {
    this.setState({ openMonthWasClicked: true });
  }

  _closeDialog() {
    this.setState({ closeMonthWasClicked: false, openMonthWasClicked: false });
  }

  _confirmSelection(resources: ResourceObjects) {
    const { closeMonthWasClicked, openMonthWasClicked } = this.state;
    if (closeMonthWasClicked) {
      this._closeMonth(resources);
    }
    if (openMonthWasClicked) {
      this._openMonth(resources);
    }
  }

  _closeMonth(resources: ResourceObjects) {
    this.props.actions.lockPeriods(resources);
    this.setState({ closeMonthWasClicked: false });
  }

  _openMonth(resources: ResourceObjects) {
    this.props.actions.openPeriods(resources);
    this.setState({ openMonthWasClicked: false });
  }

  _onViewDescChange(viewDesc: any) {
    this.props.actions.updateViewDesc(viewDesc);
  }

  _getFieldStatus(model: ResourceObject, fieldPath: string): QueueChangeStatus {
    return this.props.stateMerge.getFieldStatus(
      model,
      fieldPath
    ) as QueueChangeStatus;
  }

  shouldComponentUpdate(
    nextProps: ResourceManagementProps,
    nextState: ResourceManagementState
  ) {
    return (
      this.props.stateMerge !== nextProps.stateMerge ||
      this.props.selectedCell !== nextProps.selectedCell ||
      this.props.selectedTimeRegistration !==
        nextProps.selectedTimeRegistration ||
      this.props.contractRoles !== nextProps.contractRoles ||
      this.props.projectResources !== nextProps.projectResources ||
      this.props.showProcessingDialog !== nextProps.showProcessingDialog ||
      this.props.processingProgression !== nextProps.processingProgression ||
      this.state.closeMonthWasClicked !== nextState.closeMonthWasClicked ||
      this.state.openMonthWasClicked !== nextState.openMonthWasClicked
    );
  }

  render() {
    const {
      selectedTimeRegistration,
      allClients,
      stateMerge,
      startTime,
      isAllClientsSelected,
      isAllProjectsSelected,
      lockedTimePeriodIndex,
      selectedCell,
      selectedProject,
      selectedClient,
      projectResources,
      idForNotBillableType,
      allCurrency,
      absencePeriodStatuses,
      absenceRegistrationProjects,
      allTimeRegistrationStatus,
      showProcessingDialog,
      contracts,
      projects,
      locales,
      selectedResourceTypes,
      usersForCurrentAccount,
      absencePeriods,
      resourcesForCurrentFilter,
      resourceTypeOptions,
      currentUserPermissionSet,
      contractsForSelectedTimeRegistration,
      contractsRoleForSelectedTimeRegistration,
      notificationsForSelectedTimeRegistration,
      billabilityTypes,
      processingProgression,
    } = this.props;
    const { closeMonthWasClicked, openMonthWasClicked } = this.state;

    const hasSelection = !!selectedCell;

    const isUnderEdit = function (row: any, date: any) {
      return (
        hasSelection &&
        ((!row && !selectedCell.resource) ||
          (row &&
            selectedCell.resource &&
            apihelper.entityHasId(selectedCell.resource, row.id))) &&
        ((!selectedCell.date && !date) ||
          (date &&
            selectedCell.date &&
            datehelper.isSameDay(date.dateObj, selectedCell.date)))
      );
    };

    const allProjects = projects.slice().sort(lexicalInsensitiveNameSortFunc);

    const users = usersForCurrentAccount;

    const detailsListTregs = selectedCell ? selectedCell.timeRegistrations : [];

    const tregHeaderText = hasSelection
      ? (selectedCell.date
          ? datehelper.toMonth(selectedCell.date)
          : selectedCell.resource
          ? apihelper.getAttr(selectedCell.resource, "name")
          : startTime
          ? datehelper.toMonth(startTime)
          : "") +
        ", " +
        detailsListTregs.length +
        " registration" +
        (detailsListTregs.length !== 1 ? "s" : "")
      : "";

    let titleText = "";
    if (selectedTimeRegistration) {
      const proj = allProjects.find((p) =>
        apihelper.relRefersToEntity(selectedTimeRegistration, "project", p)
      );
      const timeRegStartTime = new Date(
        apihelper.getAttr(selectedTimeRegistration, "startTime") as string
      );
      titleText =
        (proj ? apihelper.getAttr(proj, "name") + ", " : "") +
        `${datehelper.toWeekday(timeRegStartTime)}, ${datehelper.toMonth(
          timeRegStartTime
        )} ${datehelper.toOrdinalDayOfMonth(timeRegStartTime)}`;
    }

    return (
      <div className="novatime-page-resourcemanagement">
        <div className="novatime-container">
          <ProcessingDialog
            title="Please wait"
            body="Handling the request"
            hidden={!showProcessingDialog}
            progress={processingProgression}
          />

          <ResourceCheckboxDialog
            title={`Confirm resources to ${
              openMonthWasClicked ? "open" : "close"
            }`}
            resources={resourcesForCurrentFilter}
            hidden={!closeMonthWasClicked && !openMonthWasClicked}
            onDismiss={this._closeDialog}
            onConfirm={this._confirmSelection}
          />

          <HorizontalRule text="Select" alignment="left" />

          <Grid>
            <Row>
              <Column sm={12} lg={6} xl={3}>
                <Label>Month</Label>
                <MonthPicker
                  value={startTime}
                  onSelectMonth={this._onSelectMonth}
                />
                <div className="height2em hiddenLgUp" />
              </Column>
              <Column sm={12} lg={6} xl={3}>
                <ClientPicker
                  clientEntities={allClients}
                  isSelectAllAnOption={true}
                  isAllClientsSelected={isAllClientsSelected}
                  selectedClient={selectedClient}
                  onClientSelected={this._onSelectClient}
                  onAllClientsSelected={this._onSelectAllClients}
                />
                <div className="height2em hiddenLgUp" />
              </Column>
              <Column sm={12} lg={6} xl={3}>
                <ProjectPicker
                  projectEntities={allProjects}
                  isSelectAllAnOption={true}
                  isAllProjectsSelected={isAllProjectsSelected}
                  selectedProject={selectedProject}
                  onProjectSelected={this._onSelectProject}
                  onAllProjectsSelected={this._onSelectAllProjects}
                />
                <div className="height2em hiddenLgUp" />
              </Column>
              <Column sm={12} lg={6} xl={3}>
                <BasePickerSimple
                  label={"Resources"}
                  placeholder={"Select resource type"}
                  selectedEntities={selectedResourceTypes}
                  entities={resourceTypeOptions}
                  multiSelect={true}
                  onEntitySelected={this._onChangeResourceType}
                  nameFunction={(e: ResourceObject) =>
                    apihelper.getAttr(e, "name") as string
                  }
                  idFunction={(e: ResourceObject) =>
                    apihelper.getEntityId(e) as string
                  }
                />
              </Column>
            </Row>
          </Grid>
          <HorizontalRule text="Calendar" alignment="left" />
          {currentUserPermissionSet.hasSelectedAccountWrite() && (
            <div className="novatime-resourcemanagement-buttons">
              <div className="novatime-resourcemanagement-close-button">
                <DefaultButton
                  onClick={this._clickCloseMonth}
                  text={"Close month"}
                  disabled={false}
                />
              </div>
              <div className="novatime-resourcemanagement-open-button">
                <DefaultButton
                  onClick={this._clickOpenMonth}
                  text={"Open month"}
                  disabled={false}
                />
              </div>
            </div>
          )}
        </div>
        <OutsideAlerter onOutsideClick={this._onOutsideClick}>
          <div className="novatime-centered">
            <ByResourceTable
              isCellUnderEdit={isUnderEdit}
              firstDayOfMonth={startTime}
              onCellClick={this._onClickCell}
              clients={allClients}
              projects={projects}
              contracts={contracts}
              resources={resourcesForCurrentFilter}
              stateMerge={stateMerge}
              lockedTimePeriodIndex={lockedTimePeriodIndex}
              absencePeriods={absencePeriods}
              currencies={allCurrency}
              absencePeriodStatuses={absencePeriodStatuses}
              hourSet={this.props.hourSet}
              locales={locales}
              account={this.props.currentAccount}
              absenceRegistrationProjects={absenceRegistrationProjects}
              billabilityTypes={this.props.billabilityTypes}
            />
          </div>
          <div className="novatime-container">
            <div className="height4em hiddenLgUp" />
            {detailsListTregs.length > 0 && (
              <HorizontalRule text={tregHeaderText} alignment="left" />
            )}
            <TimeRegistrationList
              hourSet={this.props.hourSet}
              omitStatusColumn={true}
              timeRegistrations={detailsListTregs}
              selectedTimeRegistration={selectedTimeRegistration}
              onSelect={this._selectTimeRegistrationFromList}
            />
            <div className="height4em hiddenLgUp" />
            {selectedTimeRegistration && (
              <HorizontalRule text={titleText} alignment="left" />
            )}
            {selectedTimeRegistration ? (
              <TimeRegistrationForm
                model={selectedTimeRegistration}
                projects={allProjects}
                contractRoles={contractsRoleForSelectedTimeRegistration}
                contracts={contractsForSelectedTimeRegistration}
                projectResources={projectResources}
                users={users}
                notifications={notificationsForSelectedTimeRegistration}
                currentUserPermissionSet={currentUserPermissionSet}
                autoShowDetails={true}
                canDelete={false}
                canEdit={false}
                allTimeRegistrationStatus={allTimeRegistrationStatus}
                allBillabilityTypes={billabilityTypes}
                idForNotBillableType={idForNotBillableType}
                fieldStatus={this._getFieldStatus}
              />
            ) : undefined}
          </div>
        </OutsideAlerter>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const selectedProjectId =
    resourceManagementSelectors.selectedProjectId(state);
  const selectedProject = resourceManagementSelectors.selectedProject(state);
  const selectedClientId = resourceManagementSelectors.selectedClientId(state);
  const selectedClient = resourceManagementSelectors.selectedClient(state);
  const isAllClientsSelected =
    resourceManagementSelectors.isAllClientsSelected(state);
  const projects = resourceManagementSelectors.selectedProjects(state);
  const contracts =
    resourceManagementSelectors.contractsBySelectedProjects(state);
  const selectedResourceTypeIds =
    resourceManagementSelectors.selectedResourceTypeIdsEmployees(state);
  const selectedResourceTypes =
    resourceManagementSelectors.selectedResourceTypes(state);
  const resourceTypeOptions =
    resourceManagementSelectors.resourceTypeOptions(state);
  const startDate = resourceManagementSelectors.startTime(state);
  const endDate = resourceManagementSelectors.endTime(state);
  const viewDesc = resourceManagementSelectors.viewDesc(state);
  const showProcessingDialog =
    resourceManagementSelectors.showProcessingDialog(state);
  const selectedTimeRegistration =
    resourceManagementSelectors.selectedTimeRegistration(state);
  const projectResources =
    resourceManagementSelectors.selectedProjectResources(state);
  const contractRoles =
    resourceManagementSelectors.contractRolesBySelectedProjectResources(state);
  const lockedTimePeriodIndex =
    resourceManagementSelectors.lockedTimePeriodIndex(state);
  const resourcesForCurrentFilter =
    resourceManagementSelectors.resourcesForCurrentFilter(state); // Ugly and slow.
  const hourSet =
    resourceManagementSelectors.hourSetForSelectedTimeRegistrations(state);
  const idForNotBillableType = billabilitySelectors.idForNotBillableType(state);
  const isSelectedTimeRegistrationBillable =
    resourceManagementSelectors.isSelectedTimeRegistrationBillable(state);
  const allClients =
    clientSelectors.allClientsByCurrentUserAccountSortedByName(state);
  const locales = localeSelectors.allLocale(state);
  const currentAccount = appDomainSelectors.selectedAccount(state);
  const usersForCurrentAccount =
    appDomainSelectors.usersForCurrentAccount(state);
  const absencePeriodStatuses =
    absencePeriodStatusSelectors.allAbsencePeriodStatus(state);
  const billabilityTypes = billabilitySelectors.allBillabilityTypes(state);
  const currentUserAccount = accountReducerSelectors.currentUserAccount(state);
  const absencePeriods =
    resourceManagementSelectors.absencePeriodsInPeriod(state);
  const absenceRegistrationProjects =
    appDomainSelectors.allAbsenceRegistrationProjectsForSelectedAccount(state);
  const contractsForSelectedTimeRegistration =
    resourceManagementSelectors.contractForSelectedTimeRegistration(state);
  const contractsRoleForSelectedTimeRegistration =
    resourceManagementSelectors.contractRoleForSelectedTimeRegistration(state);
  const notificationsForSelectedTimeRegistration =
    resourceManagementSelectors.notificationsForSelectedTimeRegistration(state);

  return {
    resourcesForCurrentFilter,
    isLoggedIn: isLoggedIn(state),
    currentUserAccount,
    usersForCurrentAccount,
    selectedClientId,
    isAllClientsSelected,
    absenceRegistrationProjects,
    selectedProjectId,
    isAllProjectsSelected:
      resourceManagementSelectors.isAllProjectsSelected(state),
    selectedResourceTypeIds,
    startTime: startDate,
    endTime: endDate,
    selectedTimeRegistration,
    lockedTimePeriodIndex,
    selectedCell: resourceManagementSelectors.selectedCell(state),
    stateMerge: resourceManagementSelectors.stateMerge(state),
    showInactiveResources:
      resourceManagementSelectors.showInactiveResources(state),
    absencePeriods,
    selectedProject,
    selectedClient,
    projectResources,
    contractRoles,
    idForNotBillableType,
    allCurrency: currencySelectors.allCurrency(state),
    absencePeriodStatuses,
    allTimeRegistrationStatus:
      timeRegistrationStatusSelectors.allTimeRegistrationStatus(state),
    viewDesc,
    showProcessingDialog,
    currentUserPermissionSet:
      permissionSelectors.currentUserPermissionSet(state),
    isSelectedTimeRegistrationBillable,
    allClients,
    contracts,
    projects,
    locales,
    selectedResourceTypes,
    resourceTypeOptions,
    hourSet,
    billabilityTypes,
    currentAccount,
    contractsForSelectedTimeRegistration,
    contractsRoleForSelectedTimeRegistration,
    notificationsForSelectedTimeRegistration,
    processingProgression:
      resourceManagementSelectors.processingProgression(state),
  };
}

function mapDispatch(dispatch: any) {
  const actions = Object.assign({}, resourceManagementActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(ResourceManagement as any);
