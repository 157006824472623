import React, { Component, LegacyRef } from "react";
import * as Icons from "../../constants/Icons";
import { Label } from "@fluentui/react/lib/Label";
import PropTypes from "prop-types";
import { IconButton } from "@fluentui/react/lib/Button";
import { Calendar, ICalendarStrings } from "@fluentui/react/lib/Calendar";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Callout, Target } from "@fluentui/react/lib/Callout";
import * as datehelper from "../../lib/date";

const DayPickerStrings: ICalendarStrings = {
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],

  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],

  shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],

  goToToday: "This month",
  prevYearAriaLabel: "Go to previous year",
  nextYearAriaLabel: "Go to next year",
};

type MonthPickerProps = {
  onSelectMonth: (date: Date) => void;
  value: Date;
  label?: string;
};

type MonthPickerState = {
  showCalendar: boolean;
};

export default class MonthPicker extends Component<
  MonthPickerProps,
  MonthPickerState
> {
  private buttonRef: React.RefObject<unknown>;

  constructor(props: MonthPickerProps) {
    super(props);
    this._onSelectDate = this._onSelectDate.bind(this);
    this._formatDate = this._formatDate.bind(this);
    this._onLeftClick = this._onLeftClick.bind(this);
    this._onRightClick = this._onRightClick.bind(this);
    this._toggleShowCalendar = this._toggleShowCalendar.bind(this);

    this.state = { showCalendar: false };
    this.buttonRef = React.createRef();
  }

  _toggleShowCalendar() {
    this.setState({ showCalendar: !this.state.showCalendar });
  }

  _onSelectDate(date: Date, selectedDateRangeArray?: Date[]) {
    if (this.state.showCalendar) {
      this.setState({ showCalendar: false });
    }
    if (this.props.onSelectMonth) {
      this.props.onSelectMonth(datehelper.startOfMonth(date));
    }
  }

  _formatDate(date: Date) {
    return `${datehelper.toMonth(date)} ${date.getFullYear()}`;
  }

  _onLeftClick() {
    if (this.props.onSelectMonth && this.props.value) {
      this.props.onSelectMonth(datehelper.subtractMonth(this.props.value, 1));
    }
  }

  _onRightClick() {
    if (this.props.onSelectMonth && this.props.value) {
      this.props.onSelectMonth(datehelper.addMonth(this.props.value, 1));
    }
  }

  render() {
    const { label, value } = this.props;

    return (
      <div className="novatime-date-picker">
        {label ? <Label>{label}</Label> : undefined}
        <div className="clearfix">
          <div className="novatime-datepicker-left">
            <IconButton
              iconProps={{ iconName: Icons.ICON_GO_LEFT }}
              onClick={this._onLeftClick}
            />
          </div>
          <div className="novatime-datepicker-right">
            <IconButton
              iconProps={{ iconName: Icons.ICON_GO_RIGHT }}
              onClick={this._onRightClick}
            />
          </div>
          <div
            className="novatime-datepicker-center"
            ref={this.buttonRef as LegacyRef<HTMLDivElement>}
          >
            <DefaultButton
              onClick={this._toggleShowCalendar}
              text={value ? this._formatDate(value) : ""}
              style={{ width: "100%" }}
            />
            {this.state.showCalendar && (
              <Callout
                isBeakVisible={false}
                onDismiss={this._toggleShowCalendar}
                target={this.buttonRef as Target}
                setInitialFocus
              >
                <Calendar
                  highlightSelectedMonth={true}
                  value={value ? value : undefined}
                  onSelectDate={this._onSelectDate}
                  isMonthPickerVisible={true}
                  isDayPickerVisible={false}
                  strings={DayPickerStrings}
                />
              </Callout>
            )}
          </div>
        </div>
      </div>
    );
  }
}
