/* tslint:disable:no-unused-variable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { v1 as uuidv1 } from "uuid";
import {
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsGroupRenderProps,
  IDetailsRowProps,
  IGroup,
  IObjectWithKey,
  ISelection,
  Selection,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import "../../lib/setOperations";
import { IRenderFunction } from "@fluentui/react";

type DetailsListBasicProps = {
  multiselect?: boolean;
  selectable?: boolean;
  columns: IColumn[];
  items: any[];
  className?: string;
  onSelect?: (selection: IObjectWithKey[]) => void;
  isItemSelected?: (item: any) => boolean;
  setKey?: string;
  onRenderItemColumn?: (
    item?: any,
    index?: number,
    column?: IColumn
  ) => React.ReactNode;
  onRenderRow?: IRenderFunction<IDetailsRowProps>;
  groups?: IGroup[];
  groupProps?: IDetailsGroupRenderProps;
};

type DetailsListBasicState = {
  selection: ISelection;
  selectedItems: any[];
};

export default class DetailsListBasic extends Component<
  DetailsListBasicProps,
  DetailsListBasicState
> {
  constructor(props: DetailsListBasicProps) {
    super(props);
    const { multiselect = false, selectable = true } = this.props;

    this._onSelectionChanged = this._onSelectionChanged.bind(this);
    const selectionMode = selectable
      ? multiselect
        ? SelectionMode.multiple
        : SelectionMode.single
      : SelectionMode.none;
    this.state = {
      selection: new Selection({
        onSelectionChanged: this._onSelectionChanged,
        selectionMode: selectionMode,
      }),
      selectedItems: [],
    };
  }

  _onSelectionChanged() {
    if (this.props.onSelect) {
      if (
        !this._hasControlledSelection() ||
        !this.state.selectedItems.equals(this.state.selection.getSelection())
      ) {
        this.props.onSelect(this.state.selection.getSelection());
      }
    }
  }

  _hasControlledSelection() {
    return !!this.props.isItemSelected;
  }

  // because the selection object in UI fabric is kind of crap and we use a function to mark which records are
  // selected, we need all this stuff at exactly this hook.
  static getDerivedStateFromProps(
    props: DetailsListBasicProps,
    state: DetailsListBasicState
  ) {
    const { items, isItemSelected } = props;
    const newState: DetailsListBasicState = {
      selection: state.selection,
      selectedItems: [],
    };
    const hasControlledSelection = !!isItemSelected;

    if (items) {
      let didChange = false;
      if (items !== state.selection.getItems()) {
        state.selection.setChangeEvents(false);
        state.selection.setItems(items, false);
        state.selection.setChangeEvents(true, true);
        didChange = true;
      }

      if ((didChange || items.length > 0) && hasControlledSelection) {
        const selectedItems = items.filter(
          isItemSelected as (item: any) => boolean
        );
        const selectedItemIndices: number[] = selectedItems.map((item) =>
          items.indexOf(item)
        );

        // if the UI fabric list's current state for selection doesn't match (i.g. selection wasn't changed
        // through a click), change it manually
        if (
          !selectedItemIndices.every(
            state.selection.isIndexSelected,
            state.selection
          ) ||
          state.selection.getSelectedCount() !== selectedItemIndices.length
        ) {
          state.selection.setChangeEvents(false);
          state.selection.setAllSelected(false);
          selectedItemIndices.forEach((idx) =>
            state.selection.setIndexSelected(idx, true, false)
          );
          state.selection.setChangeEvents(true, true);
        }

        newState.selectedItems = selectedItems;
        didChange = true;
      }
      if (didChange) {
        return newState;
      }
    }

    return null;
  }

  render() {
    const {
      items,
      columns,
      multiselect = false,
      setKey,
      ...other
    } = this.props;

    return (
      <DetailsList
        {...other}
        items={items}
        columns={columns}
        selectionMode={
          multiselect ? SelectionMode.multiple : SelectionMode.single
        }
        layoutMode={DetailsListLayoutMode.fixedColumns}
        selection={this.state.selection}
        selectionPreservedOnEmptyClick={true}
        ariaLabelForSelectionColumn="Toggle selection"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
        setKey={"static" || setKey || uuidv1()}
      />
    );
  }
}
