import BaseConnector from "./Base";

export default class TimeRegistrationConnector extends BaseConnector {
  getAll(
    {
      resourceIds,
      projectIds,
      startTime,
      endTime,
    }: {
      resourceIds?: string[] | null;
      projectIds?: string[] | null;
      startTime?: Date;
      endTime?: Date;
    } = {},
    includes?: string[]
  ) {
    const q: string[] = [];
    if (resourceIds && resourceIds.length > 0) {
      q.push(`resource.id in (${resourceIds.join(",")})`);
    }
    if (projectIds && projectIds.length > 0) {
      q.push(`project.id in (${projectIds.join(",")})`);
    }
    if (startTime) {
      q.push("startTime gt " + startTime.toISOString());
    }
    if (endTime) {
      q.push("endTime lt " + endTime.toISOString());
    }
    const qs = q.join(" and ");

    includes = includes || [];
    const is = includes.join(",");

    const qp: string[] = [];
    is && qp.push("includes=" + is);
    qs && qp.push("filter=" + qs);

    return this.authFetch(
      this.baseApiUrl + "TimeRegistrations/?" + qp.join("&")
    );
  }

  createTimeRegistration(timeRegistrationDto: object) {
    return this.authPost(
      this.baseApiUrl + "TimeRegistrations",
      timeRegistrationDto
    );
  }

  updateTimeRegistration(
    timeRegistrationId: string,
    timeRegistrationDto: object
  ) {
    return this.authPatch(
      this.baseApiUrl + "TimeRegistrations/" + timeRegistrationId,
      timeRegistrationDto
    );
  }

  deleteTimeRegistration(id: string) {
    return this.authDelete(this.baseApiUrl + "TimeRegistrations/" + id);
  }
}
