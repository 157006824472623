import * as types from "../../../constants/ActionTypes";
import { takeLatest, put, delay } from "redux-saga/effects";
import { awaitCalls } from "../../helpers";
import { lockPeriodsProcessingProgress } from "../../../actions/pages/resourceManagement";

function* doOpenPeriods(action) {
  yield put({ type: types.APP_DOMAIN_OPEN_PERIODS_STARTED });

  let listOfLockedTimePeriods =
    action.payload && action.payload.listOfLockedTimePeriods
      ? action.payload.listOfLockedTimePeriods
      : [];

  let listOfPuts = listOfLockedTimePeriods.map((model) => ({
    type: types.API_LOCKEDTIMEPERIOD_DELETE_INIT,
    payload: { model },
  }));

  let numProcessed = 0;
  while (numProcessed < listOfPuts.length) {
    const slice = listOfPuts.slice(numProcessed, numProcessed + 3);
    yield awaitCalls(slice);
    numProcessed += slice.length;
    yield put(
      lockPeriodsProcessingProgress(
        Math.round((numProcessed / listOfPuts.length) * 100) / 100
      )
    );
    yield delay(100);
  }

  yield put({
    type: types.APP_DOMAIN_OPEN_PERIODS_COMPLETED,
    payload: {
      transactionId: action.payload ? action.payload.transactionId : null,
    },
  });
}

export default function* lockPeriod() {
  yield takeLatest(types.APP_DOMAIN_OPEN_PERIODS_INIT, doOpenPeriods);
}
