import React, { useCallback } from "react";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import HourSet from "../../lib/HourSet";
import * as apihelper from "../../selectors/apihelper";
import * as timeRegistrationsHelper from "../../lib/timeRegistration";
import * as datehelper from "../../lib/date";
import BillableSet from "../../lib/BillableSet";
import * as billabilityTypes from "../../constants/BillabilityTypes";
import * as ExcelJs from "exceljs";
import { ActionButton } from "@fluentui/react";
import * as icons from "../../constants/Icons";

const attrOrEmpty = (o: ResourceObject | undefined, field: string): string =>
  (apihelper.getAttr(o as ResourceObject, field) as string) || "";

const idOrEmpty = (o: ResourceObject | undefined): string =>
  apihelper.getEntityId(o) || "";

const relIdOrEmpty = (o: ResourceObject, relField: string): string =>
  apihelper.getRelId(o, relField) || "";

function toRow(
  timeReg: ResourceObject,
  hourSet: HourSet,
  billableSet: BillableSet
) {
  const project = hourSet.getProjectForTimeRegistration(timeReg);
  const resource = hourSet.getResourceForTimeRegistration(timeReg);
  const contractRole = hourSet.getContractRoleForTimeRegistration(timeReg);
  const contract = hourSet.getContractForProject(project);
  const client = hourSet.getClientForProject(project);

  const clientString = attrOrEmpty(client, "name");
  const clientId = idOrEmpty(client);
  const projectString = attrOrEmpty(project, "name");
  const projectId = idOrEmpty(project);
  const contractString = attrOrEmpty(contract, "title");
  const contractId = idOrEmpty(contract);
  const contractRoleString = attrOrEmpty(contractRole, "name");
  const contractRoleId = idOrEmpty(contractRole);
  const resourceString = attrOrEmpty(resource, "name");
  const resourceId = idOrEmpty(resource);
  const timeregId = idOrEmpty(timeReg);
  const timeregAccountId = relIdOrEmpty(timeReg, "account");
  const timeregCreatedByUserId = relIdOrEmpty(timeReg, "createdByUser");
  const timeregStatusId = relIdOrEmpty(timeReg, "timeRegistrationStatus");
  const timeRegDuration = timeRegistrationsHelper.getDuration(timeReg);
  const timeregStartTime = datehelper.toYyyyMmDdHhMmSs(
    new Date(attrOrEmpty(timeReg, "startTime"))
  );
  const timeregEndTime = datehelper.toYyyyMmDdHhMmSs(
    new Date(attrOrEmpty(timeReg, "endTime"))
  );
  const timeregAddedAt = datehelper.toYyyyMmDdHhMmSs(
    new Date(attrOrEmpty(timeReg, "addedDate"))
  );
  const timeregModifiedAt = datehelper.toYyyyMmDdHhMmSs(
    new Date(attrOrEmpty(timeReg, "modifiedDate"))
  );
  const description = attrOrEmpty(timeReg, "description");
  const isoWeekNumber = datehelper.toIso8601WeekNumber(
    new Date(timeregStartTime)
  );

  const timeRegIsBillable =
    apihelper.getAttr(timeReg, "isBillable") !== null
      ? apihelper.getAttr(timeReg, "isBillable")
      : "";
  const contractRoleBillabilityType =
    contractRole &&
    billableSet.getBillableTypeNameFromId(
      apihelper.getRelId(contractRole, "billabilityType")
    );
  const isBillable =
    timeRegIsBillable &&
    contractRoleBillabilityType &&
    contractRoleBillabilityType !== billabilityTypes.NOT_BILLABLE;

  const resultArray = [
    clientString,
    clientId,
    projectString,
    projectId,
    contractString,
    contractId,
    contractRoleString,
    contractRoleId,
    resourceString,
    resourceId,
    timeregId,
    timeregAccountId,
    timeregCreatedByUserId,
    timeregStatusId,
    timeRegDuration,
    timeregStartTime,
    timeregEndTime,
    timeregAddedAt,
    timeregModifiedAt,
    description,
    isoWeekNumber,
  ];

  const hasTimeregBillabilityAccess = typeof timeRegIsBillable === "boolean";
  const hasContractRoleAccess = !!contractRoleBillabilityType;
  if (hasTimeregBillabilityAccess || hasContractRoleAccess) {
    resultArray.push(
      hasContractRoleAccess ? (contractRoleBillabilityType as string) : ""
    );
    resultArray.push(
      hasTimeregBillabilityAccess
        ? (timeRegIsBillable as unknown as string)
        : ""
    );
    resultArray.push(
      typeof contractRoleBillabilityType !== "undefined"
        ? (isBillable as string)
        : ""
    );
  }

  return resultArray;
}

const headersBase = [
  "Client",
  "ClientId",
  "Project",
  "ProjectId",
  "Contract",
  "ContractId",
  "ContractRole",
  "ContractRoleId",
  "Resource",
  "ResourceId",
  "TimeRegId",
  "TimeRegAccountId",
  "TimeRegCreatedByUserId",
  "TimeRegStatusId",
  "TimeRegDuration",
  "TimeRegStartTime",
  "TimeRegEndTime",
  "TimeRegAddedAt",
  "TimeRegModifiedAt",
  "Description",
  "IsoWeekNumber",
];

const headersExtended = [
  "contractRoleBillabilityType",
  "timeRegIsBillable",
  "isBillable",
];

type ExcelExportProps = {
  timeRegistrations: ResourceObjects;
  hourSet: HourSet;
  billableSet: BillableSet;
  disabled: boolean;
};

export default function ExcelExport({
  timeRegistrations,
  hourSet,
  billableSet,
  disabled,
}: ExcelExportProps) {
  const onClick = useCallback(() => {
    // prepare export data
    const rows = timeRegistrations.map((tr) => toRow(tr, hourSet, billableSet));
    // find the row with the most columns
    const maxLengthRow = rows.reduce(
      (acc, cur) => Math.max(acc, cur.length),
      0
    );

    let headers = headersBase.slice();
    // deduce from data returned from api, if user has access to billability data
    if (maxLengthRow > headers.length) {
      headers = headers.concat(headersExtended);
    }

    // setup excel file
    const workbook = new ExcelJs.Workbook();
    workbook.creator = "NovaTime";
    workbook.lastModifiedBy = "NovaTime";
    workbook.created = new Date();
    workbook.modified = new Date();

    // add sheet with time reg table
    const timeRegSheet = workbook.addWorksheet("TimeRegistrations");
    timeRegSheet.addTable({
      name: "TimeRegistrations",
      ref: "A1",
      headerRow: true,
      columns: headers.map((headerName) => ({
        name: headerName,
        filterButton: true,
      })),
      rows,
    });

    // send file via browser link
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download =
          "timeRegistrations-" +
          datehelper.toYyyyMmDdHhMmSs(new Date()) +
          ".xlsx";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch((e) => {
        console.warn("failed to get buffer for exporting xlsx", e);
      });
  }, [timeRegistrations, hourSet, billableSet]);

  return (
    <div className="novatime-excelexport-button" style={{ marginTop: "25px" }}>
      <ActionButton
        iconProps={{
          iconName: icons.ICON_NAME_EXCELEXPORT,
          style: {
            backgroundColor: "white",
            color: disabled ? "grey" : "green",
          },
        }}
        onClick={onClick}
        title={"Export time registrations to Excel"}
        disabled={disabled}
      >
        Export
      </ActionButton>
    </div>
  );
}
