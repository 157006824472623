import React, { useCallback, useMemo, useState } from "react";
import { IPickerItemProps, ITag, TagPicker } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import {
  ClientProjectPicker,
  ClientProjectPickerProps,
  GetEntityParentFunction,
} from "./ClientAndProjectPicker";
import { Icon } from "@fluentui/react/lib/Icon";
import * as apihelper from "../../selectors/apihelper";
import * as storehelper from "../../selectors/storehelper";
import * as EntityTypes from "../../constants/EntityTypes";
import * as icons from "../../constants/Icons";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import {
  getEntityKey,
  ParentOptions,
  PickerNode,
  PickerTree,
} from "./PickerTree";

type ClientAndProjectTagPickerProps = {
  entities: ResourceObjects;
  onSelection: (projectIds: string[]) => void;
  selectedProjects: ResourceObjects;
  hierarchyData: any;
  getEntityParentFunction: GetEntityParentFunction;
};

const filterProjects = (
  filter: string,
  projects: ResourceObjects
): ResourceObjects =>
  projects.filter(
    (project) =>
      // there is no filter
      !filter ||
      // name prefix matches filter
      (apihelper.getAttr(project, "name") as string)
        .toLowerCase()
        .slice(0, filter.length) === filter.toLowerCase()
  );

const projectToTagItem = (
  project: ResourceObject,
  client?: ResourceObject
): ITagItem => ({
  name: `${client ? (apihelper.getAttr(client, "name") as string) + ": " : ""}${
    apihelper.getAttr(project, "name") as string
  }`,
  key: getEntityKey(project),
  type: apihelper.getEntityType(project) as string,
  id: apihelper.getEntityId(project) as string,
  childrenIds: [],
});

const clientToTagItem = (client: ResourceObject): ITagItem => ({
  name: apihelper.getAttr(client, "name") as string,
  key: getEntityKey(client),
  type: EntityTypes.CLIENT,
  title: "",
  id: apihelper.getEntityId(client) as string,
  childrenIds: [],
});

const pickerNodeNameCompare = storehelper.sortByLocaleCompare(
  (pickerNode: PickerNode) =>
    (apihelper.getAttr(pickerNode.getEntity(), "name") as string).toLowerCase()
);

const generateTree = (
  entities: ResourceObjects,
  hierarchyData: any,
  getEntityParentFunction: GetEntityParentFunction
): PickerTree => {
  const tree = new PickerTree();

  entities.forEach((entity) =>
    tree.insert(
      entity,
      getEntityParentFunction(entity, hierarchyData).type,
      getEntityParentFunction(entity, hierarchyData).value as ResourceObject
    )
  );
  return tree;
};

const toSelectedTagItems = (
  tree: PickerTree,
  selectedProjects: ResourceObjects
): ITagItem[] => {
  const selectedProjectLookup = selectedProjects.reduce((acc, project) => {
    acc[apihelper.getEntityId(project) as string] = project;
    return acc;
  }, {} as Record<string, ResourceObject>);

  const clientLevelNodes = tree.getTopLevelNodes().sort(pickerNodeNameCompare);
  const selectedNodes: Array<PickerNode[]> = clientLevelNodes.map(
    (clientLevelNode) => {
      const projectLevelNodes = tree
        .getChildrenOfParent(clientLevelNode)
        .sort(pickerNodeNameCompare);
      const selectedProjects = projectLevelNodes.filter(
        (projectNode) =>
          !!selectedProjectLookup[
            apihelper.getEntityId(projectNode.getEntity()) as string
          ]
      );
      // if all projects for client is selected, we use the client node directly instead
      const isAllProjectsSelected =
        selectedProjects.length === projectLevelNodes.length &&
        // this part implictly removes any clients with no projects
        projectLevelNodes.length > 0;
      return isAllProjectsSelected ? [clientLevelNode] : selectedProjects;
    }
  );

  return selectedNodes.flatten().map((node: PickerNode) => {
    const isClient = node.getParentOption() === ParentOptions.ORPHAN;
    const entity = node.getEntity();
    return isClient
      ? clientToTagItem(entity)
      : projectToTagItem(entity, node.getParent()?.getEntity());
  });
};

type FilteredClientProjectPickerProps = ClientProjectPickerProps & {
  filterText: string;
};

function FilteredClientProjectPicker({
  onSelection,
  selectedProjects,
  getEntityParentFunction,
  hierarchyData,
  entities,
  filterText,
}: FilteredClientProjectPickerProps) {
  const clients = entities.filter(
    (entity) => apihelper.getEntityType(entity) === EntityTypes.CLIENT
  );
  let projectsAvaliable = entities.filter(
    (entity) => apihelper.getEntityType(entity) === EntityTypes.PROJECT
  ); // Removing the client from the array
  projectsAvaliable = filterProjects(filterText, projectsAvaliable);

  const didLimit = !!filterText;

  return (
    <ClientProjectPicker
      onSelection={onSelection}
      entities={clients.concat(projectsAvaliable)}
      hierarchyData={hierarchyData}
      getEntityParentFunction={getEntityParentFunction}
      selectedProjects={selectedProjects}
      inSearch={didLimit} // Ensures that there is no deselection when calling the component
    />
  );
}

const tagPickerStyles = {
  itemsWrapper: { backgroundColor: "rgb(245,245,245)" },
};

export function ClientAndProjectTagPicker({
  entities,
  selectedProjects,
  onSelection,
  hierarchyData,
  getEntityParentFunction,
}: ClientAndProjectTagPickerProps) {
  const [filterText, setFilterText] = useState<string>("");
  const tagId = useId();
  const tree = useMemo<PickerTree>(
    () => generateTree(entities, hierarchyData, getEntityParentFunction),
    [entities, hierarchyData, getEntityParentFunction]
  );

  const renderSelectForm = useCallback(
    () => (
      <FilteredClientProjectPicker
        entities={entities}
        filterText={filterText}
        selectedProjects={selectedProjects}
        onSelection={onSelection}
        hierarchyData={hierarchyData}
        getEntityParentFunction={getEntityParentFunction}
      />
    ),
    [
      entities,
      filterText,
      selectedProjects,
      onSelection,
      hierarchyData,
      getEntityParentFunction,
    ]
  );

  const onRemoveItem = useCallback(
    (tagItems?: ITag[]) => {
      // End goal is to figure out the list of project ids that are selected after this deselection.
      // We only know the key of the tag item, but tag items can be both projects and clients.
      // If it is a client, we have to remove all projects under that client.
      if (tagItems) {
        const selectedNodes = tagItems
          .map((tagItem) => tree.getNodeByEntityKey(tagItem.key as string))
          .filter(Boolean) as PickerNode[];
        const projectNodes = selectedNodes.filter(
          (node) => node.getParentOption() === ParentOptions.PARENT
        );
        const clientNodes = selectedNodes.filter(
          (node) => node.getParentOption() === ParentOptions.ORPHAN
        );
        const expandedClientNodes = clientNodes
          .map((clientNode) => tree.getChildrenOfParent(clientNode))
          .flatten();
        const projectIds = projectNodes
          .concat(expandedClientNodes)
          .map((node) => node.getEntity())
          .map(apihelper.getEntityId) as string[];
        onSelection(projectIds);
      }
    },
    [tree, onSelection]
  );

  const onResolveSuggestions = useCallback(
    (filter: string, selectedItems?: ITag[]): ITag[] | PromiseLike<ITag[]> => {
      setFilterText(filter);
      return filter ? ([] as ITag[]) : (undefined as any); // this neededs to return undefined, in order to keep the callout opened when the input becomes an empty string.
    },
    [setFilterText]
  );

  const tagItems = useMemo(
    () => toSelectedTagItems(tree, selectedProjects),
    [tree, selectedProjects]
  );

  return (
    <div id={tagId}>
      {" "}
      {/* This div is needed to prevent the suggestions from following the input field...... */}
      <TagPicker
        selectedItems={tagItems}
        removeButtonAriaLabel="Remove"
        styles={tagPickerStyles}
        onResolveSuggestions={onResolveSuggestions}
        pickerSuggestionsProps={{
          suggestionsHeaderText: "Hold CTRL for multiselection ",
          onRenderNoResultFound: renderSelectForm,
        }}
        pickerCalloutProps={{
          style: { width: "500px" },
          preventDismissOnScroll: true,
          target: `#${tagId}`, // Makes the selection menu mount under the tag-bar
        }}
        inputProps={{
          style: { margin: 0 },
          placeholder: "Click here for project selection",
        }}
        onChange={(items) => onRemoveItem(items)}
        onRenderItem={(props: IPickerItemProps<ITag>) => (
          <ClientAndProjectTag
            item={props.item as ITagItem}
            removeButtonAriaLabel={props.removeButtonAriaLabel}
            onRemoveItem={props.onRemoveItem}
          />
        )}
        onEmptyResolveSuggestions={() =>
          (
            <h1>dummytext, since the dropdown wont be displayed otherwise </h1>
          ) as any
        }
      />
    </div>
  );
}

interface ITagItem extends ITag {
  title?: string;
  type: string;
  id: string;
  count?: number;
  childrenIds: string[];
  numberOfSelectedProjects?: number;
}

type ClientAndProjectTagProps = {
  item: ITagItem;
  onRemoveItem?: React.MouseEventHandler<HTMLButtonElement>;
  removeButtonAriaLabel?: string;
};

function ClientAndProjectTag({
  item,
  removeButtonAriaLabel,
  onRemoveItem,
}: ClientAndProjectTagProps) {
  let icon = icons.ICON_NAME_PROJECT;
  const titleOfDiv = item.title;

  if (item.type === EntityTypes.CLIENT) {
    icon = icons.ICON_NAME_CLIENT;
  }

  return (
    <div
      style={{
        background: "#F5F5F5",
        border: "1px solid black",
        margin: "1px",
        fontSize: "14px",
        fontWeight: "400",
        padding: "2px 4px",
        borderRadius: "3px",
      }}
      title={titleOfDiv}
      key={item.key + item.id}
    >
      <Icon
        iconName={icon}
        style={{
          color: "#0078D4",
          fontSize: "16px",
          margin: "2px",
          position: "relative",
          top: "2px",
        }}
      />

      <span style={{ margin: "2px", position: "relative", top: "-1px" }}>
        {item.name}
      </span>
      <button
        type="button"
        aria-label={removeButtonAriaLabel}
        onClick={onRemoveItem}
        style={{
          marginLeft: "5px",
          background: "#F5F5F5",
          border: "none",
          fontSize: "12px",
        }}
      >
        <span style={{ fontSize: "16px", position: "relative", top: "-1px" }}>
          ✖
        </span>
      </button>
    </div>
  );
}
