import { put, takeLatest, select, take } from "redux-saga/effects";
import { v1 as uuidv1 } from "uuid";
import * as types from "../../../constants/ActionTypes";
import * as entityTypes from "../../../constants/EntityTypes";
import { createEmptyModel } from "../../../lib/emptyModelCreation";
import { getWeekIntervalsFromMonth } from "../../../lib/monthToWeeks";
import {
  endTime,
  startTime,
} from "../../../selectors/pages/resourceManagement";
import { lockedTimePeriodsByResourcesAndPeriod } from "../../../selectors/reducers/lockedTimePeriod";
import { toggleProcessingDialog } from "../../../actions/pages/resourceManagement";

function* doLockTimePeriods(action) {
  yield put({ type: types.APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS_INIT });
  yield put(toggleProcessingDialog());

  const startDate = yield select(startTime);
  const endDate = yield select(endTime);
  const resourcesToClose = action.payload ? action.payload : [];
  const transactionId = uuidv1();

  let weeks = getWeekIntervalsFromMonth(startDate, endDate);

  let listOfLockedTimePeriods = [];
  for (let i = 0; i < weeks.length; i++) {
    let week = weeks[i];
    let startDate = week.start.format("YYYY-MM-DD");
    let endDate = week.end.format("YYYY-MM-DD");
    let startDateAsDateObj = week.start.toDate();
    let endDateAsDateObj = week.end.toDate();
    for (let j = 0; j < resourcesToClose.length; j++) {
      let resource = resourcesToClose[j];
      let getLockedTimePeriodsForResourceAndPeriod = yield select(
        lockedTimePeriodsByResourcesAndPeriod,
        resource,
        startDateAsDateObj,
        endDateAsDateObj
      );
      if (!getLockedTimePeriodsForResourceAndPeriod[0])
        listOfLockedTimePeriods.push(
          createEmptyModel(
            entityTypes.LOCKEDTIMEPERIOD,
            {
              startDate,
              endDate,
            },
            {
              resource: resource,
            }
          )
        );
    }
  }

  yield put({
    type: types.APP_DOMAIN_LOCK_PERIODS_INIT,
    payload: {
      listOfLockedTimePeriods,
      transactionId,
    },
  });

  yield take(
    (action) =>
      action.type === types.APP_DOMAIN_LOCK_PERIODS_COMPLETED &&
      action.payload.transactionId === transactionId
  );

  yield put(toggleProcessingDialog());
  yield put({ type: types.APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS_COMPLETED });
}

export default function* lockTimePeriod() {
  yield takeLatest(
    types.APP_PAGE_RESOURCEMANAGEMENT_LOCK_PERIODS,
    doLockTimePeriods
  );
}
