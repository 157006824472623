import React, { Component } from "react";
import PropTypes from "prop-types";
import KpiStack, { Kpi } from "../../KpiStack";
import { BillabilityAggregate } from "../../../lib/reportAggregators/billability";
import * as InternalPropTypes from "../../../constants/PropTypes";
import * as apihelper from "../../../selectors/apihelper";
import { Icon } from "@fluentui/react/lib/Icon";
import SorterList from "../../../components/lists/SorterList";
import * as storehelper from "../../../selectors/storehelper";
import { ActionButton } from "@fluentui/react/lib/Button";
import * as AbsencePeriodTypes from "../../../constants/AbsencePeriodTypeNames";
import { ResourceObject, ResourceObjects } from "../../../lib/models";
import { DefaultCompareFunctions } from "../../lists/sorting";
import { ColumnSorterCollection } from "../../lists/types";

const InfoIcon = () => <Icon iconName="Info" />;

type WarningLineProps = {
  infoTitle: string;
};

function WarningLine(props: React.PropsWithChildren<WarningLineProps>) {
  return (
    <p className="novatime-report-warning-line">
      {props.children}
      {props.infoTitle ? (
        <span
          className="novatime-report-warning-line-spacer"
          title={props.infoTitle}
        >
          <InfoIcon />
        </span>
      ) : null}
    </p>
  );
}

const vacationHoursPerDay = 8;

const baseColumns = [
  {
    key: "contractName",
    name: "Contract",
    fieldName: "contractName",
    minWidth: 200,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "share",
    name: "Share",
    fieldName: "share",
    minWidth: 70,
    maxWidth: 70,
  },
  {
    key: "hours",
    name: "Hours",
    fieldName: "hours",
    minWidth: 60,
    maxWidth: 60,
  },
];

const baseColumnSorters: ColumnSorterCollection = {
  contractName: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.contractName),
  },
  share: {
    compareFunction: DefaultCompareFunctions.Numerical((a) => a.share),
  },
  hours: {
    compareFunction: DefaultCompareFunctions.Numerical((a) => a.hours),
  },
};

const hourByContractListDefaultLimit = 3;

type InternalHoursReportProps = {
  reportData: BillabilityAggregate;
  account: ResourceObject;
  contracts: ResourceObjects;
  clients: ResourceObjects;
  absenceRegistrationProjects: ResourceObjects;
};

type InternalHoursReportState = {
  showAllHoursByContracts: boolean;
};

type HoursByContract = {
  contractName: string;
  hours: number;
  share: number;
};

export default class InternalHoursReport extends Component<
  InternalHoursReportProps,
  InternalHoursReportState
> {
  static propTypes = {
    reportData: PropTypes.instanceOf(BillabilityAggregate),
    account: InternalPropTypes.accountEntity,
    contracts: InternalPropTypes.contractEntities,
    clients: InternalPropTypes.clientEntities,
    absenceRegistrationProjects:
      InternalPropTypes.absenceRegistrationProjectEntities,
  };

  constructor(props: InternalHoursReportProps) {
    super(props);
    this.state = { showAllHoursByContracts: false };
    this.toggleShowAllHoursByContracts =
      this.toggleShowAllHoursByContracts.bind(this);
  }

  toggleShowAllHoursByContracts() {
    this.setState({
      showAllHoursByContracts: !this.state.showAllHoursByContracts,
    });
  }

  render() {
    const {
      reportData,
      account,
      contracts,
      clients,
      absenceRegistrationProjects,
    } = this.props;
    const { showAllHoursByContracts } = this.state;

    const accountName = apihelper.getAttr(account, "name");
    const internalClientId = apihelper.getRelId(account, "internalClient");
    const vacationRegProject = absenceRegistrationProjects.find((arp) =>
      apihelper.relHasId(
        arp,
        "absencePeriodType",
        AbsencePeriodTypes.VACATION.id + ""
      )
    );
    let vacationProjectId;
    if (vacationRegProject) {
      vacationProjectId = apihelper.getRelId(vacationRegProject, "project");
    }

    let kpis: Kpi[] = [];
    if (vacationProjectId) {
      const vacationHours = reportData.getHours({
        projectId: vacationProjectId,
      });
      const resourceIds = reportData.getResourceIds();
      const vacationDays = vacationHours / vacationHoursPerDay;
      const vacationDaysPerResource =
        resourceIds.length > 0 ? vacationDays / resourceIds.length : 0;
      kpis = [
        {
          title: "Vacation hours",
          value: vacationHours,
          description: `Total sum of hours registered on the vacation project. ${vacationDays.toFixed(
            1
          )} in days.`,
        },
        {
          title: "Vacation days per resource",
          value: vacationDaysPerResource.toFixed(1),
          description: `Assuming ${vacationHoursPerDay}h vacation per day and counting across ${resourceIds.length} registering resources`,
        },
      ];
    }

    let hoursByContractRows: HoursByContract[] = [];
    if (internalClientId) {
      const internalClient = storehelper.findById(clients, internalClientId);
      const totalInternalHours = reportData.getHours({
        clientId: internalClientId,
      });
      if (internalClient) {
        kpis.unshift({
          title: "Total internal hours",
          value: totalInternalHours,
          description: `All hours registered on projects under the ${apihelper.getAttr(
            internalClient,
            "name"
          )} client on the ${apihelper.getAttr(account, "name")} account`,
        });
      }

      const contractIds = reportData.getContractIds();
      contractIds.forEach((contractId: string) => {
        const contractHours = reportData.getHours({
          clientId: internalClientId,
          contractId,
        });
        if (contractHours > 0) {
          const contract = storehelper.findById(contracts, contractId);
          if (contract) {
            hoursByContractRows.push({
              contractName: apihelper.getAttr(contract, "title") as string,
              hours: contractHours,
              share:
                totalInternalHours > 0 ? contractHours / totalInternalHours : 0,
            });
          }
        }
      });
    }
    hoursByContractRows.sort(
      (a: HoursByContract, b: HoursByContract) => b.hours - a.hours
    );
    const shouldHideSomeContracts =
      !showAllHoursByContracts &&
      hoursByContractRows.length > hourByContractListDefaultLimit;
    const countOfContractsWithHours = hoursByContractRows.length;
    if (shouldHideSomeContracts) {
      hoursByContractRows = hoursByContractRows.slice(
        0,
        hourByContractListDefaultLimit
      );
    }

    const showList = hoursByContractRows.length > 0;

    return (
      <>
        {vacationProjectId ? (
          <KpiStack kpis={kpis} />
        ) : (
          <WarningLine infoTitle="Edit the account and set an absence registration project for vacation to get reporting">
            No vacation project configured for account {accountName}
          </WarningLine>
        )}
        {internalClientId ? (
          <>
            {showList ? (
              <SorterList
                columns={baseColumns}
                columnSorters={baseColumnSorters}
                items={hoursByContractRows}
                onRenderItemColumn={(item, index, column) => {
                  const fieldContent = item[column?.fieldName as string];
                  switch (column?.key) {
                    case "hours":
                      return <span>{fieldContent.toFixed(2)}</span>;
                    case "share":
                      return <span>{(fieldContent * 100).toFixed(0)}%</span>;
                    default:
                      return <span title={fieldContent}>{fieldContent}</span>;
                  }
                }}
                selectable={false}
              />
            ) : null}
            {shouldHideSomeContracts ? (
              <div style={{ marginLeft: "1em" }}>
                <ActionButton onClick={this.toggleShowAllHoursByContracts}>
                  Show all {countOfContractsWithHours} contracts
                </ActionButton>
              </div>
            ) : null}
          </>
        ) : (
          <WarningLine infoTitle="Edit the account and set a client as the internal client to get reporting">
            No Internal client configured for account {accountName}
          </WarningLine>
        )}
      </>
    );
  }
}
