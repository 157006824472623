import React from "react";
import { Dialog } from "@fluentui/react/lib/Dialog";
import { ProgressIndicator } from "@fluentui/react";

type ProcessingDialogProps = {
  title: string;
  body: string;
  hidden: boolean;
  progress: number;
};

export default function ProcessingDialog({
  hidden,
  progress,
  title,
  body,
}: ProcessingDialogProps) {
  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={{
        title: title,
      }}
    >
      <ProgressIndicator label={body} percentComplete={progress} />
    </Dialog>
  );
}
