import * as apihelper from "../../selectors/apihelper";
import { ResourceObject, ResourceObjects } from "../models";
import * as datehelper from "../date";
import * as timeRegistrationHelper from "../timeRegistration";
import Filter, { FilterLiteral } from "./Filter";

type Boundaries = {
  earliestStartTime: Date | null;
  latestStartTime: Date | null;
  earliestEndTime: Date | null;
  latestEndTime: Date | null;
};

type RegistrationMatrix = {
  day: Record<number, Record<number, Record<number, ResourceObjects>>>; // year, month of year, day of month
  resource: Record<string, ResourceObjects>; // resource id
  project: Record<string, ResourceObjects>; // project id
  contractRole: Record<string, ResourceObjects>; // contract role id
  id: Record<string, ResourceObject>; // timeregistration id
};

type Summations = {
  resources: Record<string, number>; // id to sum
  total: number;
  projects: Record<string, number>; // id to sum
  resourcePerDay: Record<
    string,
    Record<number, Record<number, Record<number, number>>>
  >; // resource id, year, month, day to sum
  day: Record<number, Record<number, Record<number, number>>>; // year, month, day to sum
};

export class TimeRegistrationIndex {
  private timeRegistrations: ResourceObjects;
  private registrationMatrix: RegistrationMatrix = {
    day: {},
    resource: {},
    project: {},
    contractRole: {},
    id: {},
  };
  private boundaries: Boundaries = {
    earliestStartTime: null,
    latestStartTime: null,
    earliestEndTime: null,
    latestEndTime: null,
  };
  private summations: Summations = {
    resources: {},
    total: 0,
    projects: {},
    resourcePerDay: {},
    day: {},
  };

  constructor(timeRegistrations: ResourceObjects) {
    this.timeRegistrations = timeRegistrations;
    this.buildIndex(timeRegistrations);
  }

  getById = (timeRegistrationId: string): undefined | ResourceObject =>
    this.registrationMatrix.id[timeRegistrationId];

  getTimeRegistrationDurationSumTotal = (): number => this.summations.total;

  getTimeRegistrationDurationSumByProject = (
    projectId: string
  ): number | undefined => this.summations.projects[projectId];

  getTimeRegistrationDurationSumByResourcePerDay = (
    resourceId: string,
    date: Date
  ): number | undefined => {
    const year = date.getFullYear(),
      month = date.getMonth(),
      day = date.getDate();
    return (
      this.summations.resourcePerDay[resourceId] &&
      this.summations.resourcePerDay[resourceId][year] &&
      this.summations.resourcePerDay[resourceId][year][month] &&
      this.summations.resourcePerDay[resourceId][year][month][day]
    );
  };

  getTimeRegistrationDurationSumByResource = (
    resourceId: string
  ): number | undefined => this.summations.resources[resourceId];

  getTimeRegistrationDurationSumByDay = (date: Date): number | undefined => {
    const year = date.getFullYear(),
      month = date.getMonth(),
      day = date.getDate();

    return (
      this.summations.day[year] &&
      this.summations.day[year][month] &&
      this.summations.day[year][month][day]
    );
  };

  getIndexedProjectIds = (): string[] =>
    Object.keys(this.registrationMatrix.project);

  getIndexedResourceIds = (): string[] =>
    Object.keys(this.registrationMatrix.resource);

  getIndexedContractRoleIds = (): string[] =>
    Object.keys(this.registrationMatrix.contractRole);

  buildIndex(timeRegistrations: ResourceObjects) {
    const registrationMatrix: RegistrationMatrix = {
      day: {},
      resource: {},
      project: {},
      contractRole: {},
      id: {},
    };
    const summations: Summations = {
      resources: {},
      total: 0,
      projects: {},
      resourcePerDay: {},
      day: {},
    };
    const boundaries: Boundaries = {
      earliestStartTime: null,
      latestStartTime: null,
      earliestEndTime: null,
      latestEndTime: null,
    };
    timeRegistrations.forEach((tr) => {
      const startTime = new Date(apihelper.getAttr(tr, "startTime") as string),
        endTime = new Date(apihelper.getAttr(tr, "endTime") as string),
        day = startTime.getDate(),
        month = startTime.getMonth(),
        year = startTime.getFullYear(),
        tregId = apihelper.getEntityId(tr) as string,
        resourceId = apihelper.getRelId(tr, "resource") as string,
        projectId = apihelper.getRelId(tr, "project") as string,
        contractRoleId = apihelper.getRelId(tr, "contractRole") as string,
        tregDuration = timeRegistrationHelper.getDuration(tr);

      // init if empty
      registrationMatrix.day[year] = registrationMatrix.day[year] || {};
      registrationMatrix.day[year][month] =
        registrationMatrix.day[year][month] ||
        new Array(32).fill(0).map(() => []);
      registrationMatrix.resource[resourceId] =
        registrationMatrix.resource[resourceId] || [];
      registrationMatrix.project[projectId] =
        registrationMatrix.project[projectId] || [];
      registrationMatrix.contractRole[contractRoleId] =
        registrationMatrix.contractRole[contractRoleId] || [];

      summations.resources[resourceId] = summations.resources[resourceId] || 0;
      summations.projects[projectId] = summations.projects[projectId] || 0;
      summations.day[year] = summations.day[year] || {};
      summations.day[year][month] =
        summations.day[year][month] || new Array(32).fill(0);
      summations.day[year][month][day] = summations.day[year][month][day] || 0;
      summations.resourcePerDay[resourceId] =
        summations.resourcePerDay[resourceId] || {};
      summations.resourcePerDay[resourceId][year] =
        summations.resourcePerDay[resourceId][year] || {};
      summations.resourcePerDay[resourceId][year][month] =
        summations.resourcePerDay[resourceId][year][month] || {};
      summations.resourcePerDay[resourceId][year][month][day] =
        summations.resourcePerDay[resourceId][year][month][day] || 0;

      // add to lookup index
      registrationMatrix.resource[resourceId].push(tr);
      registrationMatrix.project[projectId].push(tr);
      registrationMatrix.contractRole[contractRoleId].push(tr);
      registrationMatrix.day[year][month][day].push(tr);
      registrationMatrix.id[tregId] = tr;

      // add to summations
      summations.resources[resourceId] += tregDuration;
      summations.projects[projectId] += tregDuration;
      summations.day[year][month][day] += tregDuration;
      summations.total += tregDuration;
      summations.resourcePerDay[resourceId][year][month][day] += tregDuration;

      // add to boundaries
      boundaries.earliestStartTime =
        boundaries.earliestStartTime === null
          ? startTime
          : datehelper.min(startTime, boundaries.earliestStartTime);
      boundaries.latestStartTime =
        boundaries.latestStartTime === null
          ? startTime
          : datehelper.max(startTime, boundaries.latestStartTime);
      boundaries.earliestEndTime =
        boundaries.earliestEndTime === null
          ? endTime
          : datehelper.min(endTime, boundaries.earliestEndTime);
      boundaries.latestEndTime =
        boundaries.latestEndTime === null
          ? endTime
          : datehelper.max(endTime, boundaries.latestEndTime);
    }, this);

    // structure timeregs in order by day => resource => [timeregs]
    this.registrationMatrix = registrationMatrix;
    this.summations = summations;
    this.boundaries = boundaries;
  }

  // startTime:       lower boundary on date (registrations must be later or same day)
  // endTime:         upper boundary on date (registrations must be earlier or same day)
  // projects:        list of whitelisted projects or project ids
  // resources:       list of whitelisted resources or resource ids
  // contractRoles:   list of whitelisted contractRoles or contractRole ids
  query(
    filter: FilterLiteral | Filter = {
      startTime: null,
      endTime: null,
      projects: null,
      resources: null,
      contractRoles: null,
      timeRegistrations: null,
    }
  ) {
    if (this.timeRegistrations.length == 0) {
      return [];
    }

    const ft = new Filter(filter),
      filterStartTime = ft.hasStartTime()
        ? datehelper.max(
            ft.getStartTime() as Date,
            this.boundaries.earliestStartTime as Date
          )
        : this.boundaries.earliestStartTime,
      filterEndTime = ft.hasEndTime()
        ? datehelper.min(
            ft.getEndTime() as Date,
            this.boundaries.latestEndTime as Date
          )
        : this.boundaries.latestEndTime;

    const regMatrix = this.registrationMatrix;

    if (filterEndTime && filterStartTime && filterEndTime < filterStartTime) {
      return [];
    }

    // gather all timeregs within period
    let regIds: string[] = [];
    const startMonth = (filterStartTime as Date).getMonth(),
      startYear = (filterStartTime as Date).getFullYear(),
      endMonth = (filterEndTime as Date).getMonth(),
      endYear = (filterEndTime as Date).getFullYear();

    // add explicit time reg ids
    if (ft.hasTimeRegistrations()) {
      ft.visitTimeRegistrationIds((trId) => {
        if (!!regMatrix.id[trId]) {
          regIds.push(trId);
        }
      });
    } else {
      const matrix = this.registrationMatrix.day;

      for (let loopYear = startYear; loopYear <= endYear; loopYear++) {
        if (!(loopYear in matrix)) {
          continue;
        }
        const isStartYear = loopYear === startYear;
        const isEndYear = loopYear === endYear;
        for (
          let loopMonth = loopYear === startYear ? startMonth : 0;
          loopMonth <= (loopYear === endYear ? endMonth : 12);
          loopMonth++
        ) {
          if (!(loopMonth in matrix[loopYear])) {
            continue;
          }
          const isStartMonth = isStartYear && loopMonth === startMonth;
          const isEndMonth = isEndYear && loopMonth === endMonth;

          const startDate = isStartMonth
            ? (filterStartTime as Date).getDate()
            : 0;
          const endDate = isEndMonth ? (filterEndTime as Date).getDate() : 31;
          const month = matrix[loopYear][loopMonth];

          if (month) {
            for (let d = startDate; d <= endDate; d++) {
              if (!(d in month)) {
                continue;
              }
              let dayTregs = month[d];
              if (isStartMonth || isEndMonth) {
                dayTregs = dayTregs.filter((tr) =>
                  timeRegistrationHelper.isBetween(
                    tr,
                    filterStartTime as Date,
                    filterEndTime as Date
                  )
                );
              }
              dayTregs.forEach((tr) =>
                regIds.push(apihelper.getEntityId(tr) as string)
              );
            }
          }
        }
      }
    }

    // filter from the remaining properties, if any
    const others: {
      project: string[];
      resources: string[];
      contractRole: string[];
    } = {
      project: [],
      resources: [],
      contractRole: [],
    };

    ft.visitProjectIds((pid: string) => {
      if (!!regMatrix.project[pid]) {
        others.project = others.project.concat(
          regMatrix.project[pid].map(
            (tr) => apihelper.getEntityId(tr) as string
          )
        );
      }
    });

    ft.visitResourceIds((rid: string) => {
      if (!!regMatrix.resource[rid]) {
        others.resources = others.resources.concat(
          regMatrix.resource[rid].map(
            (tr) => apihelper.getEntityId(tr) as string
          )
        );
      }
    });

    ft.visitContractRoles((crid: string) => {
      if (!!regMatrix.contractRole[crid]) {
        others.contractRole = others.contractRole.concat(
          regMatrix.contractRole[crid].map(
            (tr) => apihelper.getEntityId(tr) as string
          )
        );
      }
    });

    if (ft.hasProjects()) {
      regIds = regIds.intersection(others.project);
    }

    if (ft.hasResources()) {
      regIds = regIds.intersection(others.resources);
    }

    if (ft.hasContractRoles()) {
      regIds = regIds.intersection(others.contractRole);
    }

    // scoop up the registrations from the ids
    return regIds.map((id) => this.registrationMatrix.id[id], this);
  }
}
