import TimeRegistrationMonth from "./month";
import React, { Component, PureComponent } from "react";
import { Resource } from "../uifabricextensions/Resource";
import TimeRegistrationHoverCard from "./TimeRegistrationHoverCard";
import MonthHourField from "./MonthHourField";
import latinize from "latinize";
import "../../lib/unique";
import { v1 as uuidv1 } from "uuid";
import * as apihelper from "../../selectors/apihelper";
import MonthReport from "./reports/monthReport";
import StateMerge from "../../lib/stateMerge/StateMerge";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import LockedTimePeriodIndex from "../../lib/LockedTimePeriodIndex";
import HourSet from "../../lib/HourSet";
import * as datehelper from "../../lib/date";

function resourceSort(a: ResourceObject, b: ResourceObject) {
  const aActive = apihelper.getAttr(a, "isActive") as boolean;
  const bActive = apihelper.getAttr(b, "isActive") as boolean;
  if (aActive != bActive) {
    return aActive && !bActive ? 1 : -1;
  }
  const nameA = latinize(apihelper.getAttr(a, "name") as string).toLowerCase(); // ignore upper and lowercase
  const nameB = latinize(apihelper.getAttr(b, "name") as string).toLowerCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
}

const regGoal = { goal: 8, deviation: 2 };

type NormalCellProps = {
  stateMerge: StateMerge;
  date: Date;
  resource: ResourceObject;
  onClick: (cellData: any) => void;
};

class NormalCell extends Component<NormalCellProps> {
  shouldComponentUpdate(nextProps: NormalCellProps) {
    const stateMergeIsSame = nextProps.stateMerge === this.props.stateMerge;
    const dateIsSame =
      (!!nextProps.date &&
        !!this.props.date &&
        this.props.date.getTime() === nextProps.date.getTime()) ||
      this.props.date === nextProps.date;
    const resourceIsSame = nextProps.resource === this.props.resource;
    const onClickIsSame = nextProps.onClick === this.props.onClick;
    const noChange =
      stateMergeIsSame && dateIsSame && resourceIsSame && onClickIsSame;
    return !noChange;
  }

  render() {
    const { stateMerge, resource, date, onClick } = this.props;
    const tregs = stateMerge.registrationsByResourceAndDay(resource, date);
    const totalHours = stateMerge.getTotalByResourceAndDay(resource, date);
    const key =
      "month-cell" +
      tregs.map((tr) => apihelper.getEntityId(tr) || uuidv1()).join("-");

    return (
      <MonthHourField
        cellData={{
          value: totalHours,
          registrationGoals: regGoal,
          date,
          resource,
          timeRegistrations: tregs,
        }}
        onClick={onClick}
        key={key + "-monthhourfield"}
      />
    );
  }
}

type RowSummationCellProps = {
  stateMerge: StateMerge;
  resource: ResourceObject;
  onClick: (cellData: any) => void;
  onLongHover?: (props: any) => JSX.Element | null;
  onHover?: (props: any) => JSX.Element | null;
  startTime: Date;
  endTime: Date;
};

class RowSummationCell extends PureComponent<RowSummationCellProps> {
  render() {
    const {
      stateMerge,
      resource,
      onClick,
      onLongHover,
      onHover,
      startTime,
      endTime,
    } = this.props;
    const timeRegistrations = stateMerge.registrationsByResource(resource);
    const total = stateMerge.getTotalByResource(resource);

    return (
      <TimeRegistrationHoverCard
        renderData={{
          timeRegistrations,
          resources: [resource],
          startTime,
          endTime,
        }}
        onHover={onHover}
        compactCardHeight={600}
        onLongHover={onLongHover}
      >
        <MonthHourField
          cellData={{
            value: total,
            resource,
            timeRegistrations,
          }}
          onClick={onClick}
        />
      </TimeRegistrationHoverCard>
    );
  }
}

type ColumnSummationCellProps = {
  stateMerge: StateMerge;
  resources: ResourceObjects;
  startTime: Date;
  endTime: Date;
  onClick: (cellData: any) => void;
  onHover: (props: any) => JSX.Element | null;
};

class ColumnSummationCell extends Component<ColumnSummationCellProps> {
  shouldComponentUpdate(nextProps: ColumnSummationCellProps) {
    const stateMergeIsSame = this.props.stateMerge === nextProps.stateMerge;
    const resourcesIsSame = this.props.resources === nextProps.resources;
    const startTimeIsSame =
      this.props.startTime === nextProps.startTime ||
      (!!this.props.startTime &&
        !!nextProps.startTime &&
        this.props.startTime.getTime() === nextProps.startTime.getTime());
    const endTimeIsSame =
      this.props.endTime === nextProps.endTime ||
      (!!this.props.endTime &&
        !!nextProps.endTime &&
        this.props.endTime.getTime() === nextProps.endTime.getTime());
    const onClickIsSame = this.props.onClick === nextProps.onClick;

    return !(
      stateMergeIsSame &&
      resourcesIsSame &&
      startTimeIsSame &&
      endTimeIsSame &&
      onClickIsSame
    );
  }

  render() {
    const { stateMerge, resources, startTime, endTime, onClick, onHover } =
      this.props;
    const timeRegistrations = stateMerge.getRegistrations({
      startTime,
      endTime,
      resources,
    });
    const total = stateMerge.getTotalByResourcesAndDay(resources, startTime);

    return (
      <TimeRegistrationHoverCard
        onHover={onHover}
        compactCardHeight={600}
        renderData={{ timeRegistrations, resources, startTime, endTime }}
      >
        <MonthHourField
          cellData={{
            value: total,
            date: startTime,
            timeRegistrations,
          }}
          onClick={onClick}
        />
      </TimeRegistrationHoverCard>
    );
  }
}

type TotalSummationCellProps = {
  stateMerge: StateMerge;
  resources: ResourceObjects;
  onClick?: (cellData: any) => void;
  onLongHover?: (props: any) => JSX.Element | null;
  onHover?: (props: any) => JSX.Element | null;
  startTime: Date;
  endTime: Date;
};

class TotalSummationCell extends PureComponent<TotalSummationCellProps> {
  render() {
    const {
      stateMerge,
      resources,
      onClick,
      onLongHover,
      onHover,
      startTime,
      endTime,
    } = this.props;
    const timeRegistrations = stateMerge.getRegistrations({ resources });
    const total = stateMerge.aggrHours(timeRegistrations);

    return (
      <TimeRegistrationHoverCard
        renderData={{
          timeRegistrations,
          resources,
          startTime,
          endTime,
        }}
        onHover={onHover}
        compactCardHeight={600}
        onLongHover={onLongHover}
      >
        <MonthHourField
          cellData={{
            value: total,
            timeRegistrations,
          }}
          onClick={onClick}
        />
      </TimeRegistrationHoverCard>
    );
  }
}

type TableRow = {
  id: string;
  resource: ResourceObject;
};

type ByResourceTableProps = {
  onCellClick: (cellData: any) => void;
  lockedTimePeriodIndex: LockedTimePeriodIndex;
  absencePeriods: ResourceObjects;
  absencePeriodStatuses: ResourceObjects;
  resources: ResourceObjects;
  stateMerge: StateMerge;
  firstDayOfMonth: Date;
  hourSet: HourSet;
  currencies: ResourceObjects;
  locales: ResourceObjects;
  absenceRegistrationProjects: ResourceObjects;
  account: ResourceObject;
  billabilityTypes: ResourceObjects;
  clients: ResourceObjects;
  isCellUnderEdit: (row: any, date: Date) => void;
  projects: ResourceObjects;
  contracts: ResourceObjects;
};

export default class ByResourceTable extends Component<ByResourceTableProps> {
  constructor(props: ByResourceTableProps) {
    super(props);

    this._getRows = this._getRows.bind(this);
    this._renderRowHeader = this._renderRowHeader.bind(this);
    this._renderCell = this._renderCell.bind(this);
    this._renderColumnSummation = this._renderColumnSummation.bind(this);
    this._renderRowSummation = this._renderRowSummation.bind(this);
    this._renderTotalSummation = this._renderTotalSummation.bind(this);
    this._onCellClick = this._onCellClick.bind(this);
    this._onRowSummationLongHover = this._onRowSummationLongHover.bind(this);
    this._isLocked = this._isLocked.bind(this);
    this._getAbsencePeriod = this._getAbsencePeriod.bind(this);
    this._isFirstDayOfLockedPeriod = this._isFirstDayOfLockedPeriod.bind(this);
    this._isLastDayOfLockedPeriod = this._isLastDayOfLockedPeriod.bind(this);
  }

  _onCellClick(cellData: any) {
    this.props.onCellClick && this.props.onCellClick(cellData);
  }

  _isLocked(resourceId: string, date: Date) {
    const { lockedTimePeriodIndex } = this.props;
    return lockedTimePeriodIndex.isDateLockedForResource(date, resourceId);
  }

  _isFirstDayOfLockedPeriod(resourceId: string, date: Date) {
    const { lockedTimePeriodIndex } = this.props;
    return lockedTimePeriodIndex.isDateFirstDayOfLockedPeriodForResource(
      date,
      resourceId
    );
  }

  _isLastDayOfLockedPeriod(resourceId: string, date: Date) {
    const { lockedTimePeriodIndex } = this.props;
    return lockedTimePeriodIndex.isDateLastDayOfLockedPeriodForResource(
      date,
      resourceId
    );
  }

  _getAbsencePeriod(resourceId: string, date: Date): string | undefined {
    const { absencePeriods, absencePeriodStatuses } = this.props;
    const absencePeriod = absencePeriods.find(
      (absencePeriod) =>
        new Date(apihelper.getAttr(absencePeriod, "periodStart") as string) <=
          date &&
        new Date(apihelper.getAttr(absencePeriod, "periodEnd") as string) >=
          date &&
        apihelper.relHasId(absencePeriod, "resource", resourceId)
    );
    let res: string | undefined;
    if (absencePeriod) {
      const statusId = apihelper.getRelId(
        absencePeriod,
        "absencePeriodStatus"
      ) as string;
      const vs = absencePeriodStatuses.find((vs) =>
        apihelper.entityHasId(vs, statusId)
      ) as ResourceObject;
      res = apihelper.getAttr(vs, "name") as string;
    }
    return res;
  }

  _getRows(): TableRow[] {
    const { resources } = this.props;
    return resources
      .slice()
      .sort(resourceSort)
      .map((res) => ({
        id: apihelper.getEntityId(res) as string,
        resource: res,
      }));
  }

  _renderRowHeader(row: TableRow) {
    return <Resource model={row.resource} />;
  }

  _renderCell(row: TableRow, monthDate: any) {
    return (
      <NormalCell
        stateMerge={this.props.stateMerge}
        date={monthDate.dateObj}
        resource={row.resource}
        onClick={this._onCellClick}
      />
    );
  }

  _renderColumnSummation(monthDate: any) {
    const date = new Date(monthDate.dateObj);
    return (
      <ColumnSummationCell
        stateMerge={this.props.stateMerge}
        resources={this.props.resources}
        startTime={datehelper.startOfDay(date)}
        endTime={datehelper.endOfDay(date)}
        onHover={this._onRowSummationLongHover}
        onClick={this._onCellClick}
      />
    );
  }

  _renderRowSummation(row: TableRow) {
    const { stateMerge, firstDayOfMonth } = this.props;

    return (
      <RowSummationCell
        stateMerge={stateMerge}
        resource={row.resource}
        startTime={datehelper.startOfMonth(firstDayOfMonth)}
        endTime={datehelper.endOfMonth(firstDayOfMonth)}
        onClick={this._onCellClick}
        onHover={this._onRowSummationLongHover}
      />
    );
  }

  _renderTotalSummation() {
    const { stateMerge, firstDayOfMonth } = this.props;

    return (
      <TotalSummationCell
        stateMerge={stateMerge}
        resources={this.props.resources}
        startTime={datehelper.startOfMonth(firstDayOfMonth)}
        endTime={datehelper.endOfMonth(firstDayOfMonth)}
        onClick={this._onCellClick}
        onHover={this._onRowSummationLongHover}
      />
    );
  }

  _onRowSummationLongHover(renderData: any) {
    return (
      <MonthReport
        stateMerge={this.props.stateMerge}
        hourSet={this.props.hourSet}
        startTime={renderData.startTime}
        endTime={renderData.endTime}
        limitToResources={renderData.resources}
        currencies={this.props.currencies}
        locales={this.props.locales}
        absenceRegistrationProjects={this.props.absenceRegistrationProjects}
        account={this.props.account}
        billabilityTypes={this.props.billabilityTypes}
        clients={this.props.clients}
      />
    );
  }

  render() {
    const { firstDayOfMonth } = this.props;
    return firstDayOfMonth != null ? (
      <TimeRegistrationMonth
        rows={this._getRows()}
        isCellUnderEdit={this.props.isCellUnderEdit}
        renderRowHeader={this._renderRowHeader}
        renderCell={this._renderCell}
        renderRowSummation={this._renderRowSummation}
        renderColumnSummation={this._renderColumnSummation}
        renderTotalSummation={this._renderTotalSummation}
        dayInMonth={this.props.firstDayOfMonth}
        clients={this.props.clients}
        projects={this.props.projects}
        contracts={this.props.contracts}
        withCallout={false}
        isLocked={this._isLocked}
        isFirstDayOfLockedPeriod={this._isFirstDayOfLockedPeriod}
        isLastDayOfLockedPeriod={this._isLastDayOfLockedPeriod}
        absencePeriod={this._getAbsencePeriod}
      />
    ) : null;
  }
}
