import { useCallback, useEffect, useMemo, useState } from "react";
import {
  extractValues,
  sortByLocaleCompare,
  sortByValue,
} from "../../selectors/storehelper";
import {
  ColumnSorterCollection,
  DefaultCompareFunctionCollection,
  SortColumnState,
  SortCommand,
  SortDefinition,
} from "./types";

export const DefaultCompareFunctions: DefaultCompareFunctionCollection = {
  Lexical: (extract) => sortByLocaleCompare(extract),
  Numerical: (extract) => sortByValue(extract),
  Boolean: (extract) => (a: any, b: any) => {
    const [aVal, bVal] = extractValues(extract, a, b, false);
    return aVal && !bVal ? 1 : !aVal && bVal ? -1 : 0;
  },
};

export const useSortColumn = <T>(
  sorterCollection: ColumnSorterCollection
): [SortCommand<T> | undefined, (selectedIdx: string) => void] => {
  const [sortState, setSortState] = useState<SortColumnState>({
    sortedColumnIndex: undefined,
    sortedDescending: undefined,
  });

  useEffect(
    () =>
      setSortState({
        sortedColumnIndex: undefined,
        sortedDescending: undefined,
      }),
    [sorterCollection]
  );

  const sorter = useMemo(
    () =>
      sortState.sortedColumnIndex &&
      sorterCollection[sortState.sortedColumnIndex],
    [sortState]
  ) as SortDefinition<any>;
  const compareFunction = useMemo(
    () =>
      sorter &&
      (sortState.sortedDescending
        ? (a: any, b: any) => sorter.compareFunction(a, b) * -1
        : sorter.compareFunction),
    [sorter, sortState]
  );

  let sortCmd: SortCommand<T> | undefined = undefined;
  if (sortState.sortedColumnIndex) {
    sortCmd = {
      compareFunction,
      columnKey: sortState.sortedColumnIndex,
      isSortedAscending: !sortState.sortedDescending,
    };
  }

  const onToggleColumn = useCallback(
    (columnName: string) => {
      if (sorterCollection[columnName]) {
        setSortState({
          sortedColumnIndex: columnName,
          sortedDescending:
            sortState.sortedColumnIndex === columnName &&
            !sortState.sortedDescending,
        });
      }
    },
    [sortState, setSortState]
  );
  return [sortCmd, onToggleColumn];
};
