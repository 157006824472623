import ModelMergeQueue, {
  INVALID as QUEUE_INVALID,
  SCHEDULED as QUEUE_SCHEDULED,
  UNCHANGED as QUEUE_UNCHANGED,
  UNKNOWN as QUEUE_UNKNOWN,
  VALID as QUEUE_VALID,
} from "../ModelMergeQueue";
import * as datehelper from "../date";
import "../setOperations";
import * as apihelper from "../../selectors/apihelper";
import Filter, { FilterLiteral } from "./Filter";
import * as timeRegistrationHelper from "../timeRegistration";
import { ResourceObject, ResourceObjects } from "../models";
import { TimeRegistrationIndex } from "./TimeRegistrationIndex";
export const QUEUED_VALID = "queued, valid";
export const QUEUED_INVALID = "queued, invalid";
export const SCHEDULED = "scheduled, not live";
export const LIVE = "live";
export const PERSISTED = "persisted";
export const UNKNOWN = "unknown, probably persisted";

export default class StateMerge {
  private queue: ModelMergeQueue;
  private index: TimeRegistrationIndex;
  private storeRegistrations: ResourceObjects;
  private liveTimeRegistrationActions: any[];

  constructor(
    storeRegistrations?: ResourceObjects,
    liveTimeRegistrationActions?: any[],
    queue?: ModelMergeQueue
  ) {
    this.liveTimeRegistrationActions = liveTimeRegistrationActions || [];
    this.queue = queue || new ModelMergeQueue();
    this.storeRegistrations = storeRegistrations || [];
    this.index = new TimeRegistrationIndex(this.storeRegistrations);
  }

  getRegistrations(filter: FilterLiteral | Filter) {
    const ft = new Filter(filter);
    const storeRegistrations = this.index.query(ft);
    const queueChanges = this.queue.getAllUpdatedModels().filter(ft.filter);
    const queueChangesNotInStore: ResourceObjects = [],
      queueChangesAlsoInStore: ResourceObjects = [];
    const storeRegIds = new Set(
      storeRegistrations.map((tr) => apihelper.getEntityId(tr))
    );
    queueChanges.forEach((qc) => {
      if (
        (qc.id && storeRegIds.has(qc.id)) ||
        this.queue.isMergedModelNotified(qc)
      ) {
        queueChangesAlsoInStore.push(qc);
      } else {
        queueChangesNotInStore.push(qc);
      }
    }, this);
    const queueRegIds = new Set(
      queueChangesAlsoInStore.map(
        (qc) => qc.id || this.queue.getMergedModelCreateId(qc),
        this
      )
    );
    const storeRegistrationsNotInQueue = storeRegistrations.filter(
      (sr) => !queueRegIds.has(apihelper.getEntityId(sr))
    );
    return queueChangesNotInStore
      .concat(queueChangesAlsoInStore)
      .concat(storeRegistrationsNotInQueue);
  }

  registrationsByProject(project: string | ResourceObject) {
    return this.getRegistrations({ projects: [project] });
  }

  registrationsByDay(day: Date) {
    return this.getRegistrations({
      startTime: datehelper.startOfDay(day),
      endTime: datehelper.endOfDay(day),
    });
  }

  registrationsByResourceAndDay(resource: string | ResourceObject, day: Date) {
    return this.getRegistrations({
      resources: [resource],
      startTime: datehelper.startOfDay(day),
      endTime: datehelper.endOfDay(day),
    });
  }

  registrationsByResource(resource: string | ResourceObject) {
    return this.getRegistrations({ resources: [resource] });
  }

  registrationsByProjectAndDay(project: ResourceObject, day: Date) {
    return this.registrationsByDay(day).filter((t) =>
      apihelper.relRefersToEntity(t, "project", project)
    );
  }

  registrationsByResourceAndProjectAndDay(
    resource: string | ResourceObject,
    project: ResourceObject,
    day: Date
  ) {
    return this.registrationsByResourceAndDay(resource, day).filter((t) =>
      apihelper.relRefersToEntity(t, "project", project)
    );
  }

  registrationsInWeek(anyIsoWeekDay: Date) {
    return this.getRegistrations({
      startTime: datehelper.startOfWeek(anyIsoWeekDay),
      endTime: datehelper.endOfWeek(anyIsoWeekDay),
    });
  }

  registrationById(id: string): undefined | ResourceObject {
    return this.index.getById(id);
  }

  getTimeregDuration(treg: ResourceObject) {
    return timeRegistrationHelper.getDuration(treg);
  }

  aggrHours(tregs: ResourceObjects) {
    return tregs.reduce(
      (acc, cur) => acc + timeRegistrationHelper.getDuration(cur),
      0
    );
  }

  getTotalDuration() {
    return this.index.getTimeRegistrationDurationSumTotal();
  }

  getTotalByProject(project: string | ResourceObject) {
    const projectId = apihelper.isEntity(project)
      ? (apihelper.getEntityId(project as ResourceObject) as string)
      : (project as string);
    return this.index.getTimeRegistrationDurationSumByProject(projectId) || 0;
  }

  getTotalByResourceAndDay(resource: ResourceObject | string, date: Date) {
    const resourceId = apihelper.isEntity(resource)
      ? (apihelper.getEntityId(resource as ResourceObject) as string)
      : (resource as string);
    return (
      this.index.getTimeRegistrationDurationSumByResourcePerDay(
        resourceId,
        date
      ) || 0
    );
  }

  getTotalByResourcesAndDay(
    resources: Array<string | ResourceObject>,
    date: Date
  ) {
    const resourceIds: string[] = resources.map((resource) =>
      apihelper.isEntity(resource)
        ? (apihelper.getEntityId(resource as ResourceObject) as string)
        : (resource as string)
    );
    const index = this.index;
    return resourceIds.reduce(
      (acc, resourceId) =>
        acc +
        (index.getTimeRegistrationDurationSumByResourcePerDay(
          resourceId,
          date
        ) || 0),
      0
    );
  }

  getTotalByResource(resource: string | ResourceObject) {
    const resourceId = apihelper.isEntity(resource)
      ? (apihelper.getEntityId(resource as ResourceObject) as string)
      : (resource as string);
    return this.index.getTimeRegistrationDurationSumByResource(resourceId) || 0;
  }

  getTotalByDay(date: Date) {
    return this.index.getTimeRegistrationDurationSumByDay(date);
  }

  containsTimeRegistration(timeRegistration: ResourceObject) {
    return apihelper.isPersistedEntity(timeRegistration)
      ? !!this.index.getById(apihelper.getEntityId(timeRegistration) as string)
      : !!this.queue.findInQueue(timeRegistration);
  }

  // true if any of the provided time registrations are pending
  isPendingUpload(tregs: ResourceObject | ResourceObjects) {
    tregs = Array.isArray(tregs) ? tregs : [tregs].filter(Boolean);
    return tregs.some((tr) => this.queue.isModelValidOrScheduled(tr), this);
  }

  // true if any of the provided time registration are currently in transit
  isUploading(tregs: ResourceObject | ResourceObjects) {
    tregs = Array.isArray(tregs) ? tregs : [tregs].filter(Boolean);
    if (this.liveTimeRegistrationActions.length == 0 || tregs.length == 0) {
      return false;
    }

    return !!tregs.find((treg) => this._isScheduledChangeLive(treg), this);
  }

  getFailedValidations(treg: ResourceObject) {
    return this.queue.getFailedValidations(treg);
  }

  _isScheduledChangeLive(model: ResourceObject) {
    const ch = this.queue.getChange(model);
    return (
      !!ch &&
      !!this.liveTimeRegistrationActions.find(
        (lta) => lta.initAction.payload.transactionId === ch?.claimId
      )
    );
  }

  getFieldStatus(model: ResourceObject, fieldPath: string) {
    const qStatus = this.queue.queryFieldStatus(model, fieldPath);
    switch (qStatus) {
      case QUEUE_UNCHANGED:
        return apihelper.getEntityId(model) ? PERSISTED : QUEUE_VALID;
      case QUEUE_VALID:
        return QUEUED_VALID;
      case QUEUE_INVALID:
        return QUEUED_INVALID;
      case QUEUE_SCHEDULED:
        return this._isScheduledChangeLive(model) ? LIVE : SCHEDULED;
      case QUEUE_UNKNOWN:
        return UNKNOWN;
      case undefined: {
        if (model.id) {
          const storeReg = this.registrationById(
            apihelper.getEntityId(model) as string
          );
          return storeReg ? PERSISTED : UNKNOWN;
        } else {
          return UNKNOWN;
        }
      }
      default:
        throw (
          "Unexpected value in StateMerge.getFieldStatus (" +
          qStatus +
          ", " +
          fieldPath +
          ")"
        );
    }
  }

  getReferencedProjectIds() {
    return this.index.getIndexedProjectIds();
  }

  getReferencedResourceIds() {
    return this.index.getIndexedResourceIds();
  }

  getReferencedContractRoleIds() {
    return this.index.getIndexedContractRoleIds();
  }

  getProjectIdsWithHours() {
    return this.getReferencedProjectIds().filter(
      (pId) =>
        (this.index.getTimeRegistrationDurationSumByProject(pId) || 0) > 0,
      this
    );
  }
}
