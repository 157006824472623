import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { QUEUED_VALID, QUEUED_INVALID } from "../../lib/stateMerge/StateMerge";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import SingleBaseForm, {
  SingleBaseFormProps,
  SingleBaseFormState,
  SingleFormFieldValues,
  SingleFormFields,
} from "./SingleBaseForm";
import Expandable from "../Expandable";
import TimeInputField from "../TimeInputField";
import Column from "../grid/Column";
import Row from "../grid/Row";
import BasePickerSimple from "../uifabricextensions/BasePickerSimple";
import ResourceTagPicker from "../uifabricextensions/ResourceTagPicker";
import * as Icons from "../../constants/Icons";
import * as InternalPropTypes from "../../constants/PropTypes";
import * as apihelper from "../../selectors/apihelper";
import {
  createEmptyModelRelationships,
  createEmptyModelAttributes,
} from "../../lib/emptyModelCreation";
import * as EntityTypes from "../../constants/EntityTypes";
import Grid from "../grid/Grid";
import { isBillabilityTypeBillable } from "../../selectors/reducers/billabilityTypes";
import { NotificationActivityItem } from "../uifabricextensions/Notification";
import { Stack, IStackTokens, StackItem } from "@fluentui/react/lib/Stack";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import {
  Notification,
  NotificationLevel,
  NotificationType,
} from "../../reducers/app/notification";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { WriteBackTimer } from "../../lib/timer";
import * as contractSelectors from "../../selectors/reducers/contract";
import * as projectSelectors from "../../selectors/reducers/project";
import * as projectResourceSelectors from "../../selectors/reducers/projectResource";
import * as contractRoleSelectors from "../../selectors/reducers/contractRole";
import * as storehelper from "../../selectors/storehelper";
import { UserPermissionSet } from "../../lib/PermissionSet";
import "../../lib/setOperations";
import { QueueChangeStatus } from "../../lib/ModelMergeQueue";

type RangeValue = {
  min: number;
  max: number;
};

const getRangeValue = (startTime: Date, endTime: Date): RangeValue => {
  const rangeValue = { min: 0, max: 0 };
  if (startTime) {
    rangeValue.min =
      startTime.getHours() * 4 + Math.floor(startTime.getMinutes() / 15);
    rangeValue.max = rangeValue.min;
  }
  if (endTime) {
    rangeValue.max =
      (startTime.getDate() !== endTime.getDate() ? 96 : 0) +
      endTime.getHours() * 4 +
      Math.floor(endTime.getMinutes() / 15);
  }
  return rangeValue;
};

const timeFromValue = (v: number, date: Date | string) => {
  const d = new Date(date);
  d.setHours(Math.floor(v / 4));
  d.setMinutes((v % 4) * 15);
  return d;
};

const formValEquals = (fieldName: string, curVal: any, nextVal: any) => {
  const isDates = curVal instanceof Date && nextVal instanceof Date;
  return isDates ? curVal.getTime() === nextVal.getTime() : curVal === nextVal;
};

const verticalGapStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: "20px 0 0 0",
};

type TimeRegistrationFormProps = SingleBaseFormProps & {
  allTimeRegistrationStatus: Array<ResourceObject>;
  allBillabilityTypes: Array<ResourceObject>;
  onChange?: (changes: ResourceObject, model: ResourceObject) => void;
  autoShowDetails?: boolean;
  canDelete: boolean;
  canEdit: boolean;
  contractRoles: Array<ResourceObject>;
  fieldStatus: (model: ResourceObject, fieldPath: string) => QueueChangeStatus;
  isUnderDelete: boolean;
  model: ResourceObject;
  contracts: ResourceObjects;
  projects: Array<ResourceObject>;
  projectResources: Array<ResourceObject>;
  resourcesForSelectedProject: Array<ResourceObject>;
  users: Array<ResourceObject>;
  idForNotBillableType: string;
  currentUserPermissionSet: UserPermissionSet;
  notifications: Array<Notification>;
  resourceForCurrentUser?: ResourceObject;
  compareModels?: (model1: ResourceObject, model2: ResourceObject) => boolean;
};

type TimeRegistrationFormState = SingleBaseFormState & {
  rangeValue: RangeValue;
  delayedWriteBackTimer: WriteBackTimer;
};

type RestrictedProjectPickerProps = {
  selectedProjectId: string;
  allProjects: ResourceObjects;
  isQueuedForWrite: boolean;
  disabled: boolean;
  contracts: ResourceObjects;
  projectResources: ResourceObjects;
  contractRoles: ResourceObjects;
  selectedResource?: ResourceObject;
  resourceForCurrentUser?: ResourceObject;
  currentUserPermissionSet: UserPermissionSet;
  timeRegistrationStartTime: string;
  onChange: (selectedProject: ResourceObject | null) => void;
};

function getAssignableContractRolesForAllocation(
  contractRoles: ResourceObjects,
  timeRegistrationStartTime: Date,
  projectResources: ResourceObjects,
  selectedResource: ResourceObject,
  allProjects: ResourceObjects,
  selectedProjectId: string
) {
  const startTime = new Date(timeRegistrationStartTime);
  const projectResourcesForSelectedResource =
    projectResourceSelectors.filterByResourceAllocation(
      projectResources,
      selectedResource,
      allProjects
    );
  const selectedProject = storehelper.findById(allProjects, selectedProjectId);
  const projectResourcesForProject = projectResourceSelectors.filterByProjects(
    projectResourcesForSelectedResource,
    selectedProject
  );
  const activeProjectResources =
    projectResourceSelectors.filterByActiveInPeriod(
      projectResourcesForProject,
      startTime,
      startTime
    );
  return contractRoleSelectors.filterByProjectResources(
    contractRoles,
    activeProjectResources
  );
}

function RestrictedProjectPicker({
  allProjects,
  selectedProjectId,
  isQueuedForWrite,
  disabled,
  projectResources,
  contracts,
  selectedResource,
  resourceForCurrentUser,
  currentUserPermissionSet,
  timeRegistrationStartTime,
  onChange,
  contractRoles,
}: RestrictedProjectPickerProps) {
  // current resource is selected resource and is allocated to
  // current user has project write to source and destination project and selected resource is allocated to both
  let projectsSelectedResourceIsAllocatedTo: ResourceObjects = [];
  let selectableProjects: ResourceObjects = [];
  let unselectableProjects: ResourceObjects = [];
  const selectedProject = storehelper.findById(allProjects, selectedProjectId);
  const currentUserIsSelectedResource = apihelper.entityHasId(
    resourceForCurrentUser,
    apihelper.getEntityId(selectedResource)
  );
  if (selectedProject && selectedResource) {
    const contractsForSelectedProject = contractSelectors.filterByProjects(
      contracts,
      selectedProject
    );
    const projectsOnSameContracts = projectSelectors.filterByContracts(
      allProjects,
      contractsForSelectedProject
    );
    const projectResourcesForSelectedResource =
      projectResourceSelectors.filterByResourceAllocation(
        projectResources,
        selectedResource,
        allProjects
      );
    projectsSelectedResourceIsAllocatedTo =
      projectSelectors.filterByProjectResources(
        projectsOnSameContracts,
        projectResourcesForSelectedResource
      );

    if (currentUserIsSelectedResource) {
      selectableProjects = projectsSelectedResourceIsAllocatedTo;
    } else {
      selectableProjects = projectsSelectedResourceIsAllocatedTo.filter(
        (project) => currentUserPermissionSet.hasProjectWrite(project)
      );
    }
    unselectableProjects = selectableProjects.filter((project) => {
      const projectResourcesForProject =
        projectResourceSelectors.filterByProjects(
          projectResourcesForSelectedResource,
          project
        );
      const startTime = new Date(timeRegistrationStartTime);
      const activeProjectResources =
        projectResourceSelectors.filterByActiveInPeriod(
          projectResourcesForProject,
          startTime,
          startTime
        );
      const noActiveAllocation = activeProjectResources.length === 0;
      const contractRolesForProject =
        contractRoleSelectors.filterByProjectResources(
          contractRoles,
          activeProjectResources
        );
      const hasClientRead =
        contractsForSelectedProject.length === 1 &&
        currentUserPermissionSet.hasClientRead(
          apihelper.getRelId(contractsForSelectedProject[0], "client") as string
        );
      const canResolveContractRoles =
        contractRolesForProject.length === 1 || hasClientRead;
      return noActiveAllocation || !canResolveContractRoles;
    });
  }

  return (
    <BasePickerSimple
      label="Project"
      entities={selectableProjects}
      disabledEntityIds={
        unselectableProjects.map(apihelper.getEntityId) as string[]
      }
      selectedEntity={selectedProject}
      icon={isQueuedForWrite ? Icons.ICON_NAME_CHANGE_QUEUED : undefined}
      disabled={disabled || selectableProjects.length == 1}
      onEntitySelected={onChange}
    />
  );
}

export default class TimeRegistrationForm extends SingleBaseForm<
  TimeRegistrationFormProps,
  TimeRegistrationFormState
> {
  constructor(props: TimeRegistrationFormProps) {
    super(props);

    this._onDescriptionChange = this._onDescriptionChange.bind(this);
    this._onStartTimeChange = this._onStartTimeChange.bind(this);
    this._onEndTimeChange = this._onEndTimeChange.bind(this);
    this._onStatusIdChange = this._onStatusIdChange.bind(this);
    this._onToggleBillable = this._onToggleBillable.bind(this);
    this._onResourceIdChange = this._onResourceIdChange.bind(this);
    this._onProjectIdChange = this._onProjectIdChange.bind(this);
    this._onContractRoleIdChange = this._onContractRoleIdChange.bind(this);
    this._onTimeRangeInputChange = this._onTimeRangeInputChange.bind(this);
    this._onTimeRangeInputChangeComplete =
      this._onTimeRangeInputChangeComplete.bind(this);
    this.propagateFieldValueChange = this.propagateFieldValueChange.bind(this);
    this._onFieldBlur = this._onFieldBlur.bind(this);

    const fields = TimeRegistrationForm._initializeFieldsFromModel(props.model);
    const rangeValue = getRangeValue(
      fields.startTime.value,
      fields.endTime.value
    );
    this.state = {
      fields,
      rangeValue,
      delayedWriteBackTimer: new WriteBackTimer(this.propagateFieldValueChange),
    };
  }

  static propTypes = {
    allBillabilityTypes: PropTypes.array,
    autoShowDetails: PropTypes.bool,
    canDelete: PropTypes.bool,
    canEdit: PropTypes.bool,
    isUnderDelete: PropTypes.bool,
    model: InternalPropTypes.timeRegistrationEntity,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDismiss: PropTypes.func,
    onSave: PropTypes.func,
    projectResources: InternalPropTypes.projectResourceEntities,
    projects: InternalPropTypes.projectEntities,
    resourcesForSelectedProject: InternalPropTypes.resourceEntities,
    users: InternalPropTypes.userEntities,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    canEdit: true,
    isUnderDelete: false,
    canDelete: true,
    autoShowDetails: false,
    projects: [],
    users: [],
    resourcesForSelectedProject: [],
    allBillabilityTypes: [],
  };

  static _initializeFieldsFromModel(model: ResourceObject): SingleFormFields {
    return this._initializeFields(
      {
        description: {
          getVal: (model) => apihelper.getAttr(model, "description"),
          setVal: (fields) =>
            createEmptyModelAttributes(EntityTypes.TIMEREGISTRATION, {
              description: fields.description.value,
            }),
          getPath: () => "attributes.description",
          isRequired: true,
        },
        startTime: {
          getVal: (model) =>
            moment
              .parseZone(apihelper.getAttr(model, "startTime") as string)
              .toDate(),
          setVal: (fields) =>
            createEmptyModelAttributes(EntityTypes.TIMEREGISTRATION, {
              startTime: moment(fields.startTime.value).format(),
            }),
          getPath: () => "attributes.startTime",
          isRequired: true,
        },
        endTime: {
          getVal: (model) =>
            moment
              .parseZone(apihelper.getAttr(model, "endTime") as string)
              .toDate(),
          setVal: (fields) =>
            createEmptyModelAttributes(EntityTypes.TIMEREGISTRATION, {
              endTime: moment(fields.endTime.value).format(),
            }),
          getPath: () => "attributes.endTime",
          isRequired: true,
        },
        billable: {
          getVal: (model) => apihelper.getAttr(model, "isBillable"),
          setVal: (fields) =>
            createEmptyModelAttributes(EntityTypes.TIMEREGISTRATION, {
              isBillable: fields.billable.value,
            }),
          getPath: () => "attributes.isBillable",
          isRequired: true,
        },
        statusId: {
          getVal: (model) =>
            apihelper.getRelId(model, "timeRegistrationStatus"),
          setVal: (fields) =>
            createEmptyModelRelationships(EntityTypes.TIMEREGISTRATION, {
              timeRegistrationStatus: {
                id: fields.statusId.value,
                type: EntityTypes.TIMEREGISTRATIONSTATUS,
              },
            }),
          getPath: () => "relationships.timeRegistrationStatus.data.id",
          isRequired: true,
        },
        resourceId: {
          getVal: (model) => apihelper.getRelId(model, "resource"),
          setVal: (fields) =>
            createEmptyModelRelationships(EntityTypes.TIMEREGISTRATION, {
              resource: {
                id: fields.resourceId.value,
                type: EntityTypes.RESOURCE,
              },
            }),
          getPath: () => "relationships.resource.data.id",
          isRequired: true,
        },
        projectId: {
          getVal: (model) => apihelper.getRelId(model, "project"),
          setVal: (fields) =>
            createEmptyModelRelationships(EntityTypes.TIMEREGISTRATION, {
              project: {
                id: fields.projectId.value,
                type: EntityTypes.PROJECT,
              },
            }),
          getPath: () => "relationships.project.data.id",
          isRequired: false,
        },
        contractRoleId: {
          getVal: (model) => apihelper.getRelId(model, "contractRole"),
          setVal: (fields) =>
            createEmptyModelRelationships(EntityTypes.TIMEREGISTRATION, {
              contractRole: {
                id: fields.contractRoleId.value,
                type: EntityTypes.CONTRACTROLE,
              },
            }),
          getPath: () => "relationships.contractRole.data.id",
          isRequired: false,
        },
      },
      model
    );
  }

  static getDerivedStateFromProps(
    props: TimeRegistrationFormProps,
    curState: TimeRegistrationFormState
  ): TimeRegistrationFormState | null {
    // pass onSave and propage changes only fires when a button is pressed
    const isControlled = !props.onSave;
    let nextState: TimeRegistrationFormState | null = null;
    if (isControlled) {
      const fields = TimeRegistrationForm._initializeFieldsFromModel(
        props.model
      );
      const changedFields: string[] = Object.keys(curState.fields).filter(
        (fieldName) =>
          !formValEquals(
            fieldName,
            curState.fields[fieldName].value,
            fields[fieldName].value
          )
      );
      const hasChangedFields = changedFields.length > 0;
      if (hasChangedFields) {
        const o = { fields } as TimeRegistrationFormState;
        if (
          changedFields.includes("startTime") ||
          changedFields.includes("endTime")
        ) {
          o.rangeValue = getRangeValue(
            fields.startTime.value,
            fields.endTime.value
          );
        }
        const postponeDescriptionUpdate =
          changedFields.includes("description") &&
          curState.delayedWriteBackTimer.isPending();
        if (postponeDescriptionUpdate) {
          fields.description = curState.fields.description;
        }
        nextState = Object.assign({}, curState, o) as TimeRegistrationFormState;
      }
    }
    return nextState;
  }

  componentWillUnmount() {
    this.state.delayedWriteBackTimer.finish();
  }

  _onDescriptionChange(
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newDescription?: string
  ) {
    const value = newDescription;
    const fieldName = "description";
    let validationMessage = "";
    if (!value) {
      validationMessage = "Description must be provided";
    }
    this._updateFieldStateAndSchedulePropagation(
      fieldName,
      value,
      validationMessage
    );
  }

  _onStartTimeChange(startTime: Date) {
    if (startTime) {
      const startIsBefore =
        startTime <
        new Date(apihelper.getAttr(this.props.model, "endTime") as string);
      const fields = this._updateFieldStateAndPropagateNow(
        "startTime",
        startTime,
        !startIsBefore ? "Start time should be before end time." : undefined
      );
      this.setState({
        rangeValue: getRangeValue(startTime, fields.endTime.value),
      });
    }
  }

  _onEndTimeChange(endTime: Date) {
    if (endTime) {
      const endIsLater =
        new Date(apihelper.getAttr(this.props.model, "startTime") as string) <
        endTime;
      const fields = this._updateFieldStateAndPropagateNow(
        "endTime",
        endTime,
        !endIsLater ? "End time should be after start time." : undefined
      );
      this.setState({
        rangeValue: getRangeValue(fields.startTime.value, endTime),
      });
    }
  }

  _updateFieldStateAndPropagateNow(
    name: string | SingleFormFieldValues,
    value?: any,
    validationMessage?: string
  ) {
    const fields = this._updateFieldState(name, value, validationMessage);
    if (!this.props.onSave) {
      // isControlled
      this.propagateFieldValueChange(this._getModelChanges(fields));
    }
    return fields;
  }

  _updateFieldStateAndSchedulePropagation(
    name: string,
    value?: any,
    validationMessage?: string
  ) {
    this._updateFieldState(name, value, validationMessage);
    this.state.delayedWriteBackTimer.touch();
  }

  _onTimeRangeInputChange(rangeValues: RangeValue) {
    this.setState({ rangeValue: rangeValues });
  }

  _onTimeRangeInputChangeComplete(rangeValues: RangeValue) {
    const { min, max } = rangeValues;
    const curStartTime = this.state.fields.startTime.value;

    this._updateFieldStateAndPropagateNow({
      startTime: {
        value: timeFromValue(min, curStartTime),
        validationMessage: "",
      },
      endTime: {
        value: timeFromValue(max, curStartTime),
        validationMessage: "",
      },
    });
  }

  _onToggleBillable(
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean
  ) {
    this._updateFieldStateAndPropagateNow("billable", isChecked);
  }

  _onStatusIdChange(
    event: React.FormEvent<HTMLDivElement>,
    selectedStatus?: IDropdownOption,
    index?: number
  ) {
    const { allTimeRegistrationStatus } = this.props;
    const status = allTimeRegistrationStatus.find(
      (s) =>
        selectedStatus && apihelper.entityHasId(s, selectedStatus.key as string)
    );
    this._updateFieldStateAndPropagateNow(
      "statusId",
      status ? apihelper.getEntityId(status) : null
    );
  }

  _onResourceIdChange(selectedResources: ResourceObjects) {
    this._updateFieldStateAndPropagateNow(
      "resourceId",
      selectedResources
        ? apihelper.getEntityId(selectedResources[selectedResources.length - 1])
        : null
    );
  }

  _onProjectIdChange(selectedProject: ResourceObject | null) {
    if (selectedProject === null) {
      return;
    }
    const {
      projectResources,
      contractRoles,
      resourcesForSelectedProject,
      projects,
    } = this.props;
    const { startTime, resourceId, contractRoleId } = this.state.fields;

    const selectedResource: ResourceObject | undefined =
      resourcesForSelectedProject.find((r) =>
        apihelper.entityHasId(r, resourceId.value)
      );

    const selectedProjectId = selectedProject
      ? apihelper.getEntityId(selectedProject)
      : null;
    const change: SingleFormFieldValues = {
      projectId: { value: selectedProjectId },
    };

    const applicableContractRoles = getAssignableContractRolesForAllocation(
      contractRoles,
      startTime.value,
      projectResources,
      selectedResource as ResourceObject,
      projects,
      selectedProjectId as string
    );
    if (
      !storehelper.findById(applicableContractRoles, contractRoleId.value) &&
      applicableContractRoles.length > 0
    ) {
      // the contract role has to be changed as well because the current contract role is not applicable on the project being switched to
      const nextContractRoleId = apihelper.getEntityId(
        applicableContractRoles[0]
      );
      change.contractRoleId = { value: nextContractRoleId };
    }

    this._updateFieldStateAndPropagateNow(change);
  }

  _onContractRoleIdChange(selectedContractRole: ResourceObject | null) {
    if (selectedContractRole === null) {
      return;
    }

    const change: SingleFormFieldValues = {
      contractRoleId: {
        value: selectedContractRole
          ? apihelper.getEntityId(selectedContractRole)
          : null,
      },
    };

    const billabilityType = this.props.allBillabilityTypes.find((bt) =>
      apihelper.entityHasId(
        bt,
        apihelper.getRelId(selectedContractRole, "billabilityType") as string
      )
    );
    const isContractRoleBillable = isBillabilityTypeBillable(billabilityType);

    change.billable = { value: !!isContractRoleBillable };
    // Set timereg billability to default for new contract role
    this._updateFieldStateAndPropagateNow(change);
  }

  private propagateFieldValueChange(
    changes: ResourceObject | null = null,
    model: ResourceObject = this.props.model
  ) {
    this.state.delayedWriteBackTimer.cancel();
    changes = changes ? changes : this._getModelChanges();
    if (Object.keys(changes).length > 0) {
      this.props.onChange && this.props.onChange(changes, model);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<TimeRegistrationFormProps>,
    prevState: Readonly<TimeRegistrationFormState>,
    snapshot?: any
  ): void {
    if (
      this.props.model !== prevProps.model &&
      this.state.delayedWriteBackTimer.isPending()
    ) {
      if (
        this.props.compareModels &&
        !this.props.compareModels(prevProps.model, this.props.model)
      ) {
        // the logical model has changed, meaning that it is not the same logical timeregistration anymore.
        // this happens if we switch time registration and not by what would be regular updated or first persistance of the time registration.
        // since we're not editing another time registration, whatever changes still in the pipeline must be flushed to the appropriate model.
        this.propagateFieldValueChange(null, prevProps.model);
      }
    }
  }

  _onFieldBlur() {
    this.propagateFieldValueChange();
  }

  render() {
    const {
      allBillabilityTypes,
      allTimeRegistrationStatus,
      autoShowDetails,
      canDelete,
      canEdit,
      contractRoles,
      fieldStatus,
      isUnderDelete,
      model,
      projects,
      projectResources,
      resourcesForSelectedProject,
      users,
      notifications,
      contracts,
      currentUserPermissionSet,
      resourceForCurrentUser,
    } = this.props;

    const {
      startTime,
      endTime,
      description,
      resourceId,
      contractRoleId,
      billable,
      statusId,
      projectId,
    } = this.state.fields;

    const selectedResources: Array<ResourceObject> = [];
    const selectedResource: ResourceObject | undefined =
      resourcesForSelectedProject.find((r) =>
        apihelper.entityHasId(r, resourceId.value)
      );
    if (selectedResource) {
      selectedResources.push(selectedResource);
    }

    const selectedProject = projects.find((p) =>
      apihelper.entityHasId(p, projectId.value)
    );
    const projectResourcesForSelectedResource = selectedResource
      ? projectResourceSelectors.filterByResourceAllocation(
          projectResources,
          selectedResource,
          projects
        )
      : [];

    const contractRolesBySelectedProjectAndResource = contractRoles.filter(
      (contractRole) =>
        selectedProject &&
        apihelper.getRelId(contractRole, "contract") ===
          apihelper.getRelId(selectedProject, "contract") &&
        projectResourcesForSelectedResource.some(
          (projectResource) =>
            apihelper.relRefersToEntity(
              projectResource,
              "contractRole",
              contractRole
            ) &&
            apihelper.relRefersToEntity(
              projectResource,
              "project",
              selectedProject
            )
        )
    );
    const activeContractRoles = contractRoleSelectors
      .filterByActiveInPeriod(
        contractRolesBySelectedProjectAndResource,
        startTime.value,
        endTime.value
      )
      .filter(
        (contractRole) =>
          selectedProject &&
          projectResourcesForSelectedResource.some(
            (projectResource) =>
              projectResourceSelectors.isActiveInPeriod(
                projectResource,
                startTime.value,
                endTime.value
              ) &&
              apihelper.relRefersToEntity(
                projectResource,
                "contractRole",
                contractRole
              ) &&
              apihelper.relRefersToEntity(
                projectResource,
                "project",
                selectedProject
              )
          )
      );
    const expiredContractRoleIds = contractRolesBySelectedProjectAndResource
      .difference(activeContractRoles)
      .map(apihelper.getEntityId) as string[];

    const selectedContractRole = contractRoles.find((cr) =>
      apihelper.entityHasId(cr, contractRoleId.value)
    );

    const fieldNames = [
      "description",
      "resourceId",
      "projectId",
      "contractRoleId",
      "billable",
      "statusId",
    ];
    const curFieldStatus: Record<string, string> = {};
    const fieldIsQueued: Record<string, boolean> = {};
    fieldNames.forEach((n) => {
      const field = this.state.fields[n];

      if (field.getPath) {
        curFieldStatus[n] = fieldStatus && fieldStatus(model, field.getPath());
        fieldIsQueued[n] =
          curFieldStatus[n] === QUEUED_VALID ||
          curFieldStatus[n] === QUEUED_INVALID;
      }
    }, this);

    const iconPropsQueued = { iconName: Icons.ICON_NAME_CHANGE_QUEUED };
    const iconProps = (fieldStatus: string, isChanged: boolean) =>
      fieldStatus === QUEUED_VALID || fieldStatus === QUEUED_INVALID
        ? iconPropsQueued
        : this._getIconProps(isChanged);
    const createdBy = users.find((u) =>
      apihelper.relRefersToEntity(model, "createdByUser", u)
    );

    const hasAccessToBillability =
      selectedContractRole &&
      apihelper.relHasReference(selectedContractRole, "billabilityType");
    const billabilityType =
      selectedContractRole &&
      allBillabilityTypes.find((bt) =>
        apihelper.relRefersToEntity(selectedContractRole, "billabilityType", bt)
      );
    const timeRegsContractRoleIsBillable =
      isBillabilityTypeBillable(billabilityType);
    const contractRoleDefaultBillability =
      hasAccessToBillability && timeRegsContractRoleIsBillable;

    const selectedContract =
      selectedContractRole &&
      contracts.find((contract) =>
        apihelper.relRefersToEntity(selectedContractRole, "contract", contract)
      );
    const hasProjectRead = currentUserPermissionSet.hasProjectRead(
      projectId.value
    );
    const hasProjectWrite = currentUserPermissionSet.hasProjectWrite(
      projectId.value
    );
    const hasClientRead =
      selectedContract &&
      currentUserPermissionSet.hasClientRead(
        apihelper.getRelId(selectedContract, "client") as string
      );
    const hasClientWrite =
      selectedContract &&
      currentUserPermissionSet.hasClientWrite(
        apihelper.getRelId(selectedContract, "client") as string
      );
    const hasAnyPermissions =
      hasProjectRead || hasProjectWrite || hasClientRead || hasClientWrite;

    const apiNotifications = notifications.filter(
      (notification) =>
        (notification.type === NotificationType.ApiCallPatch ||
          notification.type === NotificationType.ApiCallPost) &&
        notification.level === NotificationLevel.Info
    );
    const isUploading = apiNotifications.length > 0;
    const errorNotifications = notifications.filter(
      (notification) => notification.level === NotificationLevel.Error
    );
    const hasErrors = errorNotifications.length > 0;

    return (
      <div className="novatime-timeregistration-form">
        <Grid>
          <Row>
            <Column sm={3} md={2} lg={1}>
              <TimeInputField
                label={"From"}
                showSeconds={false}
                value={startTime.value}
                disabled={isUnderDelete || !canEdit}
                onChange={this._onStartTimeChange}
                onClose={this._onFieldBlur}
                required={!!startTime.isRequired}
                minuteStep={15}
                errorMessage={startTime.validationMessage}
              />
            </Column>
            <Column sm={3} md={2} lg={1}>
              <TimeInputField
                label={"To"}
                showSeconds={false}
                value={endTime.value}
                disabled={isUnderDelete || !canEdit}
                onChange={this._onEndTimeChange}
                onClose={this._onFieldBlur}
                required={!!endTime.isRequired}
                minuteStep={15}
                errorMessage={endTime.validationMessage}
              />

              <div className="height2em hiddenLgUp" />
            </Column>
            <Column sm={12} md={8} lg={10}>
              <Label>&nbsp;</Label>
              <TextField
                autoFocus={!model}
                placeholder="What did you do?"
                iconProps={iconProps(
                  curFieldStatus.description,
                  description.isChanged
                )}
                onChange={this._onDescriptionChange}
                required={description.isRequired as boolean}
                onBlur={this._onFieldBlur}
                multiline
                disabled={isUnderDelete || !canEdit}
                rows={4}
                value={description.value}
                errorMessage={description.validationMessage}
              />
            </Column>
          </Row>
          {hasErrors ? (
            <Row>
              <Column sm={12} md={12} lg={12}>
                <Stack enableScopedSelectors tokens={verticalGapStackTokens}>
                  {errorNotifications.map((notification, idx) => (
                    <StackItem disableShrink key={`notif-idx-${idx}`}>
                      <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                      >
                        <div style={{ paddingLeft: "30px" }}>
                          <NotificationActivityItem
                            notification={notification}
                          />
                        </div>
                      </MessageBar>
                    </StackItem>
                  ))}
                </Stack>
              </Column>
            </Row>
          ) : null}
          <Row>
            <div style={{ paddingTop: "15px" }}></div>
            <div className="clearfix">
              <div style={{ float: "right" }}>
                <Stack
                  enableScopedSelectors
                  horizontal
                  disableShrink
                  tokens={{ childrenGap: 20 }}
                >
                  {isUploading ? <Spinner size={SpinnerSize.medium} /> : null}
                  {apihelper.getEntityId(model)
                    ? this._renderDeleteButtons(true, !canDelete)
                    : null}
                </Stack>
              </div>
            </div>
          </Row>
        </Grid>
        <Grid>
          <Expandable
            expandText="View all form fields"
            collapseText={autoShowDetails ? "" : "Hide fields"}
            initialExpanded={autoShowDetails}
          >
            <Row>
              <Column sm={12} md={12} lg={6} xl={4}>
                <RestrictedProjectPicker
                  selectedProjectId={projectId.value}
                  allProjects={projects}
                  contractRoles={contractRoles}
                  isQueuedForWrite={fieldIsQueued.projectId}
                  disabled={isUnderDelete || !canEdit}
                  projectResources={projectResources}
                  contracts={contracts}
                  selectedResource={selectedResource}
                  resourceForCurrentUser={resourceForCurrentUser}
                  currentUserPermissionSet={currentUserPermissionSet}
                  timeRegistrationStartTime={startTime.value}
                  onChange={this._onProjectIdChange}
                />
              </Column>
              <Column sm={12} md={12} lg={6} xl={4}>
                {hasAnyPermissions ? (
                  <div>
                    <Label>Resource</Label>
                    <ResourceTagPicker
                      required={resourceId.isRequired} //bliver ikke brugt?
                      resources={resourcesForSelectedProject}
                      placeholder="choose resource"
                      users={users}
                      selectedResources={selectedResources}
                      disabled={!hasClientWrite || isUnderDelete || !canEdit}
                      icon={
                        fieldIsQueued.resourceId
                          ? Icons.ICON_NAME_CHANGE_QUEUED
                          : undefined
                      }
                      onResourceSelected={this._onResourceIdChange}
                      itemLimit={1}
                    />
                  </div>
                ) : null}
              </Column>
              <Column sm={12} md={12} lg={6} xl={4}>
                {hasClientRead ? (
                  <div>
                    <BasePickerSimple
                      label="Contract role"
                      onEntitySelected={this._onContractRoleIdChange}
                      entities={contractRolesBySelectedProjectAndResource}
                      selectedEntity={selectedContractRole}
                      disabledEntityIds={expiredContractRoleIds}
                      disabled={!hasClientWrite || isUnderDelete || !canEdit}
                      icon={
                        fieldIsQueued.projectId
                          ? Icons.ICON_NAME_CHANGE_QUEUED
                          : undefined
                      }
                    />
                  </div>
                ) : null}
              </Column>
            </Row>
            <Row>
              <Column sm={12} md={12} lg={6} xl={4}>
                <Label>Status</Label>
                <div className="novatime-combo-box">
                  <Dropdown
                    onChange={this._onStatusIdChange}
                    options={allTimeRegistrationStatus.map(
                      (n) =>
                        ({
                          key: apihelper.getEntityId(n),
                          text: apihelper.getAttr(n, "name"),
                        } as IDropdownOption)
                    )}
                    selectedKey={
                      statusId.value ? statusId.value + "" : undefined
                    }
                    disabled={!hasProjectWrite || isUnderDelete || !canEdit}
                  />
                  <Icon
                    className="indicator"
                    iconName={
                      fieldIsQueued.statusId
                        ? Icons.ICON_NAME_CHANGE_QUEUED
                        : undefined
                    }
                  />
                </div>
              </Column>
              <Column sm={12} md={12} lg={6} xl={4}>
                {hasAnyPermissions ? (
                  <div>
                    <Label>Billable</Label>
                    <Checkbox
                      onChange={this._onToggleBillable}
                      checked={
                        typeof billable.value == "boolean"
                          ? billable.value
                          : contractRoleDefaultBillability
                      }
                      indeterminate={
                        typeof billable.value != "boolean" &&
                        !hasAccessToBillability
                      }
                      disabled={
                        !hasClientWrite ||
                        !timeRegsContractRoleIsBillable ||
                        isUnderDelete ||
                        !canEdit
                      }
                    />
                    <Icon
                      iconName={
                        fieldIsQueued.billable
                          ? Icons.ICON_NAME_CHANGE_QUEUED
                          : undefined
                      }
                    />
                  </div>
                ) : null}
              </Column>
            </Row>
            <Row>
              <Column sm={4}>
                <Label>Created by</Label>
                <Label>
                  {createdBy && apihelper.getAttr(createdBy, "name")}
                </Label>
              </Column>
              <Column sm={4}>
                <Label>Id</Label>
                <Label>{apihelper.getEntityId(model) || ""}</Label>
              </Column>
            </Row>
          </Expandable>
        </Grid>
      </div>
    );
  }
}
