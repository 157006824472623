import { createSelector } from "reselect";
import { allClients, allClientsByCurrentUserAccount } from "../reducers/client";
import { allContracts } from "../reducers/contract";
import { allProjectsByCurrentUserAccount } from "../reducers/project";
import * as apihelper from "../apihelper";
import * as storehelper from "../storehelper";
import { allContractRoles } from "../reducers/contractRole";
import BillableSet from "../../lib/BillableSet";
import { allBillabilityTypes } from "../reducers/billabilityTypes";
import {
  allResourceType,
  employeeResourceType,
} from "../reducers/resourceType";
import { currentUserAccount } from "../reducers/account";
import { allResources } from "../reducers/resource";
import * as reducerLockedTimePeriodSelectors from "../reducers/lockedTimePeriod";
import LockedTimePeriodIndex from "../../lib/LockedTimePeriodIndex";
import * as reducerProjectResourceSelectors from "../reducers/projectResource";
import * as writeBackQueueReducerSelectors from "../reducers/writeBackQueue";
import * as reducerProjectSelectors from "../reducers/project";
import * as reducerResourceSelectors from "../reducers/resource";
import * as reducerContractRoleSelectors from "../reducers/contractRole";
import * as reducerContractSelectors from "../reducers/contract";
import * as reducerClientSelectors from "../reducers/client";
import * as reducerAccountSelectors from "../reducers/account";
import * as appDomainSelectors from "../app/domain";
import * as notificationSelectors from "../app/notification";
import * as reducerTimeRegistrationSelectors from "../reducers/timeRegistration";
import HourSet from "../../lib/HourSet";
import { lexicalInsensitiveNameSortFunc } from "../../lib/sorting";
import * as absencePeriodSelectors from "../reducers/absencePeriod";
import StateMerge from "../../lib/stateMerge/StateMerge";

const SHOW_INACTIVE_RESOURCE_ID = "inactiveresources";
const inactiveResources = {
  attributes: {
    name: "Inactive Resources",
    description: "Show inactive resources",
  },
  id: SHOW_INACTIVE_RESOURCE_ID,
  type: "ResourceType",
  relationships: {},
};

export const selectedProjectId = (state) =>
  state.page.resourceManagement.filter.projectId;

export const isAllProjectsSelected = (state) =>
  state.page.resourceManagement.filter.projectId == null;

export const selectedClientId = (state) =>
  state.page.resourceManagement.filter.clientId;

export const isAllClientsSelected = (state) =>
  state.page.resourceManagement.filter.clientId == null;

export const selectedResourceTypeIds = (state) =>
  state.page.resourceManagement.filter.resourceTypeIds;

export const isSelectedResourceTypeIdsEmpty = (state) =>
  state.page.resourceManagement.filter.resourceTypeIds.equals([]);

const startTimeFilter = (state) =>
  state.page.resourceManagement.filter.startTime;

export const startTime = createSelector([startTimeFilter], (st) =>
  typeof st === "string" ? new Date(st) : st
);

const endTimeFilter = (state) => state.page.resourceManagement.filter.endTime;

export const endTime = createSelector([endTimeFilter], (et) =>
  typeof et === "string" ? new Date(et) : et
);

export const filter = (state) => state.page.resourceManagement.filter;

export const selectedTimeRegistration = (state) =>
  state.page.resourceManagement.timeRegistration;

export const showInactiveResources = (state) =>
  state.page.resourceManagement.filter.showInactiveResources;

export const viewDesc = (state) => state.page.resourceManagement.viewDesc;

export const showProcessingDialog = (state) =>
  state.page.resourceManagement.showProsseingDialog;

export const processingProgression = (state) =>
  state.page.resourceManagement.processingProgression;

export const allProjectResourcesFromProjectsByCurrentUserAccount =
  createSelector(
    [
      reducerProjectResourceSelectors.allProjectResources,
      allProjectsByCurrentUserAccount,
    ],
    (projectResources, projects) =>
      projectResources.filter((pr) =>
        projects.some((p) => p.id === apihelper.getRelId(pr, "project"))
      )
  );

export const selectedProject = createSelector(
  [selectedProjectId, reducerProjectSelectors.allProjects],
  (projectId, allProjects) =>
    allProjects.find((project) => apihelper.entityHasId(project, projectId))
);

export const selectedClient = createSelector(
  [allClients, selectedClientId],
  (clients, clientId) => clients.find((c) => apihelper.entityHasId(c, clientId))
);

const timeRegistrationsByPeriod = createSelector(
  [reducerTimeRegistrationSelectors.allTimeRegistrations, startTime, endTime],
  (tregs, st, et) =>
    reducerTimeRegistrationSelectors.filterByPeriod(tregs, st, et)
);

const timeRegistrationsByProjectAndPeriod = createSelector(
  [timeRegistrationsByPeriod, selectedProject],
  (tregs, project) =>
    reducerTimeRegistrationSelectors.filterByProjects(tregs, project)
);

const timeRegistrationsByClientAndPeriod = createSelector(
  [
    timeRegistrationsByPeriod,
    selectedClient,
    reducerContractSelectors.allContracts,
    reducerProjectSelectors.allProjects,
  ],
  (tregs, client, allContracts, allProjects) => {
    const clientContracts = reducerContractSelectors.filterByClients(
      allContracts,
      client
    );
    const clientProjects = reducerProjectSelectors.filterByContracts(
      allProjects,
      clientContracts
    );
    return reducerTimeRegistrationSelectors.filterByProjects(
      tregs,
      clientProjects
    );
  }
);

export const stateMerge = createSelector(
  [
    selectedProject,
    selectedClient,
    timeRegistrationsByPeriod,
    timeRegistrationsByProjectAndPeriod,
    timeRegistrationsByClientAndPeriod,
  ],
  (
    project,
    client,
    tregsByPeriod,
    tregsByPeriodAndProject,
    tregsByPeriodAndClient
  ) => {
    const tregs = !!project
      ? tregsByPeriodAndProject
      : !!client
      ? tregsByPeriodAndClient
      : tregsByPeriod;

    return new StateMerge(tregs);
  }
);

export const selectedResourceTypeIdsEmployees = createSelector(
  [
    selectedResourceTypeIds,
    isSelectedResourceTypeIdsEmpty,
    employeeResourceType,
  ],
  (
    selectedResourceTypeIds,
    isSelectedResourceTypeIdsEmpty,
    employeeResourceType
  ) => {
    if (employeeResourceType && isSelectedResourceTypeIdsEmpty) {
      return [employeeResourceType.id];
    } else {
      return selectedResourceTypeIds;
    }
  }
);

export const selectedResourceTypes = createSelector(
  [allResourceType, selectedResourceTypeIdsEmployees, showInactiveResources],
  (resourceType, selectedResourceTypeIds, showInactiveResources) => {
    let result = resourceType.filter((rt) =>
      selectedResourceTypeIds.includes(apihelper.getEntityId(rt))
    );
    if (showInactiveResources) return [...result, inactiveResources];
    return result;
  }
);

export const resourceTypeOptions = createSelector(
  [allResourceType],
  (allResourceType) => {
    let result = allResourceType.slice().sort(lexicalInsensitiveNameSortFunc);
    return [...result, inactiveResources];
  }
);

export const allProjectResourcesBySelectedProject = createSelector(
  [reducerProjectResourceSelectors.allProjectResources, selectedProject],
  (projectResources, project) =>
    storehelper
      .filterByReferenceToRelatedEntities(projectResources, project, "project")
      .flatten()
);

export const allContractsBySelectedClient = createSelector(
  [allContracts, selectedClient],
  (contracts, client) =>
    reducerContractSelectors.filterByClients(contracts, client)
);

export const allProjectsBySelectedClient = createSelector(
  [reducerProjectSelectors.allProjects, allContractsBySelectedClient],
  (projects, contracts) =>
    reducerProjectSelectors.filterByContracts(projects, contracts)
);

export const allProjectResourcesBySelectedClient = createSelector(
  [
    reducerProjectResourceSelectors.allProjectResources,
    allProjectsBySelectedClient,
  ],
  (projectResources, projects) =>
    reducerProjectResourceSelectors.filterByProjects(projectResources, projects)
);

export const allContractsByCurrentUserAccount = createSelector(
  [allContracts, allClientsByCurrentUserAccount],
  (contracts, clients) => {
    let clientIds = clients.map(apihelper.getEntityId);
    return contracts.filter((co) =>
      clientIds.includes(apihelper.getRelId(co, "client"))
    );
  }
);

export const allContractRolesByCurrentUserAccount = createSelector(
  [allContractRoles, allContractsByCurrentUserAccount],
  (contractRoles, contracts) => {
    let contractIds = contracts.map(apihelper.getEntityId);
    return contractRoles.filter((cr) =>
      contractIds.includes(apihelper.getRelId(cr, "contract"))
    );
  }
);

export const selectedProjectResources = createSelector(
  [
    selectedProject,
    selectedClient,
    isAllClientsSelected,
    allProjectResourcesBySelectedProject,
    allProjectResourcesBySelectedClient,
    appDomainSelectors.allProjectResourcesForSelectedAccount,
  ],
  (
    project,
    client,
    isAllClientsSelected,
    projectResourcesByproject,
    projectResourcesByClient,
    projectResourcesByAccount
  ) => {
    return project
      ? projectResourcesByproject
      : client
      ? projectResourcesByClient
      : isAllClientsSelected
      ? projectResourcesByAccount
      : [];
  }
);

export const contractRolesBySelectedProjectResources = createSelector(
  [allContractRoles, selectedProjectResources],
  (contractRoles, projectResources) => {
    let contractRoleIds = projectResources.map((pr) =>
      apihelper.getRelId(pr, "contractRole")
    );
    return contractRoles.filter((cr) =>
      contractRoleIds.includes(apihelper.getEntityId(cr))
    );
  }
);

export const selectedContractRole = createSelector(
  [allContractRoles, selectedTimeRegistration],
  (allContractRoles, timeReg) =>
    allContractRoles.find((cr) =>
      apihelper.entityHasId(cr, apihelper.getRelId(timeReg, "contractRole"))
    )
);

export const isSelectedTimeRegistrationBillable = createSelector(
  [allBillabilityTypes, selectedContractRole],
  (billabilityTypes, selectedContractRole) => {
    const bType = billabilityTypes.find((bt) =>
      apihelper.relRefersToEntity(selectedContractRole, "billabilityType", bt)
    );
    return !!bType && BillableSet.isEntityBillable(bType);
  }
);

export const selectedProjects = createSelector(
  [
    selectedClient,
    allProjectsBySelectedClient,
    allProjectsByCurrentUserAccount,
  ],
  (client, projectsByClient, projectsByAccount) =>
    client ? projectsByClient : projectsByAccount
);

export const contractsBySelectedProjects = createSelector(
  [allContracts, selectedProjects],
  (contracts, projects) => {
    let contractIdsFromProjects = projects.map((p) =>
      apihelper.getRelId(p, "contract")
    );
    return contracts.filter((c) =>
      contractIdsFromProjects.includes(apihelper.getEntityId(c))
    );
  }
);

export const resourcesOfCurrentUserAccountOfSelectedType = createSelector(
  [currentUserAccount, selectedResourceTypeIds, allResources],
  (account, resourceTypeIds, resources) =>
    resources
      .filter((c) => apihelper.relRefersToEntity(c, "account", account))
      .filter((r) =>
        resourceTypeIds.includes(apihelper.getRelId(r, "resourceType"))
      )
);

export const lockedTimePeriodIndex = createSelector(
  [startTime, endTime, reducerLockedTimePeriodSelectors.allLockedTimePeriods],
  (startTime, endTime, allLockedTimePeriods) => {
    let ltps =
      startTime !== null && endTime !== null
        ? reducerLockedTimePeriodSelectors.lockedTimePeriodsIntersectingInterval(
            allLockedTimePeriods,
            startTime,
            endTime
          )
        : [];
    return new LockedTimePeriodIndex(ltps);
  }
);

export const resourcesForCurrentFilter = createSelector(
  [
    selectedProjectResources,
    reducerResourceSelectors.allResources,
    reducerContractRoleSelectors.allContractRoles,
    reducerContractSelectors.allContracts,
    reducerClientSelectors.allClients,
    reducerAccountSelectors.allAccounts,
    showInactiveResources,
  ],
  (
    projectResources,
    allResources,
    allContractRoles,
    allContracts,
    allClients,
    allAccounts,
    showInactiveResources
  ) =>
    reducerResourceSelectors
      .filterAssignableResources(
        allResources.filter(
          (resource) =>
            showInactiveResources || apihelper.getAttr(resource, "isActive")
        ),
        projectResources,
        allContractRoles,
        allContracts,
        allClients,
        allAccounts
      )
      .sort(storehelper.sortByAttr("name"))
);

export const hourSetForSelectedTimeRegistrations = createSelector(
  [
    stateMerge,
    selectedProjects,
    resourcesForCurrentFilter,
    selectedProjectResources,
    reducerClientSelectors.allClientsByCurrentUserAccountSortedByName,
    contractsBySelectedProjects,
    contractRolesBySelectedProjectResources,
  ],
  (
    stateMerge,
    projects,
    resources,
    projectResources,
    clients,
    contracts,
    contractRoles
  ) =>
    new HourSet(
      stateMerge,
      projects,
      resources,
      projectResources,
      clients,
      contracts,
      contractRoles
    )
);

export const absencePeriodsInPeriod = createSelector(
  [absencePeriodSelectors.allAbsencePeriods, startTime, endTime],
  (allAbsencePeriods, startTime, endTime) =>
    startTime !== null && endTime !== null
      ? absencePeriodSelectors.filterByPeriod(
          allAbsencePeriods,
          startTime,
          endTime
        )
      : []
);

export const selectedCell = createSelector(
  [
    (state) => state.page.resourceManagement.selectedCell,
    reducerResourceSelectors.allResources,
    stateMerge,
  ],
  (rawSelectedCell, allResources, stateMerge) =>
    rawSelectedCell === null
      ? null
      : {
          resource: reducerResourceSelectors.filterByResourceId(
            allResources,
            rawSelectedCell.resourceId
          ),
          date: rawSelectedCell.date && new Date(rawSelectedCell.date),
          timeRegistrations: stateMerge.getRegistrations({
            timeRegistrations: rawSelectedCell.timeRegistrationIds,
          }),
        }
);

export const contractRoleForSelectedTimeRegistration = createSelector(
  [selectedTimeRegistration, allContractRoles],
  (selectedTimeReg, allContractRoles) =>
    storehelper.filterByRelatedEntity(
      allContractRoles,
      selectedTimeReg,
      "contractRole"
    )
);

export const contractForSelectedTimeRegistration = createSelector(
  [allContracts, contractRoleForSelectedTimeRegistration],
  (allContracts, contractRole) =>
    storehelper.filterByRelatedEntity(allContracts, contractRole, "contract")
);

export const queueIdForSelectedTimeRegistration = createSelector(
  [selectedTimeRegistration, writeBackQueueReducerSelectors.queue],
  (selectedTimeReg, queue) => {
    const change = queue.findInQueue(selectedTimeReg);
    return change?.queueId;
  }
);

export const notificationsForSelectedTimeRegistration = createSelector(
  [
    queueIdForSelectedTimeRegistration,
    notificationSelectors.visibleNotifications,
  ],
  (queueId, notifications) =>
    notifications.filter(
      (notification) => queueId && notification?.location?.queueId === queueId
    )
);
