import { createSelector } from "reselect";
import { allAbsencePeriodComments } from "../reducers/absencePeriodComments";
import * as reducerResourceSelectors from "../reducers/resource";
import * as apihelper from "../apihelper";
import * as reducerUserSelectors from "../reducers/user";
import * as reducerLocaleSelectors from "../reducers/locale";
import * as storehelper from "../storehelper";
import * as reducerAbsencePeriodSelectors from "../reducers/absencePeriod";
import * as reducerTimeRegistrationSelectors from "../reducers/timeRegistration";
import * as reducerAbsencePeriodStatusSelectors from "../reducers/absencePeriodStatus";
import * as AbsencePeriodGroupStatus from "../../constants/AbsencePeriodGroupStatus";
import BusinessCalendar from "../../lib/businessCalendar";
import { ResourceObject, ResourceObjects } from "../../lib/models";
import * as appDomainSelectors from "../app/domain";
import * as datehelper from "../../lib/date";
import * as timeRegistrationHelpers from "../../lib/timeRegistration";

const calendar = new BusinessCalendar();

const sortByPeriodEnd = storehelper.sortByAttr("periodEnd");

export const selectedAbsencePeriodId = (state: any) =>
  state.page.absencePeriod.selectedAbsencePeriodId;

export const selectedResourceId = (state: any) =>
  state.page.absencePeriod.selectedResourceId;

export const selectedYear = (state: any) =>
  state.page.absencePeriod.selectedYear;

export const selectedAbsencePeriod = createSelector(
  [reducerAbsencePeriodSelectors.allAbsencePeriods, selectedAbsencePeriodId],
  (absencePeriods, id) =>
    absencePeriods.find((ap: ResourceObject) => apihelper.entityHasId(ap, id))
);

export const selectedResource = createSelector(
  [reducerResourceSelectors.allResources, selectedResourceId],
  (resources, resourceId) =>
    storehelper.findById(resources, resourceId) as ResourceObject
);

export const userForSelectedResource = createSelector(
  [selectedResource, reducerUserSelectors.allUsers],
  (selectedResource, allUsers) =>
    selectedResource &&
    allUsers.find((user) =>
      apihelper.relRefersToEntity(selectedResource, "user", user)
    )
);
export const vacationHoursGranted = createSelector(
  [selectedResource],
  (selectedResource) =>
    selectedResource && apihelper.getAttr(selectedResource, "vacationHours")
      ? (apihelper.getAttr(selectedResource, "vacationHours") as number)
      : 0
);

export const selectedResourceLocaleName = createSelector(
  [selectedResource, reducerLocaleSelectors.allLocale],
  (selectedResource, allLocale) => {
    if (
      selectedResource &&
      apihelper.relHasReference(selectedResource, "employedAtLocale")
    ) {
      const l = reducerLocaleSelectors.localeByResource(
        allLocale,
        selectedResource
      );
      if (l) return l.attributes.name;
    } else return "dk";
  }
);

export const selectedAbsencePeriodsComments = createSelector(
  [allAbsencePeriodComments, selectedAbsencePeriodId],
  (comments, absencePeriodId) =>
    comments.filter((comment: ResourceObject) =>
      apihelper.relHasId(comment, "absencePeriod", absencePeriodId)
    )
);

export const vacationStartAndEndDate = createSelector(
  [selectedResource, selectedYear, appDomainSelectors.selectedAccount],
  (resource: ResourceObject, selectedYear: number, account: ResourceObject) => {
    const vacationYearStartDate = new Date(
      (resource
        ? apihelper.getAttr(resource, "vacationYearStart")
        : apihelper.getAttr(account, "defaultVacationResetDate")) as string
    );

    const vacationStartDate = new Date(
      selectedYear,
      vacationYearStartDate.getMonth(),
      vacationYearStartDate.getDate()
    );
    const vacationEndDate = new Date(vacationStartDate);
    vacationEndDate.setFullYear(selectedYear + 1);

    return { startDate: vacationStartDate, endDate: vacationEndDate };
  }
);

export const yearlyVacationRegistrationsBySelectedResource = createSelector(
  [
    selectedResource,
    vacationStartAndEndDate,
    appDomainSelectors.selectedAccountVacationProject,
    reducerTimeRegistrationSelectors.allTimeRegistrations,
  ],
  (resource, { startDate, endDate }, project, allTimeRegistrations) =>
    reducerTimeRegistrationSelectors.filterByPeriod(
      reducerTimeRegistrationSelectors.filterByProjects(
        reducerTimeRegistrationSelectors.filterByResources(
          allTimeRegistrations,
          resource
        ),
        project
      ),
      startDate,
      endDate
    )
);

export const allAbsencePeriodsByResource = createSelector(
  [selectedResource, reducerAbsencePeriodSelectors.allAbsencePeriods],
  (resource, allAbsencePeriods) =>
    reducerAbsencePeriodSelectors.filterByResource(allAbsencePeriods, resource)
);

export const absencePeriodDefault = createSelector(
  [reducerAbsencePeriodSelectors.allAbsencePeriods, vacationStartAndEndDate],
  (allAbsencePeriods, { startDate, endDate }) =>
    reducerAbsencePeriodSelectors.filterByPeriod(
      allAbsencePeriods,
      startDate,
      endDate
    )
);

export const allAbsencePeriodsForCurrentFilter = createSelector(
  [
    selectedResource,
    allAbsencePeriodsByResource,
    absencePeriodDefault,
    vacationStartAndEndDate,
  ],
  (
    selectedResource,
    allAbsencePeriodsByResource,
    absencePeriodDefault,
    { startDate, endDate }
  ) => {
    const absencePeriods = selectedResource
      ? allAbsencePeriodsByResource.slice().sort(sortByPeriodEnd)
      : absencePeriodDefault;
    return reducerAbsencePeriodSelectors.filterByPeriod(
      absencePeriods,
      startDate,
      endDate
    );
  }
);

export const selectedResourceSpentVacation = createSelector(
  [yearlyVacationRegistrationsBySelectedResource],
  (VacRegByResource) =>
    VacRegByResource.reduce(
      (acc: number, tr: ResourceObject) =>
        acc + timeRegistrationHelpers.getDuration(tr),
      0
    )
);

export const selectedResourceRemainingVacation = createSelector(
  [vacationHoursGranted, selectedResourceSpentVacation],
  (vacationHoursGranted, selectedResourceSpentVacation) =>
    Math.max(0, vacationHoursGranted - selectedResourceSpentVacation)
);

export const allAbsencePeriodsGrouped = createSelector(
  [
    allAbsencePeriodsForCurrentFilter,
    reducerAbsencePeriodStatusSelectors.absencePeriodStatusApproved,
    reducerAbsencePeriodStatusSelectors.absencePeriodStatusDenied,
    reducerAbsencePeriodStatusSelectors.absencePeriodStatusPending,
  ],
  (absencePeriods, approvedStatus, deniedStatus, pendingStatus) => {
    const result = absencePeriods.groupBy((ap) => {
      const isPending =
        pendingStatus &&
        apihelper.relRefersToEntity(ap, "absencePeriodStatus", pendingStatus);
      const isApproved =
        approvedStatus &&
        apihelper.relRefersToEntity(ap, "absencePeriodStatus", approvedStatus);
      const isDenied =
        deniedStatus &&
        apihelper.relRefersToEntity(ap, "absencePeriodStatus", deniedStatus);

      if (isPending) {
        return AbsencePeriodGroupStatus.PENDING.name;
      } else if (isApproved) {
        const periodStart = new Date(
          apihelper.getAttr(ap, "periodStart") as string
        );
        const periodEnd = new Date(
          apihelper.getAttr(ap, "periodEnd") as string
        );
        const now = new Date();
        const isNotStarted = datehelper.isBefore(now, periodStart);
        const isInThePast = datehelper.isAfter(now, periodEnd);
        return isNotStarted
          ? AbsencePeriodGroupStatus.APPROVED_UPCOMING.name
          : isInThePast
          ? AbsencePeriodGroupStatus.APPROVED_ARCHIVED.name
          : AbsencePeriodGroupStatus.APPROVED_ACTIVE.name;
      } else if (isDenied) {
        return AbsencePeriodGroupStatus.DENIED.name;
      }
    });
    return result as Map<string, ResourceObjects>;
  }
);

export const allApprovedAbsencePeriods = createSelector(
  [
    allAbsencePeriodsForCurrentFilter,
    reducerAbsencePeriodStatusSelectors.absencePeriodStatusApproved,
  ],
  (absencePeriods, approvedStatus) =>
    absencePeriods.filter(
      (ap) =>
        approvedStatus &&
        apihelper.relRefersToEntity(ap, "absencePeriodStatus", approvedStatus)
    )
);

export const selectedResourceAbsencePeriodsGrouped = createSelector(
  [allAbsencePeriodsGrouped],
  (absencePeriodMap) => Object.fromEntries(absencePeriodMap.entries())
);

export const selectedResourceApprovedAbsencePeriod = createSelector(
  [allApprovedAbsencePeriods, selectedResourceLocaleName],
  (approvedAbsencePeriods, selectedResourceLocaleName) =>
    approvedAbsencePeriods.reduce((acc, absencePeriod) => {
      const absencePeriodStartDate = new Date(
        apihelper.getAttr(absencePeriod, "periodStart") as string
      );
      const absencePeriodEndDate = new Date(
        apihelper.getAttr(absencePeriod, "periodEnd") as string
      );
      const bizDays = calendar.getNumberOfBusinessDaysBetween(
        absencePeriodStartDate,
        absencePeriodEndDate,
        selectedResourceLocaleName,
        true
      );
      return acc + bizDays * 8;
    }, 0)
);

export const usersForAbsencePeriods = createSelector(
  [allAbsencePeriodsForCurrentFilter, reducerUserSelectors.allUsers],
  (absencePeriods, allUsers) =>
    absencePeriods
      .map((ap) => [
        apihelper.getRelId(ap, "approvedByUser"),
        apihelper.getRelId(ap, "requestedByUser"),
      ])
      .flatten()
      .unique()
      .map((id) => storehelper.findById(allUsers, id))
      .filter((ap) => apihelper.isEntity(ap))
);

export const resourcesForAbsencePeriods = createSelector(
  [allAbsencePeriodsForCurrentFilter, reducerResourceSelectors.allResources],
  (absencePeriods, allResources) =>
    absencePeriods
      .map((ap) =>
        storehelper.findById(
          allResources,
          apihelper.getRelId(ap, "resource") as string
        )
      )
      .filter((ap) => apihelper.isEntity(ap))
);
