import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { Label } from "@fluentui/react/lib/Label";
import BasePickerSimple from "../../components/uifabricextensions/BasePickerSimple";
import * as EntityTypes from "../../constants/EntityTypes";
import * as projectsAndRolesActions from "../../actions/pages/projectsAndRoles";
import { isLoggedIn } from "../../selectors/reducers/auth";
import {
  allAccounts,
  currentUserAccount,
} from "../../selectors/reducers/account";
import { createEmptyModel } from "../../lib/emptyModelCreation";
import * as projectAndRolesSelectors from "../../selectors/pages/projectsAndRoles";
import * as projectStatusSelectors from "../../selectors/reducers/projectStatus";
import { Resource } from "../../components/uifabricextensions/Resource";
import * as icons from "../../constants/Icons";
import "../../lib/unique";
import * as appDomainSelectors from "../../selectors/app/domain";
import { sortByAttr } from "../../selectors/storehelper";
import { IconButton } from "@fluentui/react/lib/Button";
import { ContextualMenu } from "@fluentui/react";
import { Stack, StackItem } from "@fluentui/react/lib/Stack";
import SorterList from "../../components/lists/SorterList";
import * as apihelper from "../../selectors/apihelper";
import * as editorActions from "../../actions/EntityEditor";
import ProjectForm from "../../components/forms/ProjectForm";
import ProjectResourceForm from "../../components/forms/ProjectResourceForm";
import DeleteForm from "../../components/forms/DeleteForm";
import { DefaultCompareFunctions } from "../../components/lists/sorting";

const projectResourceListColumns = [
  {
    key: "pr-project",
    name: "Project",
    fieldName: "project",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "pr-resource",
    name: "Resource",
    fieldName: "resource",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "pr-contract-role",
    name: "Contract role",
    fieldName: "contractRole",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "pr-effective",
    name: "Effective date",
    fieldName: "effective",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "pr-expire",
    name: "Expire date",
    fieldName: "expire",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
];

const projectResourceListColumnSorters = {
  project: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.project),
  },
  resource: {
    compareFunction: DefaultCompareFunctions.Lexical((a) =>
      apihelper.getAttr(a.resource, "name")
    ),
  },
  contractRole: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.contractRole),
  },
  effective: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.effectiveTime),
  },
  expire: {
    compareFunction: DefaultCompareFunctions.Lexical((a) => a.expireTime),
  },
};

class ProjectsAndRoles extends Component {
  constructor(props) {
    super(props);
    this._clientOnChange = this._clientOnChange.bind(this);
    this._projectOnChange = this._projectOnChange.bind(this);

    this._onCreateProject = this._onCreateProject.bind(this);
    this._onEditProject = this._onEditProject.bind(this);

    this._onCreateProjectResource = this._onCreateProjectResource.bind(this);
    this._onSelectProjectResource = this._onSelectProjectResource.bind(this);
    this._onEditProjectResource = this._onEditProjectResource.bind(this);
    this._onRemoveProjectResource = this._onRemoveProjectResource.bind(this);
    this._onRemoveProject = this._onRemoveProject.bind(this);
  }

  componentDidMount() {
    this.props.actions.pageOpened();
  }

  // Client handlers
  _clientOnChange(element) {
    this.props.actions.selectClient(element);
  }

  // Project handlers
  _projectOnChange(element) {
    this.props.actions.selectProject(element);
  }

  _onCreateProject() {
    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.PROJECT,
        {
          needsDescription: true,
        },
        {
          account: this.props.selectedAccount,
        }
      ),
      ProjectForm
    );
  }

  _onEditProject() {
    this.props.actions.startEdit(this.props.selectedProject, ProjectForm);
  }

  _onRemoveProject() {
    this.props.actions.startEdit(this.props.selectedProject, DeleteForm);
  }

  // Project resource handlers
  _onSelectProjectResource(items) {
    this.props.actions.selectProjectResource(
      items.length > 0 ? items[0].data : null
    );
  }

  _onCreateProjectResource() {
    this.props.actions.startEdit(
      createEmptyModel(
        EntityTypes.PROJECTRESOURCE,
        {
          isDefaultProjectResource: false,
        },
        {
          project: this.props.selectedProject,
        }
      ),
      ProjectResourceForm
    );
  }

  _onEditProjectResource() {
    this.props.actions.startEdit(
      this.props.selectedProjectResource,
      ProjectResourceForm
    );
  }

  _onRemoveProjectResource() {
    this.props.actions.startEdit(
      this.props.selectedProjectResource,
      DeleteForm
    );
  }

  render() {
    let {
      selectedAccount,
      selectedClient,
      selectedProject,
      selectedProjectResource,
      allResourcesForSelectedAccount,
      projectResourceIdToContractRoleMap,
      allClientsForSelectedAccount,
      allProjectsForSelectedClient,
      allProjectResourcesForSelectedProject,
    } = this.props;

    let projectResourceDetailListItems = selectedProject
      ? allProjectResourcesForSelectedProject
          .map((pr) => {
            let contractRole =
                projectResourceIdToContractRoleMap[apihelper.getEntityId(pr)],
              effectiveDate = apihelper.getAttr(pr, "effectiveDate"),
              expiryDate = apihelper.getAttr(pr, "expiryDate");
            return {
              project: apihelper.getAttr(selectedProject, "name"),
              resource: allResourcesForSelectedAccount.find((r) =>
                apihelper.relRefersToEntity(pr, "resource", r)
              ),
              contractRole: contractRole
                ? apihelper.getAttr(contractRole, "name")
                : undefined,
              effective: effectiveDate.startsWith("0001")
                ? undefined
                : moment(effectiveDate).fromNow(),
              expire: expiryDate.startsWith("0001")
                ? undefined
                : moment(expiryDate).fromNow(),
              data: pr,
              effectiveTime: effectiveDate,
              expireTime: expiryDate,
            };
          })
          .sort(function (a, b) {
            return moment(apihelper.getAttr(b.data, "expiryDate")).diff(
              moment(apihelper.getAttr(a.data, "expiryDate"))
            );
          })
      : [];

    let resourcesGroupedByProjectResourceCombination =
      projectResourceDetailListItems
        .filter((pr) => !!pr.resource)
        .reduce((acc, pr) => {
          let resourceId = apihelper.getEntityId(pr.resource);
          let contractRoleId = apihelper.getRelId(pr.data, "contractRole");
          acc[resourceId] = acc[resourceId] || {};
          acc[resourceId][contractRoleId] =
            acc[resourceId][contractRoleId] || [];
          acc[resourceId][contractRoleId].push(pr);
          return acc;
        }, {});

    let isProjectResourceDirty = (pr) => {
      let resourcesWithSameProjectResourceCombination =
        resourcesGroupedByProjectResourceCombination[
          apihelper.getEntityId(pr.resource)
        ][apihelper.getRelId(pr.data, "contractRole")];
      // arrange by consecutive expiry date
      let result = false;
      if (
        resourcesWithSameProjectResourceCombination &&
        resourcesWithSameProjectResourceCombination.length > 1
      ) {
        let rwsprc = resourcesWithSameProjectResourceCombination.sort(
          (a, b) =>
            new Date(apihelper.getAttr(a.data, "expiryDate")).getTime() -
            new Date(apihelper.getAttr(b.data, "expiryDate")).getTime()
        );
        for (let i = 1; i < rwsprc.length; i++) {
          let cur = rwsprc[i],
            prev = rwsprc[i - 1];
          result =
            new Date(apihelper.getAttr(prev.data, "expiryDate")).getTime() >
            new Date(apihelper.getAttr(cur.data, "effectiveDate"));
          if (result) {
            break;
          }
        }
      }
      return result;
    };
    const ProjectResourceMoreMenu = {
      items: [
        {
          key: "edit",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: this._onEditProjectResource,
        },
        {
          key: "remove",
          text: "Remove",
          iconProps: { iconName: icons.ICON_NAME_DELETE },
          onClick: this._onRemoveProjectResource,
        },
      ],
    };

    const ProjectMoreMenu = {
      items: [
        {
          key: "edit",
          text: "Edit",
          iconProps: { iconName: "Edit" },
          onClick: this._onEditProject,
        },
        {
          key: "remove",
          text: "Remove",
          iconProps: { iconName: "Remove" },
          onClick: this._onRemoveProject,
        },
      ],
    };
    return (
      <div className="novatime-container">
        <div className="novatime-page-projectsandroles">
          <h2>Projects and roles</h2>
          <CommandBar
            items={[
              {
                key: "new-entity",
                name: "New",
                iconProps: { iconName: icons.ICON_NAME_NEW },
                subMenuProps: {
                  items: [
                    {
                      key: "newProject",
                      name: "Project",
                      iconProps: { iconName: icons.ICON_NAME_PROJECT },
                      disabled: !selectedClient,
                      onClick: this._onCreateProject,
                    },
                    {
                      key: "newProjectResource",
                      name: "Project resource",
                      iconProps: { iconName: icons.ICON_NAME_PROJECTRESOURCE },
                      disabled: !selectedProject,
                      onClick: this._onCreateProjectResource,
                    },
                  ],
                },
              },
            ]}
          />
          <br />
          <div style={{ maxWidth: "600px" }}>
            <BasePickerSimple
              placeholder="Select a client"
              label="Client"
              autoComplete="on"
              selectedEntity={selectedClient}
              entities={allClientsForSelectedAccount}
              disabled={!selectedAccount}
              onEntitySelected={this._clientOnChange}
            />
            <br />

            <Stack
              horizontal
              styles={{ root: { "justify-content": "space-between" } }}
            >
              <StackItem styles={{ root: { "min-width": "600px" } }}>
                <BasePickerSimple
                  placeholder="Select a project"
                  label="Project"
                  autoComplete="on"
                  selectedEntity={selectedProject}
                  entities={allProjectsForSelectedClient}
                  disabled={!selectedClient}
                  onEntitySelected={this._projectOnChange}
                />
              </StackItem>
              <Stack.Item styles={{ root: { position: "relative" } }}>
                <IconButton
                  styles={{ root: { position: "absolute", bottom: "0" } }}
                  iconProps={{ iconName: icons.ICON_NAME_MORE }}
                  menuProps={ProjectMoreMenu}
                  onRenderMenuIcon={() => <div />} // Disables the chevron
                  menuAs={(props) => <ContextualMenu {...props} />}
                  title={"Show actions"}
                />
              </Stack.Item>
            </Stack>
          </div>
          <br />
          <Label>Project resources </Label>
          <br />

          <SorterList
            className="project-resource-list"
            columns={projectResourceListColumns}
            columnSorters={projectResourceListColumnSorters}
            items={projectResourceDetailListItems}
            onRenderItemColumn={(item, index, column) => {
              const fieldContent = item[column.fieldName];
              switch (column.key) {
                case "pr-project":
                  return (
                    <Stack
                      horizontal
                      styles={{ root: { "justify-content": "space-between" } }}
                    >
                      <Stack.Item
                        align="center"
                        shrink
                        styles={{ root: { overflow: "hidden" } }}
                      >
                        {fieldContent}
                      </Stack.Item>
                      <Stack.Item disableShrink>
                        <IconButton
                          iconProps={{ iconName: icons.ICON_NAME_MORE }}
                          menuProps={ProjectResourceMoreMenu}
                          onRenderMenuIcon={() => <div />} // Disables the chevron
                          menuAs={(props) => <ContextualMenu {...props} />}
                          title={"Show actions"}
                        />
                      </Stack.Item>
                    </Stack>
                  );
                case "pr-resource":
                  return <Resource model={fieldContent} />;
                default:
                  return <span>{fieldContent}</span>;
              }
            }}
            onSelect={this._onSelectProjectResource}
            onRenderRow={(props, defaultRender) => {
              let isDirty =
                props.item.resource && isProjectResourceDirty(props.item);
              return (
                <div
                  key={
                    props.item.resource
                      ? apihelper.getEntityId(props.item.resource)
                      : "all"
                  }
                  className={isDirty ? "dirty" : undefined}
                  title={
                    isDirty
                      ? "Resources With Same ProjectResource Combination!"
                      : undefined
                  }
                >
                  {defaultRender(props, defaultRender)}
                </div>
              );
            }}
            selectedEntity={selectedProjectResource}
            isItemSelected={(item) =>
              apihelper.entityHasId(
                item.data,
                apihelper.getEntityId(selectedProjectResource)
              )
            }
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selectedAccount = appDomainSelectors.selectedAccount(state);
  let selectedClient = null,
    selectedProject = null,
    selectedProjectResource = null,
    allClientsForSelectedAccount = [],
    allProjectsForSelectedClient = [],
    allProjectResourcesForSelectedProject = [],
    allResourcesForSelectedAccount = [],
    projectResourceIdToContractRoleMap = {};
  let selectedClientId = projectAndRolesSelectors.selectedClientId(state);
  let selectedProjectId = projectAndRolesSelectors.selectedProjectId(state);
  let selectedProjectResourceId =
    projectAndRolesSelectors.selectedProjectResourceId(state);

  if (selectedAccount) {
    allClientsForSelectedAccount =
      appDomainSelectors.allClientsForSelectedAccount(state);
    allResourcesForSelectedAccount =
      appDomainSelectors.allResourcesForSelectedAccount(state);

    if (selectedClientId) {
      selectedClient = projectAndRolesSelectors.selectedClient(state);
      allProjectsForSelectedClient =
        projectAndRolesSelectors.allProjectsForSelectedClient(state);

      if (selectedProjectId) {
        selectedProject = projectAndRolesSelectors.selectedProject(state);
        allProjectResourcesForSelectedProject =
          projectAndRolesSelectors.allProjectResourcesForSelectedProject(state);
        projectResourceIdToContractRoleMap =
          projectAndRolesSelectors.projectResourceIdToContractRoleMap(state);

        if (selectedProjectResourceId) {
          selectedProjectResource =
            projectAndRolesSelectors.selectedProjectResource(state);
        }
      }
    }
  }

  return {
    isLoggedIn: isLoggedIn(state),
    allAccounts: allAccounts(state).sort(sortByAttr("name")),
    allClientsForSelectedAccount,
    allProjectsForSelectedClient,
    currentUserAccount: currentUserAccount(state),
    allProjectResourcesForSelectedProject,
    selectedAccount,
    selectedClientId,
    selectedClient,
    selectedProjectId,
    selectedProject,
    selectedProjectResourceId,
    selectedProjectResource,
    allResourcesForSelectedAccount,
    projectResourceIdToContractRoleMap,
    allProjectStatus: projectStatusSelectors.allProjectStatus(state),
  };
}

function mapDispatch(dispatch) {
  const actions = Object.assign({}, projectsAndRolesActions, editorActions);
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatch)(ProjectsAndRoles);
